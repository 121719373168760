import { DeleteOutlined, DeleteTwoTone, PauseCircleOutlined, PauseCircleTwoTone, PlayCircleOutlined, PlayCircleTwoTone, ReloadOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, Spin, Upload, UploadFile } from "antd";
import { stringify } from "querystring";
import { ReactElement, useEffect, useState } from "react";
import { GlobalAudioLibraryApi } from "../api/client-axios";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import { REACT_APP_MAX_UPLOAD_SIZE } from "../config";
import { clearErrorAudios, fetchAudios, showAudioError } from "../features/audioLibrarySlice";

import { deleteLibraryAudio, audioUploadUrl, apiConfiguration } from "../features/server";
import { Problem } from "../model/problem";
import { readFileAsync } from "../utils/fileUtils";


export const AudioLibraryPage = () => {
    const dispatch = useAppDispatch();
    const audios = useAppSelector((state: RootState) => state.audioLibrary.audios);
    const status = useAppSelector((state: RootState) => state.audioLibrary.status);
    const error = useAppSelector((state: RootState) => state.audioLibrary.error);
    const [currentAudioTrackUid, setCurrentTrack] = useState<string | undefined>();
    const [player, setPlayer] = useState(new Audio());
    const [isPlaying, setIsPlaying] = useState(false);



    useEffect(() => {
        dispatch(fetchAudios());
    }, []);


    player.addEventListener('loadeddata', () => {
        let duration = player.duration;
        console.log(duration);
      })

    const files = audios?.map((audio) => {
        return {
            uid: audio.id,
            name: audio.name,
            status: 'done',
        } as UploadFile;
    });


    const getErrorMessage = (): { title: string, details: string } => {
        const apiError = error as Problem;
        const title = typeof error === 'string' ? error : apiError.title ?? "Server Error";
        const detail = typeof error === 'string' ? "" : apiError.detail ?? "";
        return { title, details: detail };
    }

    const errorMessage = getErrorMessage();

    const onReload = () => {
        player.pause();
        setIsPlaying(false);
        dispatch(fetchAudios());
    }

    const onDeleteFile = async (audioId: string) => {
        if (currentAudioTrackUid == audioId && !player.paused) {
            player.pause();
            setIsPlaying(false);
            return;
        }
        await deleteLibraryAudio(audioId);



    }


    const onBeforeUpload = (file: File) => {
        if (file.size > REACT_APP_MAX_UPLOAD_SIZE) {
            dispatch(showAudioError({ type: 'file-is-too-big', title: 'File is too big', detail: `File size ${file.name} exceed 3Mb` } as Problem));
            return false;
        }

        return file;
    }


    const cacheAudio = async (file: UploadFile) => {
        const api = new GlobalAudioLibraryApi(apiConfiguration());
        const r = await api.apiV1AdministrationAudioLibraryIdContentGet(file.uid!, { responseType: 'blob' });
        const url = await readFileAsync(r.data);
        file.url = url as string;
    }


    const onPlay = async (file: UploadFile) => {
        if (file.url == null) {
            await cacheAudio(file);
        }
        if (currentAudioTrackUid == file.uid && !player.paused) {
            player.pause();
            setIsPlaying(false);
            return;
        }

        if (currentAudioTrackUid == file.uid && player.paused) {
            player.play();
            setIsPlaying(true);
            setCurrentTrack(file.uid);
            return;
        }
        player.pause();
        player.src = file.url!;
        player.play();
        setIsPlaying(true);
        setCurrentTrack(file.uid);
    }

    const audioElementRender = (originNode: React.ReactElement, file: UploadFile, fileList: Array<UploadFile>, actions: {
        download: () => void;
        preview: () => void;
        remove: () => void;
    }) => <Col span={24}><Row justify="space-between">
        {file.name}
        <Row>
            <Button className="ml-l" type='text' shape="circle" icon={<DeleteTwoTone twoToneColor="#eb2f96" />} onClick={() => { actions.remove() }} />
            <Button className="mr-xs"
                type='text'
                shape="circle"

                onClick={() => { onPlay(file) }} >
                {currentAudioTrackUid === file.uid && isPlaying ? <PauseCircleTwoTone twoToneColor="#52c41a" /> : <PlayCircleTwoTone />}
            </Button>
        </Row>
    </Row></Col>;




    if (status === 'idle' || status === 'loading') {
        return <Spin className="flex-center mt-xxl" />
    }
    return (
        <div >
            <Row>
                <Col className="mt-s mr-s ml-s">
                    <Row justify="end">
                        <Button className="mb-xs" type='text' shape="circle" icon={<ReloadOutlined />} onClick={onReload} />

                    </Row>

                    <Upload
                        accept=".mp3, .aac, .mp4, .wav"
                        defaultFileList={files}

                        //customRequest={uploadAudioFile}

                        itemRender={audioElementRender}
                        action={audioUploadUrl}
                        // listType="text"
                        headers={{
                            authorization: `Bearer ${localStorage.getItem('token')}`
                        }}
                        onChange={(info) => {
                            if (info.file.size != null && info.file.size > REACT_APP_MAX_UPLOAD_SIZE){
                                info.fileList.pop();
                                return;
                            }
                            if (info.file.status === 'done') {
                                console.log(info.file.uid);
                                console.log(info.fileList)
                                info.file.uid = info.file.response.id;
                            }
                        }}


                        beforeUpload={onBeforeUpload}

                        withCredentials={true}
                        showUploadList={{ showPreviewIcon: false, showRemoveIcon: false }}

                        //showUploadList={false}
                        onRemove={(file) => {
                            onDeleteFile((file.uid));
                        }}
                    >
                        <Button className="mb-s" type="primary">Upload</Button>
                    </Upload>


                </Col>
            </Row>
            <Modal title={errorMessage.title} visible={status === 'error' || status === 'auth-error'} onOk={() => dispatch(clearErrorAudios())} cancelButtonProps={{ hidden: true }}>
                <p>{errorMessage.details}</p>
            </Modal>
        </div>


    );
}

