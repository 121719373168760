import { createAction, createAsyncThunk, createSlice, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { Configuration, MeasurementTypeApi, MeasurementValueTypeDto, MeasurementValueTypeRequestDto } from "../api/client-axios";
import { ApiError } from "../model/apiError";
import { Problem } from "../model/problem";
import { apiConfiguration } from "./server";

export interface MeasurementValueTypesState {
    measurementValueTypes?: MeasurementValueTypeDto[],
    selectedMeasurementValueType?: MeasurementValueTypeDto,
    status: "idle" | "loading" | "error" | "adding" | "success" | "auth-error" | "deleting";
    error?: string | Problem;
    code?: number;
}

const initialMeasurementValueTypesState: MeasurementValueTypesState = {
    status: "idle",
    error: ""
}

export const fetchMeasurementValueTypes = createAsyncThunk<MeasurementValueTypeDto[], string, { rejectValue: ApiError }>('measurementValueTypes/fetchMeasurementValueTypes', async (measurementTypeId, thunkApi) => {
    try {
        const api = new MeasurementTypeApi(apiConfiguration());
        const response = await api.apiV1AdministrationMeasurementMeasurementTypeIdValueTypesGet(measurementTypeId);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data, statusCode: error?.response.status });
    }
});

export const addMeasurementValueType = createAsyncThunk<MeasurementValueTypeDto[], { measurementTypeId: string, measurementValueType: MeasurementValueTypeRequestDto }, { rejectValue: ApiError }>('measurementValueTypes/addMeasurementValueType', async (args, thunkApi) => {
    try {
        const api = new MeasurementTypeApi(apiConfiguration());
        await api.apiV1AdministrationMeasurementMeasurementTypeIdValueTypesPut(args.measurementTypeId, args.measurementValueType);
        const response = await api.apiV1AdministrationMeasurementMeasurementTypeIdValueTypesGet(args.measurementTypeId);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data, statusCode: error?.response.status });
    }
});

export const updateMeasurementValueType = createAsyncThunk<MeasurementValueTypeDto[], { measurementTypeId: string, id: string, measurementValueType: MeasurementValueTypeRequestDto }, { rejectValue: ApiError }>('measurementValueTypes/updateMeasurementValueType', async (args, thunkApi) => {
    try {
        const api = new MeasurementTypeApi(apiConfiguration());
        await api.apiV1AdministrationMeasurementMeasurementTypeIdValueTypesIdPost(args.measurementTypeId, args.id!, args.measurementValueType);
        const response = await api.apiV1AdministrationMeasurementMeasurementTypeIdValueTypesGet(args.measurementTypeId);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data, statusCode: error?.response.status });
    }
});


export const getMeasurementValueType = createAsyncThunk<MeasurementValueTypeDto[], { measurementTypeId: string, id: string }, { rejectValue: ApiError }>('measurementValueTypes/getMeasurementValueType', async (args, thunkApi) => {
    try {
        const api = new MeasurementTypeApi(apiConfiguration());
        await api.apiV1AdministrationMeasurementMeasurementTypeIdValueTypesIdGet(args.measurementTypeId, args.id);
        const response = await api.apiV1AdministrationMeasurementMeasurementTypeIdValueTypesGet(args.measurementTypeId);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data.toString(), statusCode: error?.response.status });
    }
});

export const deleteMeasurementValueType = createAsyncThunk<MeasurementValueTypeDto[], { measurementTypeId: string, id: string }, { rejectValue: ApiError }>('measurementValueTypes/deleteMeasurementValueType', async (args, thunkApi) => {
    try {
        const api = new MeasurementTypeApi(apiConfiguration());
        await api.apiV1AdministrationMeasurementMeasurementTypeIdValueTypesIdDelete(args.measurementTypeId, args.id);
        const response = await api.apiV1AdministrationMeasurementMeasurementTypeIdValueTypesGet(args.measurementTypeId);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data, statusCode: error?.response.status });
    }
});


export const selectMeasurementValueType = createAction("measurementValueTypes/selectMeasurementValueType", (organization: MeasurementValueTypeDto) => ({ payload: organization }));
export const clearErrorMeasurementValueType = createAction("measurementValueTypes/clearErrorMeasurementValueType");
export const startAddMeasurementValueType = createAction("measurementValueTypes/startAddMeasurementValueType", (organization: MeasurementValueTypeDto) => ({ payload: organization }));
export const startDeleteMeasurementValueType = createAction("measurementValueTypes/startDeleteMeasurementValueType");


const measurementValueTypesSlice = createSlice({
    name: "measurementValueTypes",
    initialState: initialMeasurementValueTypesState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchMeasurementValueTypes.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchMeasurementValueTypes.fulfilled, (state, action) => {
                state.status = "success";
                state.measurementValueTypes = action.payload;
            })
            .addCase(fetchMeasurementValueTypes.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(addMeasurementValueType.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addMeasurementValueType.fulfilled, (state, action) => {
                state.status = "success";
                state.measurementValueTypes = action.payload;
            })
            .addCase(addMeasurementValueType.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(updateMeasurementValueType.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateMeasurementValueType.fulfilled, (state, action) => {
                state.status = "success";
                state.measurementValueTypes = action.payload;
            })
            .addCase(updateMeasurementValueType.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(getMeasurementValueType.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getMeasurementValueType.fulfilled, (state, action) => {
                state.status = "success";
                state.measurementValueTypes = action.payload;
            })
            .addCase(getMeasurementValueType.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(deleteMeasurementValueType.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteMeasurementValueType.fulfilled, (state, action) => {
                state.status = "success";
                state.measurementValueTypes = action.payload;
            })
            .addCase(deleteMeasurementValueType.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(selectMeasurementValueType, (state, action) => {
                state.selectedMeasurementValueType = action.payload;
            })
            .addCase(clearErrorMeasurementValueType, (state) => {
                state.status = 'success';
            })
            .addCase(startAddMeasurementValueType, (state, action) => {
                state.status = 'adding';
                state.selectedMeasurementValueType = action.payload;
            })
            .addCase(startDeleteMeasurementValueType, (state) => {
                state.status = 'deleting';

            })
            ;
    }
});

export default measurementValueTypesSlice.reducer;


function processError(
    action:
        PayloadAction<ApiError | undefined,
            string,
            {
                arg: { measurementTypeId: string, measurementValueType: MeasurementValueTypeRequestDto }
                | string
                | MeasurementValueTypeDto
                | void;
                requestId:
                string;
                requestStatus: "rejected";
                aborted: boolean;
                condition: boolean;
            } &
            ({
                rejectedWithValue: true;
            } | ({ rejectedWithValue: false; } & {})),
            SerializedError>,
    state: MeasurementValueTypesState) {
    if (action.payload) {

        if (action.payload !== undefined) {
            state.error = action.payload.message;
            state.code = action.payload.statusCode;
            if (state.code == 401) {
                state.status = "auth-error";
            } else {
                state.status = "error";
            }
        }
    } else {
        state.status = "error";
        state.error = action.error.message;
    }
}