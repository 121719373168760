import {OrganizationAdminsPage} from "./OrganizationAdminsPage";
import {PatientsPage} from "./PatientsPage";
import {CaretakersPage} from "./CaretakersPage";
import {DoctorsPage} from "./DoctorsPage";
import {Divider, Tabs} from "antd";
import {useState} from "react";
import {PatientsIndividualsPage} from "./PatientsIndividualsPage";
import {CaretakersIndividualsPage} from "./CaretakersIndividualsPage";
import {DoctorsIndividualsPage} from "./DoctorsIndividualsPage";
import {FamilyMemberIndividualsPage} from "./FamilyMembersIndividualsPage";

export  const  IndividualsPage = () => {
    const [activeTabKey, setActiveTabKey] = useState<string>("1");

    return (<div className="ml-s mr-s" >
        <h4 className="fs-m fw-600 mt-s">
            Individuals
        </h4>
        <Divider />
        <Tabs defaultActiveKey={activeTabKey} type="line" onChange={(key) => { setActiveTabKey(key) }}>
            <Tabs.TabPane tab="Patients" key="1"><PatientsIndividualsPage   /></Tabs.TabPane>
            <Tabs.TabPane tab="Family Members" key="3"><FamilyMemberIndividualsPage  /></Tabs.TabPane>
            <Tabs.TabPane tab="Caretakers" key="2"><CaretakersIndividualsPage  /></Tabs.TabPane>
            <Tabs.TabPane tab="Doctors" key="4"><DoctorsIndividualsPage  /></Tabs.TabPane>
        </Tabs>
    </div>);
}