import { Divider, Tabs } from "antd"
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { getOrganization } from "../features/organizationAdminsSlice";
import { CaretakersPage } from "./CaretakersPage";
import { DoctorsPage } from "./DoctorsPage";
import { OrganizationAdminsPage } from "./OrganizationAdminsPage";
import { PatientsPage } from "./PatientsPage";


export const OrganizationPage = () => {
    const params = useParams();
    const dispatch = useAppDispatch();
    const organization = useAppSelector(state => state.organizationAdmins.organization);
    const [activeTabKey, setActiveTabKey] = useState<string>("1");
    
    useEffect(() => {
        if (params.organizationId !== undefined) {
            dispatch(getOrganization(params.organizationId));
        }
    }, []);


    return (<div className="ml-s mr-s" >
        <h4 className="fs-m fw-600 mt-s">
            Organization: <span>{organization?.name}</span>
        </h4>
        <Divider />
        <Tabs defaultActiveKey={activeTabKey} type="line" onChange={(key) => { setActiveTabKey(key) }}>
            <Tabs.TabPane tab="Administrators" key="1"><OrganizationAdminsPage  organizationId={params.organizationId!} /></Tabs.TabPane>
            <Tabs.TabPane tab="Patients" key="2"><PatientsPage organizationId={params.organizationId!}  /></Tabs.TabPane>
            <Tabs.TabPane tab="Caretakers" key="3"><CaretakersPage organizationId={params.organizationId!} /></Tabs.TabPane>
            <Tabs.TabPane tab="Doctors" key="4"><DoctorsPage organizationId={params.organizationId!} /></Tabs.TabPane>
        </Tabs>
    </div>);
}