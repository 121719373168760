import { CloseOutlined, EditOutlined, PlusOutlined, ReloadOutlined, RightOutlined, SaveOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Input, InputRef, Modal, Row, Space, Spin, Table } from "antd";
import { ColumnsType, ColumnType, FilterConfirmProps } from "antd/lib/table/interface";
import { useEffect, useRef, useState } from "react";
import { Link, Outlet, useParams } from "react-router-dom";
import { MeasurementValueTypeDto, MeasurementValueTypeRequestDto } from "../api/client-axios";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { addMeasurementValueType, clearErrorMeasurementValueType, deleteMeasurementValueType, fetchMeasurementValueTypes, selectMeasurementValueType, startAddMeasurementValueType, startDeleteMeasurementValueType, updateMeasurementValueType } from "../features/measurementValueTypesSlice";
import { Problem } from "../model/problem";

export const MeasurementValueTypesPage = () => {

    const dispatch = useAppDispatch()
    const measurementValueTypes = useAppSelector(state => state.measurementValueTypes.measurementValueTypes);
    const selectedMeasurementValueType = useAppSelector(state => state.measurementValueTypes.selectedMeasurementValueType);
    const status = useAppSelector(state => state.measurementValueTypes.status);
    const error = useAppSelector(state => state.measurementValueTypes.error);
    const params = useParams();

    const measurementTypeId = params.measurementTypeId;

    useEffect(() => {
        if (measurementTypeId !== undefined) {
            dispatch(fetchMeasurementValueTypes(measurementTypeId!));
        }

    }, []);
    const [searchText, setSearchText] = useState("");
    const searchInput = useRef<InputRef>(null);

    if (params.measurementValueTypeId !== undefined) {
        return <Outlet />
    }

    const onRow = (measurementValueType: MeasurementValueTypeDto, index: number | undefined) => {
        return {
            onClick: () => {
                dispatch(selectMeasurementValueType(measurementValueType))
            }, // click row
        }
    }

    const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void) => {
        confirm();
        setSearchText(selectedKeys[0]);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (): ColumnType<MeasurementValueTypeDto> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search measurementValueType`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record.description != null
                ? record.description.toString().toLowerCase().includes((value as string).toLowerCase())
                : false,
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
    });


    const columns: ColumnsType<MeasurementValueTypeDto> = [
        {
            width: '30%',
            title: 'Unit of Measurement',
            dataIndex: 'uoM',
            key: 'uoM',
            sorter: (a, b) => a.uoM! > b.uoM! ? -1 : 1
        },
        {
            width: '70%',
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            ...getColumnSearchProps(),
            sorter: (a, b) => a.description! > b.description! ? -1 : 1
        }
    ];

    const getRowClassName = (measurementValueType: MeasurementValueTypeDto, index: number) => {
        return selectedMeasurementValueType != null && selectedMeasurementValueType.id === measurementValueType.id ? 'bg-selected' : '';
    }


    const getErrorMessage = (): { title: string, details: string } => {
        const apiError = error as Problem;
        const title = typeof error === 'string' ? error : apiError.title ?? "Server Error";
        const detail = typeof error === 'string' ? "" : apiError.detail ?? "";
        return { title, details: detail };
    }


    const onAddMeasurementValueType = () => {
        dispatch(startAddMeasurementValueType({} as MeasurementValueTypeDto));
    }

    const onReload = () => {
        if (measurementTypeId !== undefined) {
            dispatch(fetchMeasurementValueTypes(measurementTypeId));
        }
    }

    const handleOk = () => {

    }

    const errorMessage = getErrorMessage();

    const changeMeasurementValueTypeDescription = (description: string) => {
        dispatch(selectMeasurementValueType({ ...selectedMeasurementValueType, description }));
    }

    const changeMeasurementValueTypeUoM = (uoM: string) => {
        dispatch(selectMeasurementValueType({ ...selectedMeasurementValueType, uoM }));
    }


    const onSaveMeasurementValueType = () => {
        if (selectedMeasurementValueType?.description == null) return;

        if (selectedMeasurementValueType.id == null) {
            dispatch(addMeasurementValueType({ measurementTypeId: measurementTypeId!, measurementValueType: selectedMeasurementValueType }));
            return;
        }

        dispatch(updateMeasurementValueType({ measurementTypeId: measurementTypeId!, id: selectedMeasurementValueType.id, measurementValueType: { ...selectedMeasurementValueType } as MeasurementValueTypeDto }));
    }

    const onEditMeasurementValueType = () => {
        if (selectedMeasurementValueType != null) {
            dispatch(startAddMeasurementValueType(selectedMeasurementValueType!));
        }
    }

    const onWarnDeleteMeasurementValueType = () => {
        if (selectedMeasurementValueType != null) {
            dispatch(startDeleteMeasurementValueType());
        }
    }

    const onDeleteMeasurementValueType = () => {
        if (selectedMeasurementValueType != null) {
            dispatch(deleteMeasurementValueType({ measurementTypeId: measurementTypeId!, id: selectedMeasurementValueType.id! }));
        }
    }

    const onCancelAddMeasurementValueType = () => {
        dispatch(clearErrorMeasurementValueType());
    }

    const addForm = status == "adding" ? <Form className='mr-s'
        name="basic"
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 22 }}
        initialValues={{ remember: true }}
        autoComplete="off">
        <Form.Item label="Unit of Measure:" rules={[{ required: true, message: '' }]} className="ml-m">
            <Input placeholder='' defaultValue={selectedMeasurementValueType?.uoM || ""} onChange={(e) => { changeMeasurementValueTypeUoM(e.target.value) }} />
        </Form.Item>
        <Form.Item label="Description:" rules={[{ required: true, message: '' }]} className="ml-m">
            <Input placeholder='' defaultValue={selectedMeasurementValueType?.description || ""} onChange={(e) => { changeMeasurementValueTypeDescription(e.target.value) }} />
        </Form.Item>
        <Col offset={4} span={20} >
            <Row justify="end">

                <Space>
                    <Button type='text' icon={<CloseOutlined />} onClick={onCancelAddMeasurementValueType} >Cancel</Button>
                    <Button type='primary' disabled={selectedMeasurementValueType == null} icon={<SaveOutlined />} onClick={onSaveMeasurementValueType}>Save</Button>
                </Space>

            </Row>

        </Col>
    </Form> : <div></div>;


    const buttons = status != "adding" ? <Row >
        <Col span={24}>
            <Row className="ml-m mr-m" justify="space-between">
                <Button disabled={selectedMeasurementValueType == null} type='primary' danger icon={<CloseOutlined />} onClick={onWarnDeleteMeasurementValueType} >Delete</Button>
                <Space>
                    {selectedMeasurementValueType?.id != null ? <Button type='primary' icon={<EditOutlined />} onClick={onEditMeasurementValueType}>Edit</Button> : <div></div>}
                    <Button type='primary' icon={<PlusOutlined />} onClick={onAddMeasurementValueType}>Add</Button>
                    <Button type='text' shape="circle" icon={<ReloadOutlined />} onClick={onReload} />
                </Space>

            </Row>
        </Col>

    </Row> : <div></div>;

    const content = status === 'loading' || status === 'idle'
        ? <Spin className="flex-center mt-xxl" />
        : <div>
            {buttons}
            {addForm}
            <Table className="mr-s ml-s mt-s"
                rowKey={(measurementValueType: MeasurementValueTypeDto) => measurementValueType.id!}
                rowClassName={getRowClassName}
                onRow={onRow}
                dataSource={measurementValueTypes}
                columns={columns} />

            <Modal title={errorMessage.title} visible={status === 'error' || status === 'auth-error'} onOk={handleOk} cancelButtonProps={{ hidden: true }}>
                <p>{errorMessage.details}</p>
            </Modal>
            <Modal visible={status === "deleting"} okText="Yes" cancelText="No" onCancel={() => dispatch(clearErrorMeasurementValueType())} onOk={onDeleteMeasurementValueType} >Are you sure to complitely delete measurementValueType "{selectedMeasurementValueType?.description}"</Modal>
        </div>





    return (<div className="" >
        <h4 className="fs-m fw-600 ml-s mt-s">
            Measurement Value Types
        </h4>
        <Divider />
        {content}
    </div>);
}

