// export const OrganizationAdminsPage = () => {
//     return <div>Organization Admins</div>
// }

import { CloseOutlined, EditOutlined, PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Modal, Row, Space, Spin, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { OrganizationAdminDto, UserAccountDto } from "../api/client-axios";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { clearErrorOrganizationAdmins, deleteOrganizationAdmin, fetchOrganizationsAdmins, getOrganization, selectOrganizationAdmins, startDeleteOrganizationAdmins } from "../features/organizationAdminsSlice";
import { Problem } from "../model/problem";
import { RouteNames } from "../routes";

// import { CloseOutlined, EditOutlined, PlusOutlined, ReloadOutlined } from "@ant-design/icons";
// import { Button, Col, Divider, Modal, Row, Space, Spin, Table } from "antd";
// import { OrganizationDto } from "../api/client-axios";
// import { clearErrorOrganization } from "../features/organizationsSlice";

export const OrganizationAdminsPage = (
    props: {
        organizationId: string
    }
) => {
    const dispatch = useAppDispatch();
    const organization = useAppSelector(state => state.organizationAdmins.organization);
    const organizationAdmins = useAppSelector(state => state.organizationAdmins.organizationAdmins);
    const status = useAppSelector(state => state.organizationAdmins.status);
    const selectedOrganizationAdmin = useAppSelector(state => state.organizationAdmins.selectedOrganizationAdmin);
    const error = useAppSelector(state => state.organizationAdmins.error);
    const params = useParams();
    const navigate = useNavigate();


    useEffect(() => {
            dispatch(getOrganization(props.organizationId));
    }, []);

    useEffect(() => {
        if(organization != null) {
            dispatch(fetchOrganizationsAdmins(organization.id!));
        }
    },[ organization]);


    if(params.adminId !== undefined) {
        return <Outlet />
    }


    const onWarnDeleteOrganizationAdmin = () => {
        if (selectedOrganizationAdmin != null) {
            dispatch(startDeleteOrganizationAdmins());
        }
    }

    const onAddOrganizationAdmin = () => {
        navigate(`/${RouteNames.organizations}/${organization?.id}/admins/new`);
    }

    const onEditOrganizationAdmin = () => {
        if (selectedOrganizationAdmin != null) {
           navigate(`/${RouteNames.organizations}/${organization?.id}/admins/${selectedOrganizationAdmin.id}`);
        }
    }

    const onReload = () => {
        if (organization != null) {
            dispatch(fetchOrganizationsAdmins(organization.id!));
        }
    }

    const handleOk = () => {

    }

    const onRow = (organization: OrganizationAdminDto, index: number | undefined) => {
        return {
            onClick: () => {
                dispatch(selectOrganizationAdmins(organization))
            }, // click row
        }
    }


    const onDeleteOrganizationAdmin = () => {
        if (selectedOrganizationAdmin != null && organization != null) {
            dispatch(deleteOrganizationAdmin({
                organizationId: organization.id!,
                id: selectedOrganizationAdmin.id!
            }));
        }
    }



    const columns: ColumnsType<OrganizationAdminDto> = [
        {

            // width: '100%',
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
            sorter: (a, b) => a.firstName! > b.firstName! ? -1 : 1,
        },
        {

            //width: '100%',
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
            sorter: (a, b) => a.firstName! > b.firstName! ? -1 : 1,
        },
        {

            //width: '100%',
            title: 'Email',
            dataIndex: 'userAccount',
            key: 'lastName',
            sorter: (a, b) => a.userAccount?.emailAddress! > b.userAccount?.emailAddress! ? -1 : 1,
            render: (userAccount?: UserAccountDto) => userAccount?.emailAddress
        },

    ];

    const getRowClassName = (organization: OrganizationAdminDto, index: number) => {
        return selectedOrganizationAdmin != null && selectedOrganizationAdmin.id === organization.id ? 'bg-selected' : '';
    }


    const getErrorMessage = (): { title: string, details: string } => {
        const apiError = error as Problem;
        const title = typeof error === 'string' ? error : apiError.title ?? "Server Error";
        const detail = typeof error === 'string' ? "" : apiError.detail ?? "";
        return { title, details: detail };
    }

    const errorMessage = getErrorMessage();

    const buttons = <Row >
        <Col span={24}>
            <Row className="" justify="space-between">
                {/* <Space>
<Input placeholder='John' defaultValue={searchTerm} onChange={(e) => { setSearchTerm(e.target.value); }} onPressEnter={onSearch}/>
<Button type="primary" icon={<SearchOutlined />}  onClick={onSearch}>Search</Button>
</Space> */}
                <Button disabled={selectedOrganizationAdmin == null} type='primary' danger icon={<CloseOutlined />} onClick={onWarnDeleteOrganizationAdmin} >Delete</Button>

                <Space>
                    {selectedOrganizationAdmin?.id != null ? <Button type='primary' icon={<EditOutlined />} onClick={onEditOrganizationAdmin}>Edit</Button> : <div></div>}
                    <Button type='primary' icon={<PlusOutlined />} onClick={onAddOrganizationAdmin}>Add</Button>
                    <Button type='text' shape="circle" icon={<ReloadOutlined />} onClick={onReload} />
                </Space>

            </Row>
        </Col>
    </Row>;

    const content = status === 'loading' || status === 'idle'
        ? <Spin className="flex-center mt-xxl" />
        : <div>
            {buttons}
            <Table className="mt-s"
                rowKey={(organization: OrganizationAdminDto) => organization.id!}
                rowClassName={getRowClassName}
                onRow={onRow}
                dataSource={organizationAdmins}
                columns={columns} />

            <Modal title={errorMessage.title} visible={status === 'error' || status === 'auth-error'} onOk={() => dispatch(clearErrorOrganizationAdmins())} cancelButtonProps={{ hidden: true }}>
                <p>{errorMessage.details}</p>
            </Modal>
            <Modal visible={status === "deleting"} okText="Yes" cancelText="No" onCancel={() => dispatch(clearErrorOrganizationAdmins())} onOk={onDeleteOrganizationAdmin} >Are you sure to completely delete organization administrator "{selectedOrganizationAdmin?.firstName} {selectedOrganizationAdmin?.lastName}"</Modal>
        </div>

    return (<div className="mt-s" >
        {/* <h4 className="fs-m fw-600 ml-s mt-s">
            Organization: <span>{organization?.name}</span>
        </h4>
        <Divider />
        <h4 className="fs-m fw-600 ml-s mb-s">
            Organization Admins
        </h4> */}
        {content}
    </div>);
}