import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { Configuration, GlobalCaretakerApi, CaretakerResponseDto, CaretakerResponseDtoPagedList } from "../api/client-axios";
import { ApiError } from "../model/apiError";
import { Problem } from "../model/problem";
import { apiConfiguration } from "./server";


export interface CaretakersState {
    caretakers?: CaretakerResponseDto[],
    selectedCaretaker?: CaretakerResponseDto,
    searchTerm?: string,
    status: "idle" | "loading" | "error" | "success" | "auth-error"| "deleting" ;
    error?: string | Problem;
    code?: number;
}



const initialCaretakerState: CaretakersState = {
    status: "idle",
    error: ""
}


export const fetchCaretakers = createAsyncThunk<CaretakerResponseDtoPagedList, { organizationId: string, pageNumber?: number, pageSize?: number, q?: string }, { rejectValue: ApiError }>('caretakers/fetchCaretakers', async (args, thunkApi) => {
    try {

        const api = new GlobalCaretakerApi(apiConfiguration());
        const response = await api.apiV1AdministrationOrganizationOrganizationIdCaretakersGet(args.organizationId, args.pageNumber, args.pageSize, args.q);
        return response.data;

    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data.toString(), statusCode: error?.response.status });
    }
});




export const deleteCaretaker = createAsyncThunk<CaretakerResponseDtoPagedList, { organizationId: string, caretakerId: string }, { rejectValue: ApiError }>('caretakers/deleteCaretaker', async (args, thunkApi) => {
    try {

        const api = new GlobalCaretakerApi(apiConfiguration());
        await api.apiV1AdministrationOrganizationOrganizationIdCaretakersIdDelete( args.organizationId,args.caretakerId,);
        const response = await api.apiV1AdministrationOrganizationOrganizationIdCaretakersGet(args.organizationId);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data.toString(), statusCode: error?.response.status });
    }
});


export const selectCaretaker = createAction("caretaker/selectCaretaker", (caretaker: CaretakerResponseDto) => ({ payload: caretaker }));
export const clearErrorCaretakers = createAction("caretaker/clearErrorCaretakers");  
export const startDeleteCaretaker = createAction("caretaker/startDeleteCaretaker"); 
export const updateSearchTerm = createAction("caretaker/searchTerm", (searchTerm: string) => ({ payload: searchTerm })); 


const caretakersSlice = createSlice({
    name: 'caretakers',
    initialState: initialCaretakerState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchCaretakers.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(fetchCaretakers.fulfilled, (state, action) => {
                state.caretakers = action.payload.items;
                state.status = "success";
            })
            .addCase(fetchCaretakers.rejected, (state, action) => {
                if (action.payload) {
                    state.status = "auth-error";
                    state.error = action.payload.message;
                    state.code = action.payload.statusCode;
                } else {
                    state.status = "error";
                    state.error = action.error.message;
                }
            })
            .addCase(deleteCaretaker.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(deleteCaretaker.fulfilled, (state, action) => {
                state.status = "success";
            })
            .addCase(deleteCaretaker.rejected, (state, action) => {
                if (action.payload) {
                    state.status = "auth-error";
                    state.error = action.payload.message;
                    state.code = action.payload.statusCode;
                } else {
                    state.status = "error";
                    state.error = action.error.message;
                }
            })
            .addCase(selectCaretaker, (state, action) => {
                state.selectedCaretaker = action.payload;
            })
            .addCase(clearErrorCaretakers, (state) => {
                state.status = "success";
             })
             .addCase(startDeleteCaretaker, (state) => {
                 state.status = "deleting";
             }) 
             .addCase(updateSearchTerm, (state, action) => {
                state.searchTerm = action.payload;
            }) 
            ;


    }
});

export default caretakersSlice.reducer;
