import { CloseOutlined, EditOutlined, PlusOutlined, ReloadOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Input, Modal, Row, Space, Spin, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { CaretakerResponseDto, UserAccountDto } from "../api/client-axios";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import { clearErrorCaretakers, deleteCaretaker, fetchCaretakers, selectCaretaker, startDeleteCaretaker, updateSearchTerm } from "../features/caretakersSlice";
import { Problem } from "../model/problem";
import { RouteNames } from "../routes";
import { formatPhone } from "../utils/formatPhone";

export const CaretakersPage = (props: {
    organizationId: string
}) => {
    const dispatch = useAppDispatch();
    const caretakers = useAppSelector((state: RootState) => state.caretakers.caretakers);
    const selectedCaretaker = useAppSelector((state: RootState) => state.caretakers.selectedCaretaker);
    const searchTerm = useAppSelector((state: RootState) => state.caretakers.searchTerm);
    const countries = useAppSelector((state: RootState) => state.country.countries);
    const status = useAppSelector((state: RootState) => state.caretakers.status);
    const error = useAppSelector((state: RootState) => state.caretakers.error);
    const navigate = useNavigate();
    const params = useParams();


    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchCaretakers({ organizationId: props.organizationId }))
        }

    });


    if (params.caretakerId !== undefined) {
        return <Outlet />
    }


    const onWarnDeleteCaretaker = () => {
        if (selectedCaretaker != null) {
            dispatch(startDeleteCaretaker());
        }
    }

    const onAddCaretaker = () => {
        navigate(`/${RouteNames.organizations}/${props.organizationId}/caretakers/new`);
    }

    const onEditCaretaker = () => {
        if (selectedCaretaker != null) {
            navigate(`/${RouteNames.organizations}/${props.organizationId}/caretakers/${selectedCaretaker.id}`);
        }
    }

    const onReload = () => {

        dispatch(fetchCaretakers({ organizationId: props.organizationId }));
    }

    const onSearch = () => {
        dispatch(fetchCaretakers({ organizationId: props.organizationId, q: searchTerm }));
    }


    const onRow = (caretaker: CaretakerResponseDto, index: number | undefined) => {
        return {
            onClick: () => {
                dispatch(selectCaretaker(caretaker))
            }, // click row
        }
    }


    const onDeleteOrganizationAdmin = () => {
        if (selectedCaretaker != null) {
            dispatch(deleteCaretaker({
                organizationId: props.organizationId,
                caretakerId: selectedCaretaker.id!
            }));
        }
    }

    const getRowClassName = (caretaker: CaretakerResponseDto, index: number) => {
        return selectedCaretaker != null && selectedCaretaker.id === caretaker.id ? 'bg-selected' : '';
    }


    const getErrorMessage = (): { title: string, details: string } => {
        const apiError = error as Problem;
        const title = typeof error === 'string' ? error : apiError.title ?? "Server Error";
        const detail = typeof error === 'string' ? "" : apiError.detail ?? "";
        return { title, details: detail };
    }

    const errorMessage = getErrorMessage();


    const columns: ColumnsType<CaretakerResponseDto> = [
        {

            // width: '100%',
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
            sorter: (a, b) => a.firstName! > b.firstName! ? -1 : 1,
        },
        {

            //width: '100%',
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
            sorter: (a, b) => a.firstName! > b.firstName! ? -1 : 1,
        },
        {

            //width: '100%',
            title: 'Email',
            dataIndex: 'userAccount',
            key: 'email',
            sorter: (a, b) => a.userAccount?.emailAddress! > b.userAccount?.emailAddress! ? -1 : 1,
            render: (userAccount?: UserAccountDto) => userAccount?.emailAddress
        },
        {

            //width: '100%',
            title: 'Phone',
            dataIndex: 'userAccount',
            key: 'phone',
            sorter: (a, b) => a.userAccount?.mobileNumber! > b.userAccount?.mobileNumber! ? -1 : 1,
            render: (userAccount?: UserAccountDto) => formatPhone(userAccount?.mobileNumber, countries)
        },

    ];

    //todo implement server side paging and sorting
    const buttons = <Row >
        <Col span={24}>
            <Row className="" justify="space-between">
                <Space>
                    <Input placeholder='John' defaultValue={searchTerm} onChange={(e) => { dispatch(updateSearchTerm(e.target.value)); }} onPressEnter={onSearch} />
                    <Button type="primary" icon={<SearchOutlined />} onClick={onSearch}>Search</Button>
                </Space>
               

                <Space>
                <Button disabled={selectedCaretaker == null} type='primary' danger icon={<CloseOutlined />} onClick={onWarnDeleteCaretaker} >Delete</Button>
                    {selectedCaretaker?.id != null ? <Button type='primary' icon={<EditOutlined />} onClick={onEditCaretaker}>Edit</Button> : <div></div>}
                    <Button type='primary' icon={<PlusOutlined />} onClick={onAddCaretaker}>Add</Button>
                    <Button type='text' shape="circle" icon={<ReloadOutlined />} onClick={onReload} />
                </Space>

            </Row>
        </Col>
    </Row>;

    const content = status === 'loading' || status === 'idle'
        ? <Spin className="flex-center mt-xxl" />
        : <div>
            {buttons}
            <Table className="mt-s"
                rowKey={(caretaker: CaretakerResponseDto) => caretaker.id!}
                rowClassName={getRowClassName}
                onRow={onRow}
                dataSource={caretakers}
                columns={columns} />

            <Modal title={errorMessage.title} visible={status === 'error' || status === 'auth-error'} onOk={() => dispatch(clearErrorCaretakers())} cancelButtonProps={{ hidden: true }}>
                <p>{errorMessage.details}</p>
            </Modal>
            <Modal visible={status === "deleting"} okText="Yes" cancelText="No" onCancel={() => dispatch(clearErrorCaretakers())} onOk={onDeleteOrganizationAdmin} >Are you sure to complitely delete caretaker "{selectedCaretaker?.firstName} {selectedCaretaker?.lastName}"</Modal>
        </div>

    return (<div className="mt-s" >
        {content}
    </div>);
}
