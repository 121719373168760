import {createAction, createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {AxiosError} from "axios";
import {Configuration, GlobalDoctorsApi, DoctorResponseDto, DoctorResponseDtoPagedList} from "../api/client-axios";
import {ApiError} from "../model/apiError";
import {Problem} from "../model/problem";
import {apiConfiguration} from "./server";


export interface DoctorsState {
    doctors?: DoctorResponseDto[],
    selectedDoctor?: DoctorResponseDto,
    searchTerm?: string,
    status: "idle" | "loading" | "error" | "success" | "auth-error" | "deleting" | "saved";
    error?: string | Problem;
    code?: number;
}


const initialDoctorState: DoctorsState = {
    status: "idle",
    error: ""
}


export const fetchDoctors = createAsyncThunk<DoctorResponseDtoPagedList, {
    organizationId: string,
    pageNumber?: number,
    pageSize?: number,
    q?: string
}, { rejectValue: ApiError }>('doctors/fetchDoctors', async (args, thunkApi) => {
    try {

        const api = new GlobalDoctorsApi(apiConfiguration());
        const response = await api.apiV1AdministrationOrganizationOrganizationIdDoctorsGet(args.organizationId, args.pageNumber, args.pageSize, args.q);
        return response.data;

    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({message: error?.response.data.toString(), statusCode: error?.response.status});
    }
});


export const deleteDoctor = createAsyncThunk<DoctorResponseDtoPagedList, { organizationId: string, doctorId: string }, {
    rejectValue: ApiError
}>('doctors/deleteDoctor', async (args, thunkApi) => {
    try {

        const api = new GlobalDoctorsApi(apiConfiguration());
        await api.apiV1AdministrationOrganizationOrganizationIdDoctorsIdDelete(args.organizationId, args.doctorId);
        const response = await api.apiV1AdministrationOrganizationOrganizationIdDoctorsGet(args.organizationId);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({message: error?.response.data.toString(), statusCode: error?.response.status});
    }
});


export const selectDoctor = createAction("doctor/selectDoctor", (doctor: DoctorResponseDto) => ({payload: doctor}));
export const clearErrorDoctors = createAction("doctor/clearErrorDoctors");
export const startDeleteDoctor = createAction("doctor/startDeleteDoctor");
export const updateSearchTerm = createAction("doctor/searchTerm", (searchTerm: string) => ({payload: searchTerm}));


const doctorsSlice = createSlice({
    name: 'doctors',
    initialState: initialDoctorState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchDoctors.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(fetchDoctors.fulfilled, (state, action) => {
                state.doctors = action.payload.items;
                state.status = "success";
            })
            .addCase(fetchDoctors.rejected, (state, action) => {
                if (action.payload) {
                    state.status = "auth-error";
                    state.error = action.payload.message;
                    state.code = action.payload.statusCode;
                } else {
                    state.status = "error";
                    state.error = action.error.message;
                }
            })
            .addCase(deleteDoctor.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(deleteDoctor.fulfilled, (state, action) => {
                state.status = "saved";
            })
            .addCase(deleteDoctor.rejected, (state, action) => {
                if (action.payload) {
                    state.status = "auth-error";
                    state.error = action.payload.message;
                    state.code = action.payload.statusCode;
                } else {
                    state.status = "error";
                    state.error = action.error.message;
                }
            })
            .addCase(selectDoctor, (state, action) => {
                state.selectedDoctor = action.payload;
            })
            .addCase(clearErrorDoctors, (state) => {
                state.status = "success";
            })
            .addCase(startDeleteDoctor, (state) => {
                state.status = "deleting";
            })
            .addCase(updateSearchTerm, (state, action) => {
                state.searchTerm = action.payload;
            })
        ;


    }
});

export default doctorsSlice.reducer;
