import {createAction, createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {AxiosError} from "axios";
import {Configuration, GlobalDoctorsApi, DoctorResponseDto, DoctorResponseDtoPagedList} from "../api/client-axios";
import {ApiError} from "../model/apiError";
import {Problem} from "../model/problem";
import {apiConfiguration} from "./server";


export interface DoctorsNoOrgState {
    doctors?: DoctorResponseDto[],
    selectedDoctor?: DoctorResponseDto,
    searchTerm?: string,
    status: "idle" | "loading" | "error" | "success" | "auth-error" | "deleting";
    error?: string | Problem;
    code?: number;
}


const initialNoOrgDoctorState: DoctorsNoOrgState = {
    status: "idle",
    error: ""
}


export const fetchNoOrgDoctors = createAsyncThunk<DoctorResponseDtoPagedList, {
    pageNumber?: number,
    pageSize?: number,
    q?: string
}, { rejectValue: ApiError }>('doctors/fetchNoOrgDoctors', async (args, thunkApi) => {
    try {

        const api = new GlobalDoctorsApi(apiConfiguration());
        const response = await api.apiV1AdministrationNoOrgDoctorsGet(args.pageNumber, args.pageSize, args.q);
        return response.data;

    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({message: error?.response.data.toString(), statusCode: error?.response.status});
    }
});


export const deleteNoOrgDoctor = createAsyncThunk<DoctorResponseDtoPagedList, { doctorId: string }, {
    rejectValue: ApiError
}>('doctors/deleteNoOrgDoctor', async (args, thunkApi) => {
    try {

        const api = new GlobalDoctorsApi(apiConfiguration());
        await api.apiV1AdministrationNoOrgDoctorsIdDelete(args.doctorId);
        const response = await api.apiV1AdministrationNoOrgDoctorsGet();
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({message: error?.response.data.toString(), statusCode: error?.response.status});
    }
});


export const selectNoOrgDoctor = createAction("doctor/selectNoOrgDoctor", (doctor: DoctorResponseDto) => ({payload: doctor}));
export const clearErrorNoOrgDoctors = createAction("doctor/clearErrorNoOrgDoctors");
export const startDeleteNoOrgDoctor = createAction("doctor/startDeleteNoOrgDoctor");
export const updateSearchTermNoOrgDoctor = createAction("doctor/searchTermNoOrgDoctor", (searchTerm: string) => ({payload: searchTerm}));


const doctorsNoOrgSlice = createSlice({
    name: 'doctors',
    initialState: initialNoOrgDoctorState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchNoOrgDoctors.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(fetchNoOrgDoctors.fulfilled, (state, action) => {
                state.doctors = action.payload.items;
                state.status = "success";
            })
            .addCase(fetchNoOrgDoctors.rejected, (state, action) => {
                if (action.payload) {
                    state.status = "auth-error";
                    state.error = action.payload.message;
                    state.code = action.payload.statusCode;
                } else {
                    state.status = "error";
                    state.error = action.error.message;
                }
            })
            .addCase(deleteNoOrgDoctor.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(deleteNoOrgDoctor.fulfilled, (state, action) => {
                state.status = "success";
            })
            .addCase(deleteNoOrgDoctor.rejected, (state, action) => {
                if (action.payload) {
                    state.status = "auth-error";
                    state.error = action.payload.message;
                    state.code = action.payload.statusCode;
                } else {
                    state.status = "error";
                    state.error = action.error.message;
                }
            })
            .addCase(selectNoOrgDoctor, (state, action) => {
                state.selectedDoctor = action.payload;
            })
            .addCase(clearErrorNoOrgDoctors, (state) => {
                state.status = "success";
            })
            .addCase(startDeleteNoOrgDoctor, (state) => {
                state.status = "deleting";
            })
            .addCase(updateSearchTermNoOrgDoctor, (state, action) => {
                state.searchTerm = action.payload;
            })
        ;


    }
});

export default doctorsNoOrgSlice.reducer;
