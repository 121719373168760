import {ScissorOutlined, DeleteOutlined, CloseOutlined, CheckOutlined} from "@ant-design/icons";
import {Select, Spin, Modal, Col, Form, Row, Space, Button, Input, DatePicker, InputNumber, Switch} from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import {useState, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {PatientRequestDto, PatientResponseDto} from "../api/client-axios";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {RootState} from "../app/store";
import {
    fetchPatient,
    initPatientState,
    updatePatientFields,
    detachPatient,
    updatePatientPhoneNumber,
    clearSavePatientError,
    savePatient, updatePatientByCode
} from "../features/patientSlice";
import {fetchPatients, deletePatient} from "../features/patientsSlice";
import {Problem} from "../model/problem";
import {RouteNames} from "../routes";
import {AddPatient} from "./AddPatient";
import {AddPatientByCode} from "../components/AddPatientByCode";

const {Option} = Select;

export const EditPatientPage = () => {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const patientsStatus = useAppSelector((state: RootState) => state.patients.status);
    const patient = useAppSelector((state: RootState) => state.patient.patient);
    const status = useAppSelector((state: RootState) => state.patient.status);
    const error = useAppSelector((state: RootState) => state.patient.error);
    const countries = useAppSelector((state: RootState) => state.country.countries);


    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isRemovePatientWarningVisible, setIsRemovePatientWarningVisible] = useState(false);
    const [isDeletePatientWarningVisible, setIsDeletePatientWarningVisible] = useState(false);

    const organizationId = params.organizationId;
    let patientId = params.patientId;

    useEffect(() => {
        if (patientsStatus === "idle") {
            dispatch(fetchPatients({organizationId: organizationId!}));
        }
        dispatch(fetchPatient({organizationId: organizationId!, patientId: patientId!}));
    }, []);

    useEffect(() => {
        if (status === "saved") {
            dispatch(fetchPatients({organizationId: organizationId!}));
            dispatch(initPatientState());
            navigate(-1);
        }
        if (status === "auth-error" || status === "error") {
            setIsModalVisible(true);
        }
    }, [status])


    const onCancel = () => {
        navigate(-1);
    }

    const changePatient = (patient: PatientRequestDto) => {
        dispatch(updatePatientFields(patient));
    }

    const onSave = () => {
        dispatch(savePatient({organizationId: organizationId!, patient: patient as PatientRequestDto}));
    }


    const onDelete = () => {
        setIsDeletePatientWarningVisible(true);
    }

    const onRemove = () => {
        setIsRemovePatientWarningVisible(true);
    }

    const onDeletePatient = () => {
        if (patient?.id != null) {
            dispatch(deletePatient({organizationId: organizationId!, patientId: patient.id}));
        }
    }

    const onRemovePatient = () => {
        if (patient?.id != null) {
            dispatch(detachPatient({organizationId: organizationId!, patientId: patient.id}));
        }
    }

    const onConfirm = (phoneNumber: string) => {
        dispatch(updatePatientPhoneNumber(phoneNumber));
    }

    const handleAuthOk = () => {
        setIsModalVisible(false)
        navigate("/")
    }

    const handleErrorOk = () => {
        setIsModalVisible(false);
        dispatch(clearSavePatientError());
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }


    if (status === "idle" || status === "loading" || patientsStatus === "idle") {
        return <Spin/>;
    }

    if (status === "auth-error") {
        return <Modal title="Auth error" visible={isModalVisible} onOk={handleAuthOk} onCancel={handleCancel}>
            <p>"Authentication failed, please login"</p>
        </Modal>
    }

    if (status === "error") {
        const apiError = error as Problem;
        const title = apiError.title ?? "Server Error";
        const detail = apiError.detail ?? error?.toString;
        return <Modal title={title} visible={isModalVisible} onOk={handleErrorOk} onCancel={handleCancel}>
            <p>{detail}</p>
        </Modal>
    }

    if (status === "saved") {
        return (<div></div>);
    }

    // if (patient?.userAccount?.mobileNumber === undefined) {
    //     return <AddPatient organizationId={organizationId!}  onConfirm={onConfirm} />
    // }

    const onPatientConfirmed = (patient: PatientResponseDto) => {
        if (patient != null) {
            patientId = patient.id!;
        }
        dispatch(updatePatientByCode(patient));
    }

    if (patient?.id === undefined || patient.id == null) {
        return <AddPatientByCode organizationId={organizationId!} onConfirm={onPatientConfirmed}/>
    }


    return (<div className='edit-person-main-frame'>
        <Col span={24}>
            <Form className='person-form'
                  name="basic"
                  labelCol={{span: 6}}
                  wrapperCol={{span: 24}}
                  initialValues={{remember: true}}
                //onFinish={onFinish}
                //onFinishFailed={onFinishFailed}
                  autoComplete="off"
            >

                <Row>
                    {/* <Col className='person-title' offset={6} span={11}>Patient</Col> */}
                    <Col offset={6} span={18}>
                        <Form.Item>
                            <Row justify="end">
                                <Space className="mr-xxs">
                                    <Button icon={<ScissorOutlined/>} onClick={onRemove} type='primary'
                                            style={{background: 'orange', borderColor: 'orange'}}
                                            disabled={patient?.id === undefined}>Remove</Button>
                                    <Button icon={<DeleteOutlined/>} onClick={onDelete} type='primary' danger
                                            disabled={patient?.id === undefined}>Delete</Button>
                                </Space>

                                <Space>
                                    <Button icon={<CloseOutlined/>} onClick={onCancel}>Cancel</Button>
                                    <Button icon={<CheckOutlined/>} type='primary' onClick={onSave}>Save</Button>
                                </Space>
                            </Row>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Phone number:" rules={[{required: true}]}
                                   initialValue={patient?.userAccount?.mobileNumber}>
                            <Input placeholder='1 (344) 333-3333'
                                   defaultValue = {patient?.userAccount?.mobileNumber || ""}
                                   onChange={(e) => {
                                changePatient({...patient, userAccount: { ...patient.userAccount, mobileNumber: e.target.value }} as PatientRequestDto)
                            }}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Email:">
                            <Input disabled placeholder='john.doe@email.com'
                                   defaultValue={patient?.userAccount?.emailAddress || ""}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="First name:" rules={[{required: true, message: 'please enter First Name'}]}>
                            <Input placeholder='John' defaultValue={patient?.firstName || ""} onChange={(e) => {
                                changePatient({...patient, firstName: e.target.value} as PatientRequestDto)
                            }}/>
                        </Form.Item>

                    </Col>
                    <Col span={24}>
                        <Form.Item label="Last name:">
                            <Input placeholder='Doe' defaultValue={patient?.lastName || ""} onChange={(e) => {
                                changePatient({...patient, lastName: e.target.value} as PatientRequestDto)
                            }}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Gender:">
                            <Select defaultValue={patient?.gender} onChange={(e) => {
                                changePatient({...patient, gender: e} as PatientRequestDto)
                            }} style={{width: 100}}>
                                <Option value="Male">Male</Option>
                                <Option value="Female">Female</Option>
                                <Option value="Other">Other</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Birthday:">
                            <DatePicker
                                defaultValue={patient?.birthday != undefined ? moment(patient.birthday, "YYYY-MM-DD").utc() : moment().utc()}
                                onChange={(e) => {
                                    changePatient({...patient, birthday: e?.format("")} as PatientRequestDto)
                                }}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Country:" initialValue={patient?.address?.country}>
                            <Select
                                onChange={(e) => changePatient({
                                    ...patient,
                                    address: {...patient?.address, alpha3Code: e}
                                } as PatientRequestDto)}
                                defaultValue={patient?.address?.alpha3Code}
                                showSearch
                                style={{width: 200}}
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) => {
                                    return (option!.children![2] as unknown as string).toLowerCase().includes(input.toLowerCase())
                                }}
                                filterSort={(optionA, optionB) =>
                                    (optionA!.children![2] as unknown as string)
                                        .toLowerCase()
                                        .localeCompare((optionB!.children![2] as unknown as string).toLowerCase())
                                }
                            >
                                {countries.map(country => <Option key={country.alpha3Code}
                                                                  value={country.alpha3Code}><img src={country.flag!}
                                                                                                  width="20"></img> {country.name}
                                </Option>)}

                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Address:">
                            <Input defaultValue={patient?.address?.streetAddress1 as string | undefined}
                                   placeholder='Road st 27' onChange={(e) => {
                                changePatient({
                                    ...patient,
                                    address: {...patient?.address, streetAddress1: e.target.value}
                                } as PatientRequestDto)
                            }}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Address:">
                            <Input defaultValue={patient?.address?.streetAddress2 as string | undefined}
                                   placeholder='Left side' onChange={(e) => {
                                changePatient({
                                    ...patient,
                                    address: {...patient?.address, streetAddress2: e.target.value}
                                } as PatientRequestDto)
                            }}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="City:">
                            <Input defaultValue={patient?.address?.city as string | undefined} placeholder='New York'
                                   onChange={(e) => {
                                       changePatient({
                                           ...patient,
                                           address: {...patient?.address, city: e.target.value}
                                       } as PatientRequestDto)
                                   }}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="State:">
                            <Input defaultValue={patient?.address?.state as string | undefined} placeholder='NY'
                                   onChange={(e) => {
                                       changePatient({
                                           ...patient,
                                           address: {...patient?.address, state: e.target.value}
                                       } as PatientRequestDto)
                                   }}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Postcode:">
                            <Input defaultValue={patient?.address?.postCode as string | undefined} placeholder='NY'
                                   onChange={(e) => {
                                       changePatient({
                                           ...patient,
                                           address: {...patient?.address, postCode: e.target.value}
                                       } as PatientRequestDto)
                                   }}/>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item label="Allowed distance, m:">
                            <InputNumber min={0} max={1000}
                                         defaultValue={patient?.allowedDistance as number | undefined}
                                         onChange={(e) => {
                                             changePatient({...patient, allowedDistance: e} as PatientRequestDto)
                                         }}/>
                            {/* <Input placeholder='300 meters' onChange={(e) => { changePatient({ ...patient, allowedDistance :  parseInt(e.target.value) } as PatientRequestDto) }} /> */}
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Check allowed distance:">
                            <Switch checked={patient?.checkAllowedDistance} onChange={(e) => {
                                changePatient({...patient, checkAllowedDistance: e} as PatientRequestDto)
                            }}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Doctor first name:">
                            <Input defaultValue={patient?.doctorName as string | undefined} placeholder='Mikle'
                                   onChange={(e) => {
                                       changePatient({...patient, doctorName: e.target.value} as PatientRequestDto)
                                   }}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Doctor last name:">
                            <Input defaultValue={patient?.doctorLastName as string | undefined} placeholder='Santos'
                                   onChange={(e) => {
                                       changePatient({...patient, doctorLastName: e.target.value} as PatientRequestDto)
                                   }}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Doctor phone number:" initialValue={patient?.doctorPhone}>
                            <Input defaultValue={patient?.doctorPhone as string | undefined}
                                   placeholder='+1 456 565-3443' onChange={(e) => {
                                changePatient({...patient, doctorPhone: e.target.value} as PatientRequestDto)
                            }}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Diagnosis:" initialValue={patient?.diagnosis}>
                            <TextArea defaultValue={patient?.diagnosis as string | undefined} rows={10}
                                      placeholder="Diagnosic" onChange={(e) => {
                                changePatient({...patient, diagnosis: e.target.value} as PatientRequestDto)
                            }}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Col>
        <Modal visible={isRemovePatientWarningVisible} okText="Yes" cancelText="No"
               onCancel={() => setIsRemovePatientWarningVisible(false)} onOk={onRemovePatient}>Are you sure to remove
            patient from clinic "{patient?.firstName + " " + patient?.lastName}"</Modal>
        <Modal visible={isDeletePatientWarningVisible} okText="Yes" cancelText="No"
               onCancel={() => setIsDeletePatientWarningVisible(false)} onOk={onDeletePatient}>Are you sure to
            complitely delete patient "{patient?.firstName + " " + patient?.lastName}"</Modal>
    </div>);
}