import { createAction, createAsyncThunk, createSlice, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { AdminOrganizationsApi, Configuration, OrganizationDto, OrganizationsApi } from "../api/client-axios";
import { ApiError } from "../model/apiError";
import { Problem } from "../model/problem";
import { apiConfiguration } from "./server";

export interface OrganizationState {
    organizations?: OrganizationDto[],
    selectedOrganization?: OrganizationDto,
    status: "idle" | "loading" | "error" | "adding" | "success" | "auth-error" | "deleting" ;
    error?: string | Problem;
    code?: number;
}

const initialOrganizationState: OrganizationState = {
    status: "idle",
    error: ""
}

export const fetchOrganizations = createAsyncThunk<OrganizationDto[], void, { rejectValue: ApiError }>('organizations/fetchOrganizations', async (_, thunkApi) => {
    try {
        const api = new AdminOrganizationsApi(apiConfiguration());
        const response = await api.apiV1AdministrationOrganizationsGet();
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data.toString(), statusCode: error?.response.status });
    }
});

export const addOrganization = createAsyncThunk<OrganizationDto[], string, { rejectValue: ApiError }>('organizations/addOrganization', async (name, thunkApi) => {
    try {
        const api = new AdminOrganizationsApi(apiConfiguration());
        await api.apiV1AdministrationOrganizationsPost({ name: name });
        const response = await api.apiV1AdministrationOrganizationsGet();
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data.toString(), statusCode: error?.response.status });
    }
});

export const updateOrganization = createAsyncThunk<OrganizationDto[], OrganizationDto, { rejectValue: ApiError }>('organizations/updateOrganization', async (organizationDto, thunkApi) => {
    try {
        const api = new AdminOrganizationsApi(apiConfiguration());
        await api.apiV1AdministrationOrganizationsOrganizationIdPut(organizationDto.id!, { name: organizationDto.name });
        const response = await api.apiV1AdministrationOrganizationsGet();
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data.toString(), statusCode: error?.response.status });
    }
});


export const getOrganization = createAsyncThunk<OrganizationDto[], string, { rejectValue: ApiError }>('organizations/getOrganization', async (name, thunkApi) => {
    try {
        const api = new AdminOrganizationsApi(apiConfiguration());
        await api.apiV1AdministrationOrganizationsOrganizationIdGet(name);
        const response = await api.apiV1AdministrationOrganizationsGet();
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data.toString(), statusCode: error?.response.status });
    }
});

export const deleteOrganization = createAsyncThunk<OrganizationDto[], string, { rejectValue: ApiError }>('organizations/deleteOrganization', async (id, thunkApi) => {
    try {
        const api = new AdminOrganizationsApi(apiConfiguration());
        await api.apiV1AdministrationOrganizationsOrganizationIdDelete(id);
        const response = await api.apiV1AdministrationOrganizationsGet();
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data.toString(), statusCode: error?.response.status });
    }
});


export const selectOrganization = createAction("organizations/selectOrganization", (organization: OrganizationDto) => ({ payload: organization})); 
export const clearErrorOrganization = createAction("organizations/clearErrorOrganization"); 
export const startAddOrganization = createAction("organizations/startAddOrganization", (organization: OrganizationDto) => ({ payload: organization})); 
export const startDeleteOrganization = createAction("organizations/startDeleteOrganization"); 


const organizationsSlice = createSlice({
    name: "organizations",
    initialState: initialOrganizationState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchOrganizations.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchOrganizations.fulfilled, (state, action) => {
                state.status = "success";
                state.organizations = action.payload;
            })
            .addCase(fetchOrganizations.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(addOrganization.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addOrganization.fulfilled, (state, action) => {
                state.status = "success";
                state.organizations = action.payload;
            })
            .addCase(addOrganization.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(updateOrganization.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateOrganization.fulfilled, (state, action) => {
                state.status = "success";
                state.organizations = action.payload;
            })
            .addCase(updateOrganization.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(getOrganization.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getOrganization.fulfilled, (state, action) => {
                state.status = "success";
                state.organizations = action.payload;
            })
            .addCase(getOrganization.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(deleteOrganization.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteOrganization.fulfilled, (state, action) => {
                state.status = "success";
                state.organizations = action.payload;
            })
            .addCase(deleteOrganization.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(selectOrganization, (state, action) => {
                state.selectedOrganization = action.payload;
            })
            .addCase(clearErrorOrganization, (state) => {
                state.status = 'success';
            })
            .addCase(startAddOrganization, (state, action) => {
                state.status = 'adding';
                state.selectedOrganization =action.payload;
            })
            .addCase(startDeleteOrganization, (state) => {
                state.status = 'deleting';
                
            })
            ;
    }
});

export default organizationsSlice.reducer;


function processError(
    action:
        PayloadAction<ApiError | undefined,
            string,
            { arg: string | OrganizationDto | void; requestId: string; requestStatus: "rejected"; aborted: boolean; condition: boolean; } &
            ({ rejectedWithValue: true; } | ({ rejectedWithValue: false; } & {})),
            SerializedError>,
    state: OrganizationState) {
    if (action.payload) {

        //state.code = action.payload.statusCode;
        //const apiError = action.payload as ApiError;
        if (action.payload !== undefined) {
            state.error = action.payload.message;
            state.code = action.payload.statusCode;
            if (state.code == 401) {
                state.status = "auth-error";
            } else {
                state.status = "error";
            }
        }
    } else {
        state.status = "error";
        state.error = action.error.message;
    }
}