import { createAction, createAsyncThunk, createSlice, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { AdminOrganizationsApi, Configuration, OrganizationAdminCreateRequest, OrganizationAdminDto, OrganizationAdminUpdateRequest } from "../api/client-axios";
import { ApiError } from "../model/apiError";
import { Problem } from "../model/problem";
import { apiConfiguration } from "./server";

export interface OrganizationAdminState {
    admin?: OrganizationAdminDto,
    password: {password: string, confirmPassword: string},
    status: "idle" | "loading" | "error" | "success" | "auth-error" | "saved";
    error?: string | Problem;
    code?: number;
}

const initialOrganizationAdminState: OrganizationAdminState = {
    status: "idle",
    password: {password: "", confirmPassword: ""},
    error: ""
}




export const addOrganizationAdmin = createAsyncThunk<OrganizationAdminDto, { admin: OrganizationAdminCreateRequest, organizationId: string}, { rejectValue: ApiError }>('organizationAdmin/addOrganizationAdmin', async (args, thunkApi) => {
    try {
        const api = new AdminOrganizationsApi(apiConfiguration());
        const response = await api.apiV1AdministrationOrganizationsOrganizationIdAdminPut(args.organizationId, args.admin);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data, statusCode: error?.response.status });
    }
});

export const updateOrganizationAdmin = createAsyncThunk<OrganizationAdminDto, { admin: OrganizationAdminUpdateRequest, organizationId: string, id: string }, { rejectValue: ApiError }>('organizationAdmin/updateOrganizationAdmin', async (args, thunkApi) => {
    try {
        const api = new AdminOrganizationsApi(apiConfiguration());
        const response = await api.apiV1AdministrationOrganizationsOrganizationIdAdminIdPost(args.organizationId, args.id, args.admin);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data, statusCode: error?.response.status });
    }
});


export const getOrganizationAdmin = createAsyncThunk<OrganizationAdminDto, {organizationId:  string, id: string}, { rejectValue: ApiError }>('organizationAdmin/getOrganizationAdmin', async (args, thunkApi) => {
    try {
        const api = new AdminOrganizationsApi(apiConfiguration());
        const response = await api.apiV1AdministrationOrganizationsOrganizationIdAdminIdGet(args.organizationId, args.id);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data, statusCode: error?.response.status });
    }
});

export const clearErrorOrganizationAdmin = createAction("organizationAdmin/clearErrorOrganizationAdmin");  
export const updateOrganizationAdminFields = createAction("organizationAdmin/clearErrorOrganizationAdminFields",( (organizationAdmin: OrganizationAdminDto) => ({ payload: organizationAdmin})));
export const updateOrganizationAdminPassword = createAction("organizationAdmin/UpdateOrganizationAdminPassword",( (password: {password: string, confirmPassword: string}) => ({ payload: password})));   
export const setErrorOrganizationAdmin = createAction("organizationAdmin/setErrorOrganizationAdmin", ( (error: Problem) => ({ payload: error})));
export const initOrganizationAdmin = createAction("organizationAdmin/initOrganizationAdmin");



const organizationAdminSlice = createSlice({
    name: "organizationAdmin",
    initialState: initialOrganizationAdminState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(addOrganizationAdmin.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addOrganizationAdmin.fulfilled, (state, action) => {
                state.status = "saved";
                state.admin = action.payload;
            })
            .addCase(addOrganizationAdmin.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(updateOrganizationAdmin.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateOrganizationAdmin.fulfilled, (state, action) => {
                state.status = "saved";
                state.admin = action.payload;
            })
            .addCase(updateOrganizationAdmin.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(getOrganizationAdmin.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getOrganizationAdmin.fulfilled, (state, action) => {
                state.status = "success";
                state.admin = action.payload;
            })
            .addCase(getOrganizationAdmin.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(clearErrorOrganizationAdmin, (state) => {
               state.status = "success";
            })
            .addCase(updateOrganizationAdminFields,(state,action)=>{
                state.admin = action.payload;
            })
            .addCase(updateOrganizationAdminPassword,(state,action)=>{
               state.password = action.payload;
            })
            .addCase(setErrorOrganizationAdmin,(state,action)=>{
                state.error = action.payload;
                state.status = "error";
            })
            .addCase(initOrganizationAdmin,(state)=>{
                state.status = "success";
                state.admin = undefined;
            })
            ;
            
    }
});

function processError(
    action:
        PayloadAction<ApiError | undefined,
            string,
            { arg: string | OrganizationAdminDto | void; requestId: string; requestStatus: "rejected"; aborted: boolean; condition: boolean; } &
            ({ rejectedWithValue: true; } | ({ rejectedWithValue: false; } & {})),
            SerializedError>,
    state: OrganizationAdminState) {
    if (action.payload) {
        if (action.payload !== undefined) {
            state.error = action.payload.message;
            state.code = action.payload.statusCode;
            if (state.code == 401) {
                state.status = "auth-error";
            } else {
                state.status = "error";
            }
        }
    } else {
        state.status = "error";
        state.error = action.error.message;
    }
}

export default organizationAdminSlice.reducer;