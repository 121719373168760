import { createAction, createAsyncThunk, createSlice, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { Configuration } from "../api/client-axios";
import { AdminOrganizationsApi, OrganizationAdminDto, OrganizationDto } from "../api/client-axios/api";
import { ApiError } from "../model/apiError";
import { Problem } from "../model/problem";
import { apiConfiguration } from "./server";

export interface OrganizationAdminsState {
    organization?: OrganizationDto,
    organizationAdmins?: OrganizationAdminDto[],
    selectedOrganizationAdmin?: OrganizationAdminDto,
    status: "idle" | "loading" | "error" | "success" | "auth-error" | "deleting" ;
    error?: string | Problem;
    code?: number;
}

const initialOrganizationAdminsState: OrganizationAdminsState = {
    status: "idle",
    error: ""
}

export const getOrganization = createAsyncThunk<OrganizationDto, string, { rejectValue: ApiError }>('organizationAdmins/getOrganization', async (organizationId, thunkApi) => {
    try {
        const api = new AdminOrganizationsApi(apiConfiguration());
        const response = await api.apiV1AdministrationOrganizationsOrganizationIdGet(organizationId);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data, statusCode: error?.response.status });
    }
});
 

export const fetchOrganizationsAdmins = createAsyncThunk<OrganizationAdminDto[], string, { rejectValue: ApiError }>('organizationAdmins/fetchOrganizationAdmins', async (organizationId, thunkApi) => {
    try {
        const api = new AdminOrganizationsApi(apiConfiguration());
        const response = await api.apiV1AdministrationOrganizationsOrganizationIdAdminGet(organizationId);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data, statusCode: error?.response.status });
    }
});

export const deleteOrganizationAdmin = createAsyncThunk<OrganizationAdminDto[], {organizationId: string, id: string}, { rejectValue: ApiError }>('organizationAdmins/deleteOrganizationAdmin', async (args, thunkApi) => {
    try {
        const api = new AdminOrganizationsApi(apiConfiguration());
        await api.apiV1AdministrationOrganizationsOrganizationIdAdminIdDelete(args.organizationId, args.id);
        const response = await api.apiV1AdministrationOrganizationsOrganizationIdAdminGet(args.organizationId);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data, statusCode: error?.response.status });
    }
});


export const selectOrganizationAdmins = createAction("organizationAdmins/selectOrganizationAdmin", (organizationAdmin: OrganizationAdminDto) => ({ payload: organizationAdmin})); 
export const clearErrorOrganizationAdmins = createAction("organizationAdmins/clearErrorOrganizationAdmins");  
export const startDeleteOrganizationAdmins = createAction("organizationAdmins/startDeleteOrganizationAdmins"); 


const organizationAdminsSlice = createSlice({
    name: "organizationAdmins",
    initialState: initialOrganizationAdminsState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getOrganization.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getOrganization.fulfilled, (state, action) => {
                state.status = "loading";
                state.organization = action.payload;
            })
            .addCase(getOrganization.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(fetchOrganizationsAdmins.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchOrganizationsAdmins.fulfilled, (state, action) => {
                state.status = "success";
                state.organizationAdmins = action.payload;
            })
            .addCase(fetchOrganizationsAdmins.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(deleteOrganizationAdmin.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteOrganizationAdmin.fulfilled, (state, action) => {
                state.status = "success";
                state.organizationAdmins = action.payload;
            })
            .addCase(deleteOrganizationAdmin.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(selectOrganizationAdmins, (state, action) => {
                state.selectedOrganizationAdmin = action.payload;
            })
            .addCase(clearErrorOrganizationAdmins, (state) => {
               state.status = "success";
            })
            .addCase(startDeleteOrganizationAdmins, (state) => {
                state.status = "deleting";
            }) 
            ;
            
    }
});

export default organizationAdminsSlice.reducer;


function processError(
    action:
        PayloadAction<ApiError | undefined,
            string,
            { arg: string | OrganizationDto | void; requestId: string; requestStatus: "rejected"; aborted: boolean; condition: boolean; } &
            ({ rejectedWithValue: true; } | ({ rejectedWithValue: false; } & {})),
            SerializedError>,
    state: OrganizationAdminsState) {
    if (action.payload) {

        //state.code = action.payload.statusCode;
        //const apiError = action.payload as ApiError;
        if (action.payload !== undefined) {
            state.error = action.payload.message;
            state.code = action.payload.statusCode;
            if (state.code == 401) {
                state.status = "auth-error";
            } else {
                state.status = "error";
            }
        }
    } else {
        state.status = "error";
        state.error = action.error.message;
    }
}