import { CloseOutlined, EditOutlined, PlusOutlined, ReloadOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Input, Modal, Row, Space, Spin, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { PatientResponseDto, UserAccountDto } from "../api/client-axios";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import { clearErrorPatients, deletePatient, fetchPatients, selectPatient, startDeletePatient, updateSearchTerm } from "../features/patientsSlice";
import { Problem } from "../model/problem";
import { RouteNames } from "../routes";
import { formatPhone } from "../utils/formatPhone";

export const PatientsPage = (props: {
    organizationId: string
}) => {
    const dispatch = useAppDispatch();
    const patients = useAppSelector((state: RootState) => state.patients.patients);
    const selectedPatient = useAppSelector((state: RootState) => state.patients.selectedPatient);
    const searchTerm = useAppSelector((state: RootState) => state.patients.searchTerm);
    const countries = useAppSelector((state: RootState) => state.country.countries);
    const status = useAppSelector((state: RootState) => state.patients.status);
    const error = useAppSelector((state: RootState) => state.patients.error);
    const navigate = useNavigate();
    const params = useParams();


    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchPatients({ organizationId: props.organizationId }))
        }

    });


    if (params.patientId !== undefined) {
        return <Outlet />
    }


    const onWarnDeletePatient = () => {
        if (selectedPatient != null) {
            dispatch(startDeletePatient());
        }
    }

    const onAddPatient = () => {
        navigate(`/${RouteNames.organizations}/${props.organizationId}/patients/new`);
    }

    const onEditPatient = () => {
        if (selectedPatient != null) {
            navigate(`/${RouteNames.organizations}/${props.organizationId}/patients/${selectedPatient.id}`);
        }
    }

    const onReload = () => {

        dispatch(fetchPatients({ organizationId: props.organizationId }));
    }

    const onSearch = () => {
        dispatch(fetchPatients({ organizationId: props.organizationId, q: searchTerm }));
    }


    const onRow = (patient: PatientResponseDto, index: number | undefined) => {
        return {
            onClick: () => {
                dispatch(selectPatient(patient))
            }, // click row
        }
    }


    const onDeleteOrganizationAdmin = () => {
        if (selectedPatient != null) {
            dispatch(deletePatient({
                organizationId: props.organizationId,
                patientId: selectedPatient.id!
            }));
        }
    }

    const getRowClassName = (patient: PatientResponseDto, index: number) => {
        return selectedPatient != null && selectedPatient.id === patient.id ? 'bg-selected' : '';
    }


    const getErrorMessage = (): { title: string, details: string } => {
        const apiError = error as Problem;
        const title = typeof error === 'string' ? error : apiError.title ?? "Server Error";
        const detail = typeof error === 'string' ? "" : apiError.detail ?? "";
        return { title, details: detail };
    }

    const errorMessage = getErrorMessage();


    const columns: ColumnsType<PatientResponseDto> = [
        {

            // width: '100%',
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
            sorter: (a, b) => a.firstName! > b.firstName! ? -1 : 1,
        },
        {

            //width: '100%',
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
            sorter: (a, b) => a.firstName! > b.firstName! ? -1 : 1,
        },
        {

            //width: '100%',
            title: 'Email',
            dataIndex: 'userAccount',
            key: 'email',
            sorter: (a, b) => a.userAccount?.emailAddress! > b.userAccount?.emailAddress! ? -1 : 1,
            render: (userAccount?: UserAccountDto) => userAccount?.emailAddress
        },
        {

            //width: '100%',
            title: 'Phone',
            dataIndex: 'userAccount',
            key: 'phone',
            sorter: (a, b) => a.userAccount?.mobileNumber! > b.userAccount?.mobileNumber! ? -1 : 1,
            render: (userAccount?: UserAccountDto) => formatPhone(userAccount?.mobileNumber, countries)
        },

    ];

    //todo implement server side paging and sorting
    const buttons = <Row >
        <Col span={24}>
            <Row className="" justify="space-between">
                <Space>
                    <Input placeholder='John' defaultValue={searchTerm} onChange={(e) => { dispatch(updateSearchTerm(e.target.value)); }} onPressEnter={onSearch} />
                    <Button type="primary" icon={<SearchOutlined />} onClick={onSearch}>Search</Button>
                </Space>

                <Space>
                    <Button disabled={selectedPatient == null} type='primary' danger icon={<CloseOutlined />} onClick={onWarnDeletePatient} >Delete</Button>
                    {selectedPatient?.id != null ? <Button type='primary' icon={<EditOutlined />} onClick={onEditPatient}>Edit</Button> : <div></div>}
                    <Button type='primary' icon={<PlusOutlined />} onClick={onAddPatient}>Add</Button>
                    <Button type='text' shape="circle" icon={<ReloadOutlined />} onClick={onReload} />
                </Space>

            </Row>
        </Col>
    </Row>;

    const content = status === 'loading' || status === 'idle'
        ? <Spin className="flex-center mt-xxl" />
        : <div>
            {buttons}
            <Table className="mt-s"
                rowKey={(patient: PatientResponseDto) => patient.id!}
                rowClassName={getRowClassName}
                onRow={onRow}
                dataSource={patients}
                columns={columns} />

            <Modal title={errorMessage.title} visible={status === 'error' || status === 'auth-error'} onOk={() => dispatch(clearErrorPatients())} cancelButtonProps={{ hidden: true }}>
                <p>{errorMessage.details}</p>
            </Modal>
            <Modal visible={status === "deleting"} okText="Yes" cancelText="No" onCancel={() => dispatch(clearErrorPatients())} onOk={onDeleteOrganizationAdmin} >Are you sure to complitely delete patient "{selectedPatient?.firstName} {selectedPatient?.lastName}"</Modal>
        </div>

    return (<div className="mt-s" >
        {content}
    </div>);
}
