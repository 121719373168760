export class RouteNames{
    static organizations: string = 'organizations';
    static individuals: string = 'individuals';
    static organizationAdmins: string = 'organizationAdmins';
    static measurementTypes: string = 'measurementTypes';
    static activities: string = 'activities';
    static imagesLibrary: string = "imagesLibrary";
    static audioLibrary: string = "audioLibrary"; 
    static treatments: string = "treatments"; 
    static account: string = 'account';
 }
 