import {createAction, createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {AxiosError} from "axios";
// @ts-ignore
import {Configuration, GlobalFamilyMembersApi, FamilyMemberResponseDto, FamilyMemberResponseDtoPagedList} from "../api/client-axios";
import {ApiError} from "../model/apiError";
import {Problem} from "../model/problem";
import {apiConfiguration} from "./server";


export interface FamilyMembersNoOrgState {
    familyMembers?: FamilyMemberResponseDto[],
    selectedFamilyMember?: FamilyMemberResponseDto,
    searchTerm?: string,
    status: "idle" | "loading" | "error" | "success" | "auth-error" | "deleting";
    error?: string | Problem;
    code?: number;
}


const initialNoOrgFamilyMemberState: FamilyMembersNoOrgState = {
    status: "idle",
    error: ""
}


export const fetchNoOrgFamilyMembers = createAsyncThunk<FamilyMemberResponseDtoPagedList, {
    pageNumber?: number,
    pageSize?: number,
    q?: string
}, { rejectValue: ApiError }>('familyMembers/fetchNoOrgFamilyMembers', async (args, thunkApi) => {
    try {

        const api = new GlobalFamilyMembersApi(apiConfiguration());
        const response = await api.apiV1AdministrationNoOrgFamilyMembersGet(args.pageNumber, args.pageSize, args.q);
        return response.data;

    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({message: error?.response.data.toString(), statusCode: error?.response.status});
    }
});


export const deleteNoOrgFamilyMember = createAsyncThunk<FamilyMemberResponseDtoPagedList, { familyMemberId: string }, {
    rejectValue: ApiError
}>('familyMembers/deleteNoOrgFamilyMember', async (args, thunkApi) => {
    try {

        const api = new GlobalFamilyMembersApi(apiConfiguration());
        await api.apiV1AdministrationNoOrgFamilyMembersIdDelete(args.familyMemberId);
        const response = await api.apiV1AdministrationNoOrgFamilyMembersGet();
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({message: error?.response.data.toString(), statusCode: error?.response.status});
    }
});


export const selectNoOrgFamilyMember = createAction("familyMember/selectNoOrgFamilyMember", (familyMember: FamilyMemberResponseDto) => ({payload: familyMember}));
export const clearErrorNoOrgFamilyMembers = createAction("familyMember/clearErrorNoOrgFamilyMembers");
export const startDeleteNoOrgFamilyMember = createAction("familyMember/startDeleteNoOrgFamilyMember");
export const updateSearchTermNoOrgFamilyMember = createAction("familyMember/searchTermNoOrgFamilyMember", (searchTerm: string) => ({payload: searchTerm}));


const familyMembersNoOrgSlice = createSlice({
    name: 'familyMembers',
    initialState: initialNoOrgFamilyMemberState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchNoOrgFamilyMembers.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(fetchNoOrgFamilyMembers.fulfilled, (state, action) => {
                state.familyMembers = action.payload.items;
                state.status = "success";
            })
            .addCase(fetchNoOrgFamilyMembers.rejected, (state, action) => {
                if (action.payload) {
                    state.status = "auth-error";
                    state.error = action.payload.message;
                    state.code = action.payload.statusCode;
                } else {
                    state.status = "error";
                    state.error = action.error.message;
                }
            })
            .addCase(deleteNoOrgFamilyMember.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(deleteNoOrgFamilyMember.fulfilled, (state, action) => {
                state.status = "success";
            })
            .addCase(deleteNoOrgFamilyMember.rejected, (state, action) => {
                if (action.payload) {
                    state.status = "auth-error";
                    state.error = action.payload.message;
                    state.code = action.payload.statusCode;
                } else {
                    state.status = "error";
                    state.error = action.error.message;
                }
            })
            .addCase(selectNoOrgFamilyMember, (state, action) => {
                state.selectedFamilyMember = action.payload;
            })
            .addCase(clearErrorNoOrgFamilyMembers, (state) => {
                state.status = "success";
            })
            .addCase(startDeleteNoOrgFamilyMember, (state) => {
                state.status = "deleting";
            })
            .addCase(updateSearchTermNoOrgFamilyMember, (state, action) => {
                state.searchTerm = action.payload;
            })
        ;


    }
});

export default familyMembersNoOrgSlice.reducer;
