// This is just for the demo. Fetch data with an API jwt token
// Should be removed completely in production

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ActivityApi, CountriesApi, CountryDto } from "../api/client-axios/api";
import { Configuration } from "../api/client-axios/configuration";
import { ApiError } from "../model/apiError";
import { Problem } from "../model/problem";
import { apiConfiguration } from "./server";


export interface CountryState {
    countries: CountryDto[];
    status: "idle" | "loading" | "error" | "success" | "auth-error";
    error?: string | Problem;
    code?: number;
}


const initialCountryState: CountryState = {
    countries: [],
    status: "idle",
    error: ""
}


export const fetchCountries = createAsyncThunk<CountryDto[],void,{rejectValue: ApiError}>('country/fetchCountries', async (_,thunkApi) => {
    try{
        const api = new CountriesApi(apiConfiguration());
        const response = await api.apiV1CountriesAllGet();    
        return response.data;
    } catch(error: AxiosError | any){
       return  thunkApi.rejectWithValue({message: error?.response.data.toString(), statusCode: error?.response.status});
    }
    
  
});


const countrySlice = createSlice({
    name: 'country',
    initialState: initialCountryState,
    reducers: {},
    extraReducers: builder => {
        builder
        .addCase(fetchCountries.pending, (state, action) => {
            state.status = "loading";
        })
        .addCase(fetchCountries.fulfilled, (state, action) => {
            state.countries = action.payload;
            state.status = "success";
        })
        .addCase(fetchCountries.rejected, (state, action) => {
            if(action.payload){
                state.status = "auth-error";
                state.error = action.payload.message;
                state.code = action.payload.statusCode;
            }else{
                state.status = "error";
                state.error = action.error.message;
            }
           
        })   
    }
});

export default countrySlice.reducer;