import {createAction, createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {AxiosError} from "axios";
import {Configuration, GlobalPatientApi, PatientResponseDto, PatientResponseDtoPagedList} from "../api/client-axios";
import {ApiError} from "../model/apiError";
import {Problem} from "../model/problem";
import {apiConfiguration} from "./server";


export interface PatientsState {
    patients?: PatientResponseDto[],
    selectedPatient?: PatientResponseDto,
    searchTerm?: string,
    status: "idle" | "loading" | "error" | "success" | "auth-error" | "deleting";
    error?: string | Problem;
    code?: number;
}


const initialPatientState: PatientsState = {
    status: "idle",
    error: ""
}


export const fetchPatients = createAsyncThunk<PatientResponseDtoPagedList, {
    organizationId: string,
    pageNumber?: number,
    pageSize?: number,
    q?: string
}, { rejectValue: ApiError }>('patients/fetchPatients', async (args, thunkApi) => {
    try {

        const api = new GlobalPatientApi(apiConfiguration());
        const response = await api.apiV1AdministrationOrganizationOrganizationIdPatientsGet(args.organizationId, args.pageNumber, args.pageSize, args.q);
        return response.data;

    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({message: error?.response.data.toString(), statusCode: error?.response.status});
    }
});

export const deletePatient = createAsyncThunk<PatientResponseDtoPagedList, {
    organizationId: string,
    patientId: string
}, { rejectValue: ApiError }>('patients/deletePatient', async (args, thunkApi) => {
    try {

        const api = new GlobalPatientApi(apiConfiguration());
        await api.apiV1AdministrationOrganizationOrganizationIdPatientsIdDelete(args.patientId, args.organizationId);
        const response = await api.apiV1AdministrationOrganizationOrganizationIdPatientsGet(args.organizationId);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({message: error?.response.data.toString(), statusCode: error?.response.status});
    }
});


export const selectPatient = createAction("patient/selectPatient", (patient: PatientResponseDto) => ({payload: patient}));
export const clearErrorPatients = createAction("patient/clearErrorPatients");
export const startDeletePatient = createAction("patient/startDeletePatient");
export const updateSearchTerm = createAction("patient/searchTerm", (searchTerm: string) => ({payload: searchTerm}));


const patientsSlice = createSlice({
    name: 'patients',
    initialState: initialPatientState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchPatients.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(fetchPatients.fulfilled, (state, action) => {
                state.patients = action.payload.items;
                state.status = "success";
            })
            .addCase(fetchPatients.rejected, (state, action) => {
                if (action.payload) {
                    state.status = "auth-error";
                    state.error = action.payload.message;
                    state.code = action.payload.statusCode;
                } else {
                    state.status = "error";
                    state.error = action.error.message;
                }
            })
            .addCase(deletePatient.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(deletePatient.fulfilled, (state, action) => {
                state.status = "success";
            })
            .addCase(deletePatient.rejected, (state, action) => {
                if (action.payload) {
                    state.status = "auth-error";
                    state.error = action.payload.message;
                    state.code = action.payload.statusCode;
                } else {
                    state.status = "error";
                    state.error = action.error.message;
                }
            })
            .addCase(selectPatient, (state, action) => {
                state.selectedPatient = action.payload;
            })
            .addCase(clearErrorPatients, (state) => {
                state.status = "success";
            })
            .addCase(startDeletePatient, (state) => {
                state.status = "deleting";
            })
            .addCase(updateSearchTerm, (state, action) => {
                state.searchTerm = action.payload;
            })
        ;


    }
});

export default patientsSlice.reducer;
