import {createAction, createAsyncThunk, createSlice, PayloadAction, SerializedError} from "@reduxjs/toolkit";
import {AxiosError} from "axios";
import {GlobalPatientApi, OrganizationPatientsApi, PatientRequestDto, PatientResponseDto} from "../api/client-axios";
import {Problem} from "../model/problem";
import {ApiError} from "../model/apiError";
import {apiConfiguration} from "./server";


export interface AddPatientByCodeState {
    patient?: PatientResponseDto;
    activationCode: string;
    status: "idle" | "loading" | "error" | "success" | "auth-error";
    error?: string | Problem;
    code?: number;
}


const initialPatientByCodeState: AddPatientByCodeState = {
    status: "idle",
    activationCode: ""
}


export const clearAddPatientByCodeError = createAction('addPatientByCode/clearError');
export const initAddPatientByCodeState = createAction('addPatientByCode/initState');

export const setActivationCode = createAction('addPatientByCode/setActivationCode', (code: string) => {
    return {payload: code}
});
export const getPatientByCode = createAsyncThunk<PatientResponseDto | undefined, {
    code: string,
    organizationId: string
}, {
    rejectValue: ApiError
}>('addPatientByCode/getPatientByCode', async (args: { code: string, organizationId: string }, thunkAPI) => {
    try {
        const api = new GlobalPatientApi(apiConfiguration());
        const response = await api.apiV1AdministrationOrganizationOrganizationIdPatientsByActivationCodeGet(args.organizationId, args.code)
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkAPI.rejectWithValue({message: error?.response.data, statusCode: error?.response.status});
    }
});

const addPatientByCodeSlice = createSlice({
    name: 'addPatientByCode',
    initialState: initialPatientByCodeState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getPatientByCode.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(getPatientByCode.fulfilled, (state, action) => {
                state.patient = action.payload;
                state.status = "success";
            })
            .addCase(getPatientByCode.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(setActivationCode, (state, action) => {
                state.activationCode = action.payload;
            })
            .addCase(clearAddPatientByCodeError, (state, _) => {
                state.status = "idle";
            })
            .addCase(initAddPatientByCodeState, (state, _) => {
                state.patient = undefined;
                state.status = "idle";
                state.error = "";
                state.code = undefined;
                state.activationCode = "";
            });


    }
});

export default addPatientByCodeSlice.reducer;

function processError(
    action:
        PayloadAction<ApiError | undefined,
            string,
            {
                arg: string | PatientRequestDto | { code: string, organizationId: string };
                requestId: string;
                requestStatus: "rejected";
                aborted: boolean;
                condition: boolean;
            } &
            ({ rejectedWithValue: true; } | ({ rejectedWithValue: false; } & {})),
            SerializedError>,
    state: AddPatientByCodeState) {
    if (action.payload) {
        state.error = action.payload.message;
        state.code = action.payload.statusCode;
        if (state.code == 401 || state.code == 403) {
            state.status = "auth-error";
        } else {
            state.status = "error";
        }
    } else {
        state.status = "error";
        state.error = action.error.message;
    }
}