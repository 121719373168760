import {useAppDispatch, useAppSelector} from "../app/hooks";
import {RootState} from "../app/store";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import React, {useEffect} from "react";
import {
    clearErrorNoOrgCaretakers,
    deleteNoOrgCaretaker,
    fetchNoOrgCaretakers,
    selectNoOrgCaretaker,
    startDeleteNoOrgCaretaker, updateNoOrgCaretakerSearchTerm
} from "../features/caretakersNoOrgSlice";
import {RouteNames} from "../routes";
import {CaretakerResponseDto, UserAccountDto} from "../api/client-axios";
import {Problem} from "../model/problem";
import {ColumnsType} from "antd/lib/table/interface";
import {formatPhone} from "../utils/formatPhone";
import {Button, Col, Input, Modal, Row, Space, Spin, Table} from "antd";
import {CloseOutlined, EditOutlined, ReloadOutlined, SearchOutlined} from "@ant-design/icons";


export const CaretakersIndividualsPage = () => {
    const dispatch = useAppDispatch();
    const caretakers = useAppSelector((state: RootState) => state.caretakersNoOrg.caretakers);
    const selectedCaretaker = useAppSelector((state: RootState) => state.caretakersNoOrg.selectedCaretaker);
    const searchTerm = useAppSelector((state: RootState) => state.caretakersNoOrg.searchTerm);
    const countries = useAppSelector((state: RootState) => state.country.countries);
    const status = useAppSelector((state: RootState) => state.caretakersNoOrg.status);
    const error = useAppSelector((state: RootState) => state.caretakersNoOrg.error);
    const navigate = useNavigate();
    const params = useParams();


    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchNoOrgCaretakers({}))
        }
    });


    if (params.caretakerId !== undefined) {
        return <Outlet/>
    }


    const onWarnDeleteCaretaker = () => {
        if (selectedCaretaker != null) {
            dispatch(startDeleteNoOrgCaretaker());
        }
    }


    const onEditCaretaker = () => {
        if (selectedCaretaker != null) {
            navigate(`/${RouteNames.individuals}/caretakers/${selectedCaretaker.id}`);
        }
    }

    const onReload = () => {

        dispatch(fetchNoOrgCaretakers({}));
    }

    const onSearch = () => {
        dispatch(fetchNoOrgCaretakers({q: searchTerm}));
    }


    const onRow = (caretaker: CaretakerResponseDto, index: number | undefined) => {
        return {
            onClick: () => {
                dispatch(selectNoOrgCaretaker(caretaker))
            }, // click row
        }
    }


    const onDeleteOrganizationAdmin = () => {
        if (selectedCaretaker != null) {
            dispatch(deleteNoOrgCaretaker({
                caretakerId: selectedCaretaker.id!
            }));
        }
    }

    const getRowClassName = (caretaker: CaretakerResponseDto, index: number) => {
        return selectedCaretaker != null && selectedCaretaker.id === caretaker.id ? 'bg-selected' : '';
    }


    const getErrorMessage = (): { title: string, details: string } => {
        const apiError = error as Problem;
        const title = typeof error === 'string' ? error : apiError.title ?? "Server Error";
        const detail = typeof error === 'string' ? "" : apiError.detail ?? "";
        return {title, details: detail};
    }

    const errorMessage = getErrorMessage();


    const columns: ColumnsType<CaretakerResponseDto> = [
        {

            // width: '100%',
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
            sorter: (a, b) => a.firstName! > b.firstName! ? -1 : 1,
        },
        {

            //width: '100%',
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
            sorter: (a, b) => a.firstName! > b.firstName! ? -1 : 1,
        },
        {

            //width: '100%',
            title: 'Email',
            dataIndex: 'userAccount',
            key: 'email',
            sorter: (a, b) => a.userAccount?.emailAddress! > b.userAccount?.emailAddress! ? -1 : 1,
            render: (userAccount?: UserAccountDto) => userAccount?.emailAddress
        },
        {

            //width: '100%',
            title: 'Phone',
            dataIndex: 'userAccount',
            key: 'phone',
            sorter: (a, b) => a.userAccount?.mobileNumber! > b.userAccount?.mobileNumber! ? -1 : 1,
            render: (userAccount?: UserAccountDto) => formatPhone(userAccount?.mobileNumber, countries)
        },

    ];

    const buttons = <Row>
        <Col span={24}>
            <Row className="" justify="space-between">
                <Space>
                    <Input placeholder='John' defaultValue={searchTerm} onChange={(e) => {
                        dispatch(updateNoOrgCaretakerSearchTerm(e.target.value));
                    }} onPressEnter={onSearch}/>
                    <Button type="primary" icon={<SearchOutlined/>} onClick={onSearch}>Search</Button>
                </Space>


                <Space>
                    <Button disabled={selectedCaretaker == null} type='primary' danger icon={<CloseOutlined/>}
                            onClick={onWarnDeleteCaretaker}>Delete</Button>
                    {selectedCaretaker?.id != null ?
                        <Button type='primary' icon={<EditOutlined/>} onClick={onEditCaretaker}>Edit</Button> :
                        <div></div>}
                    <Button type='text' shape="circle" icon={<ReloadOutlined/>} onClick={onReload}/>
                </Space>

            </Row>
        </Col>
    </Row>;

    const content = status === 'loading' || status === 'idle'
        ? <Spin className="flex-center mt-xxl"/>
        : <div>
            {buttons}
            <Table className="mt-s"
                   rowKey={(caretaker: CaretakerResponseDto) => caretaker.id!}
                   rowClassName={getRowClassName}
                   onRow={onRow}
                   dataSource={caretakers}
                   columns={columns}/>

            <Modal title={errorMessage.title} visible={status === 'error' || status === 'auth-error'}
                   onOk={() => dispatch(clearErrorNoOrgCaretakers())} cancelButtonProps={{hidden: true}}>
                <p>{errorMessage.details}</p>
            </Modal>
            <Modal visible={status === "deleting"} okText="Yes" cancelText="No"
                   onCancel={() => dispatch(clearErrorNoOrgCaretakers())} onOk={onDeleteOrganizationAdmin}>Are you sure
                to completely delete caretaker "{selectedCaretaker?.firstName} {selectedCaretaker?.lastName}"</Modal>
        </div>


    return (<div className="mt-s">
        {content}
    </div>);
}
