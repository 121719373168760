import { Button } from "antd";
import {useAuth} from "react-oidc-context";

export const AccountPage = () => {
    const auth = useAuth();


    const logoutAuth0 = async () => {
        localStorage.removeItem("token");
        await auth.signoutRedirect({post_logout_redirect_uri: window.location.origin, id_token_hint: auth.user?.id_token })
        await auth.removeUser();
    };

    if (auth.isAuthenticated) {
        return (
            <div className="d-flex justify-end items-end mt-s mr-xl">
                <span style={{ paddingRight: '20px' }}>{auth.user?.profile.name}</span>
                <Button type="primary" onClick={logoutAuth0}>Logout</Button>
            </div>
        );
    }

    return (<div>Not authenticated</div>);
}