import {createAsyncThunk, createAction, createSlice, PayloadAction, SerializedError} from "@reduxjs/toolkit";
import {AxiosError} from "axios";
import {
    CaretakerCreateRequestDto,
    CaretakerResponseDto,
    CaretakerUpdateRequestDto,
    Configuration,
    GlobalCaretakerApi
} from "../api/client-axios";

import {ApiError} from "../model/apiError";
import {Problem} from "../model/problem";
import {initOtpState} from "./otpSlice";
import {apiConfiguration} from "./server";

export interface CaretakerNoOrgState {
    caretaker?: CaretakerResponseDto,
    status: "idle" | "loading" | "error" | "success" | "auth-error" | "saved";
    error?: string | Problem;
    code?: number;
}


const initialNoOrgCaretakerState: CaretakerNoOrgState = {
    status: "idle",
    error: ""
}

export const fetchNoOrgCaretaker = createAsyncThunk<CaretakerResponseDto, { caretakerId: string }, {
    rejectValue: ApiError
}>('caretaker/fetchNoOrgCaretaker', async (args, thunkApi) => {
    try {
        const api = new GlobalCaretakerApi(apiConfiguration());
        if (args.caretakerId === "new") {
            thunkApi.dispatch(initOtpState());
            return {} as CaretakerResponseDto;
        }
        const response = await api.apiV1AdministrationNoOrgCaretakersIdGet(args.caretakerId);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({message: error?.response.data, statusCode: error?.response.status});
    }
});


export const saveNoOrgCaretaker = createAsyncThunk<CaretakerResponseDto, {
    caretakerId: string,
    caretaker: CaretakerUpdateRequestDto
}, { rejectValue: ApiError }>('caretaker/saveNoOrgCaretaker', async (args, thunkApi) => {
    try {
        const api = new GlobalCaretakerApi(apiConfiguration());
        const response = await api.apiV1AdministrationNoOrgCaretakersIdPost(args.caretakerId, args.caretaker);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({message: error?.response.data, statusCode: error?.response.status});
    }
});

export const updateNoOrgCaretakerFields = createAction("caretaker/updateNoOrgCaretakerFields", (caretaker: CaretakerCreateRequestDto) => {
    return {payload: caretaker}
});
export const clearSaveNoOrgCaretakerError = createAction('caretakerNoOrg/clearError');
export const updateNoOrgCaretakerPhoneNumber = createAction('caretaker/updateNoOrgCaretakerPhoneNumber', (phoneNumber: string) => {
    return {payload: phoneNumber};
});
export const initNoOrgCaretakerState = createAction('caretakerNoOrg/init');

const caretakerNoOrgSlice = createSlice({
    name: 'caretakerNoOrg',
    initialState: initialNoOrgCaretakerState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchNoOrgCaretaker.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(fetchNoOrgCaretaker.fulfilled, (state, action) => {
                state.caretaker = action.payload;
                state.status = "success";
            })
            .addCase(fetchNoOrgCaretaker.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(saveNoOrgCaretaker.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(saveNoOrgCaretaker.fulfilled, (state, action) => {
                state.caretaker = action.payload;
                state.status = "saved";
            })
            .addCase(saveNoOrgCaretaker.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(clearSaveNoOrgCaretakerError, (state, action) => {
                state.status = "success";
                state.error = "";
            })
            .addCase(updateNoOrgCaretakerPhoneNumber, (state, action) => {
                state.caretaker!.userAccount = {
                    mobileNumber: action.payload,
                    emailAddress: state.caretaker?.userAccount?.emailAddress
                };
            })
            .addCase(initNoOrgCaretakerState, (state, action) => {
                state.status = "idle";
                state.caretaker = {};
                state.code = undefined;
                state.error = undefined;
            })
            .addCase(updateNoOrgCaretakerFields, (state, action) => {
                state.caretaker = {
                    ...state.caretaker,
                    ...action.payload as CaretakerResponseDto
                };
            });

    }
});

export default caretakerNoOrgSlice.reducer;


function processError(
    action:
        PayloadAction<ApiError | undefined,
            string,
            {
                arg: string | CaretakerCreateRequestDto | { caretakerId: string } | {
                    caretaker: CaretakerCreateRequestDto
                };
                requestId: string;
                requestStatus: "rejected";
                aborted: boolean;
                condition: boolean;
            } &
            ({ rejectedWithValue: true; } | ({ rejectedWithValue: false; } & {})),
            SerializedError>,
    state: CaretakerNoOrgState) {
    if (action.payload) {

        //state.code = action.payload.statusCode;
        //const apiError = action.payload as ApiError;
        if (action.payload !== undefined) {
            state.error = action.payload.message;
            state.code = action.payload.statusCode;
            if (state.code == 401) {
                state.status = "auth-error";
            } else {
                state.status = "error";
            }
        }
    } else {
        state.status = "error";
        state.error = action.error.message;
    }
}

