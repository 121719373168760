import { CloseOutlined, EditOutlined, PlusOutlined, ReloadOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Input, Modal, Row, Space, Spin, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { DoctorResponseDto, UserAccountDto } from "../api/client-axios";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import { Problem } from "../model/problem";
import { RouteNames } from "../routes";
import { formatPhone } from "../utils/formatPhone";
import {
    clearErrorNoOrgDoctors,
    deleteNoOrgDoctor,
    fetchNoOrgDoctors,
    selectNoOrgDoctor,
    startDeleteNoOrgDoctor, updateSearchTermNoOrgDoctor
} from "../features/doctorsNoOrgSlice";

export const DoctorsIndividualsPage = () => {
    const dispatch = useAppDispatch();
    const doctors = useAppSelector((state: RootState) => state.doctorsNoOrg.doctors);
    const selectedDoctor = useAppSelector((state: RootState) => state.doctorsNoOrg.selectedDoctor);
    const searchTerm = useAppSelector((state: RootState) => state.doctorsNoOrg.searchTerm);
    const countries = useAppSelector((state: RootState) => state.country.countries);
    const status = useAppSelector((state: RootState) => state.doctorsNoOrg.status);
    const error = useAppSelector((state: RootState) => state.doctorsNoOrg.error);
    const navigate = useNavigate();
    const params = useParams();


    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchNoOrgDoctors({  }))
        }

    });


    if (params.doctorId !== undefined) {
        return <Outlet />
    }


    const onWarnDeleteDoctor = () => {
        if (selectedDoctor != null) {
            dispatch(startDeleteNoOrgDoctor());
        }
    }

    const onEditDoctor = () => {
        if (selectedDoctor != null) {
            navigate(`/${RouteNames.individuals}/doctors/${selectedDoctor.id}`);
        }
    }

    const onReload = () => {

        dispatch(fetchNoOrgDoctors({ }));
    }

    const onSearch = () => {
        dispatch(fetchNoOrgDoctors({  q: searchTerm }));
    }


    const onRow = (doctor: DoctorResponseDto, index: number | undefined) => {
        return {
            onClick: () => {
                dispatch(selectNoOrgDoctor(doctor))
            }, // click row
        }
    }


    const onDeleteOrganizationAdmin = () => {
        if (selectedDoctor != null) {
            dispatch(deleteNoOrgDoctor({
                doctorId: selectedDoctor.id!
            }));
        }
    }

    const getRowClassName = (doctor: DoctorResponseDto, index: number) => {
        return selectedDoctor != null && selectedDoctor.id === doctor.id ? 'bg-selected' : '';
    }


    const getErrorMessage = (): { title: string, details: string } => {
        const apiError = error as Problem;
        const title = typeof error === 'string' ? error : apiError.title ?? "Server Error";
        const detail = typeof error === 'string' ? "" : apiError.detail ?? "";
        return { title, details: detail };
    }

    const errorMessage = getErrorMessage();


    const columns: ColumnsType<DoctorResponseDto> = [
        {

            // width: '100%',
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
            sorter: (a, b) => a.firstName! > b.firstName! ? -1 : 1,
        },
        {

            //width: '100%',
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
            sorter: (a, b) => a.firstName! > b.firstName! ? -1 : 1,
        },
        {

            //width: '100%',
            title: 'Email',
            dataIndex: 'userAccount',
            key: 'email',
            sorter: (a, b) => a.userAccount?.emailAddress! > b.userAccount?.emailAddress! ? -1 : 1,
            render: (userAccount?: UserAccountDto) => userAccount?.emailAddress
        },
        {

            //width: '100%',
            title: 'Phone',
            dataIndex: 'userAccount',
            key: 'phone',
            sorter: (a, b) => a.userAccount?.mobileNumber! > b.userAccount?.mobileNumber! ? -1 : 1,
            render: (userAccount?: UserAccountDto) => formatPhone(userAccount?.mobileNumber, countries)
        },

    ];

    //todo implement server side paging and sorting
    const buttons = <Row >
        <Col span={24}>
            <Row className="" justify="space-between">
                <Space>
                    <Input placeholder='John' defaultValue={searchTerm} onChange={(e) => { dispatch(updateSearchTermNoOrgDoctor(e.target.value)); }} onPressEnter={onSearch} />
                    <Button type="primary" icon={<SearchOutlined />} onClick={onSearch}>Search</Button>
                </Space>


                <Space>
                <Button disabled={selectedDoctor == null} type='primary' danger icon={<CloseOutlined />} onClick={onWarnDeleteDoctor} >Delete</Button>
                    {selectedDoctor?.id != null ? <Button type='primary' icon={<EditOutlined />} onClick={onEditDoctor}>Edit</Button> : <div></div>}
                    <Button type='text' shape="circle" icon={<ReloadOutlined />} onClick={onReload} />
                </Space>

            </Row>
        </Col>
    </Row>;

    const content = status === 'loading' || status === 'idle'
        ? <Spin className="flex-center mt-xxl" />
        : <div>
            {buttons}
            <Table className="mt-s"
                rowKey={(doctor: DoctorResponseDto) => doctor.id!}
                rowClassName={getRowClassName}
                onRow={onRow}
                dataSource={doctors}
                columns={columns} />

            <Modal title={errorMessage.title} visible={status === 'error' || status === 'auth-error'} onOk={() => dispatch(clearErrorNoOrgDoctors())} cancelButtonProps={{ hidden: true }}>
                <p>{errorMessage.details}</p>
            </Modal>
            <Modal visible={status === "deleting"} okText="Yes" cancelText="No" onCancel={() => dispatch(clearErrorNoOrgDoctors())} onOk={onDeleteOrganizationAdmin} >Are you sure to complitely delete doctor "{selectedDoctor?.firstName} {selectedDoctor?.lastName}"</Modal>
        </div>

    return (<div className="mt-s" >
        {content}
    </div>);
}
