import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { Configuration, GlobalAudioLibraryApi, AudioFileResponseDto } from "../api/client-axios";
import { ApiError } from "../model/apiError";
import { Problem } from "../model/problem";
import { apiConfiguration } from "./server";


export type AudioResult ={
    audio: AudioFileResponseDto, url: string
}

export interface AudiosLibraryState {
    audios?: AudioFileResponseDto[],
    selectedAudio?: AudioFileResponseDto,
    status: "idle" | "loading" | "error" | "success" | "auth-error" | "deleting";
    error?: string | Problem;
    code?: number;
}


const initialAudiosLibraryState: AudiosLibraryState = {
    status: "idle",
    error: ""
}



export const fetchAudios = createAsyncThunk<AudioFileResponseDto[],void, { rejectValue: ApiError }>('audioLibrary/fetchAudios', async (_, thunkApi) => {
    try {
        const api = new GlobalAudioLibraryApi(apiConfiguration());
        const response = await api.apiV1AdministrationAudioLibraryGet();
        //const audios = response.data as AudioFileResponseDto[];
        //var result: AudioResult[] = [];
        // for(var audio of audios){
        //     const r = await api.apiV1AdministrationAudioLibraryIdContentGet(audio.id!,{responseType: 'blob'});
        //     const url = await readFileAsync(r.data);
        //     result = [...result,{audio, url: url as string} as AudioResult];
        // }
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data.toString(), statusCode: error?.response.status });
    }
});


// export const uploadAudio = createAsyncThunk<AudioFileResponseDto[], void, { rejectValue: ApiError }>('audioLibrary/uploadAudio', async (args, thunkApi) => {
//     try {
//         const api = new GlobalAudioLibraryApi(apiConfiguration);
//         await api.apiV1AdministrationAudioLibraryPost(args);
//         const response = await api.apiV1AdministrationAudioLibraryGet();
//         return response.data;
//     } catch (error: AxiosError | any) {
//         return thunkApi.rejectWithValue({ message: error?.response.data.toString(), statusCode: error?.response.status });
//     }
// });

// export const deleteAudio = createAsyncThunk<AudioFileResponseDto[],string, { rejectValue: ApiError }>('audioLibrary/deleteAudio', async (audioId, thunkApi) => {
//     try {
//         const api = new GlobalAudioLibraryApi(apiConfiguration);
//         await api.apiV1AdministrationAudioLibraryIdDelete(audioId);
//         const response = await api.apiV1AdministrationAudioLibraryGet();
//         return response.data;
//     } catch (error: AxiosError | any) {
//         return thunkApi.rejectWithValue({ message: error?.response.data.toString(), statusCode: error?.response.status });
//     }
// });


export const selectAudio = createAction("audioLibrary/selectAudio", (audio: AudioFileResponseDto) => ({ payload: audio }));
export const clearErrorAudios = createAction("audioLibrary/clearErrorAudios");
export const startDeleteAudio = createAction("audioLibrary/startDeleteAudio");
export const showAudioError = createAction("audioLibrary/showAudioError", (error: Problem) => ({ payload: error }));



const audiosLibrarysSlice = createSlice({
    name: 'audioLibrary',
    initialState: initialAudiosLibraryState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAudios.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(fetchAudios.fulfilled, (state, action) => {
                state.audios = action.payload;
                state.status = "success";
            })
            .addCase(fetchAudios.rejected, (state, action) => {
                if (action.payload) {
                    state.status = "auth-error";
                    state.error = action.payload.message;
                    state.code = action.payload.statusCode;
                } else {
                    state.status = "error";
                    state.error = action.error.message;
                }
            })
            // .addCase(deleteAudio.pending, (state, _) => {
            //     state.status = "loading";
            // })
            // .addCase(deleteAudio.fulfilled, (state, action) => {
              
            //     state.status = "success";
            // })
            // .addCase(deleteAudio.rejected, (state, action) => {
            //     if (action.payload) {
            //         state.status = "auth-error";
            //         state.error = action.payload.message;
            //         state.code = action.payload.statusCode;
            //     } else {
            //         state.status = "error";
            //         state.error = action.error.message;
            //     }
            // })
            .addCase(selectAudio, (state, action) => {
                state.selectedAudio = action.payload;
            })
            .addCase(clearErrorAudios, (state) => {
                state.status = "success";
             })
             .addCase(startDeleteAudio, (state) => {
                 state.status = "deleting";
             }) 
             .addCase(showAudioError,(state, action) => {
                state.status = "error";
                state.error = action.payload;
             }) 
            ;


    }
});

export default audiosLibrarysSlice.reducer;
