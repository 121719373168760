import {createAsyncThunk, createAction, createSlice, PayloadAction, SerializedError} from "@reduxjs/toolkit";
import {AxiosError} from "axios";

import {ApiError} from "../model/apiError";
import {Problem} from "../model/problem";
import {initOtpState} from "./otpSlice";
import {apiConfiguration} from "./server";
// @ts-ignore
import {FamilyMemberRequestDto, FamilyMemberResponseDto, GlobalFamilyMembersApi} from "../api/client-axios";

export interface FamilyMemberNoOrgState {
    familyMember?: FamilyMemberResponseDto,
    status: "idle" | "loading" | "error" | "success" | "auth-error" | "saved";
    error?: string | Problem;
    code?: number;
}


const initialNoOrgFamilyMemberState: FamilyMemberNoOrgState = {
    status: "idle",
    error: ""
}

export const fetchNoOrgFamilyMember = createAsyncThunk<FamilyMemberResponseDto, { familyMemberId: string }, {
    rejectValue: ApiError
}>('doctor/fetchNoOrgFamilyMember', async (args, thunkApi) => {
    try {
        const api = new GlobalFamilyMembersApi(apiConfiguration());
        const response = await api.apiV1AdministrationNoOrgFamilyMembersIdGet(args.familyMemberId)
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({message: error?.response.data, statusCode: error?.response.status});
    }
});


export const saveNoOrgFamilyMember = createAsyncThunk<FamilyMemberResponseDto, {
    familyMemberId: string,
    familyMember: FamilyMemberRequestDto
}, { rejectValue: ApiError }>('doctor/saveNoOrgFamilyMember', async (args, thunkApi) => {
    try {
        const api = new GlobalFamilyMembersApi(apiConfiguration());
        const response = await api.apiV1AdministrationNoOrgFamilyMembersIdPost(args.familyMemberId, args.familyMember);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({message: error?.response.data, statusCode: error?.response.status});
    }
});




export const updateNoOrgFamilyMemberFields = createAction("familyMember/updateNoOrgFamilyMemberFields", (familyMember: FamilyMemberRequestDto) => {
    return {payload: familyMember}
});
export const clearSaveNoOrgFamilyMemberError = createAction('familyMember/clearErrorNoOrgFamilyMember');
export const updateNoOrgFamilyMemberPhoneNumber = createAction('familyMember/updatePhoneNumberNoOrgFamilyMember', (phoneNumber: string) => {
    return {payload: phoneNumber};
});
export const initNoOrgFamilyMemberState = createAction('familyMember/initNoOrg');

const familyMemberNoOrgSlice = createSlice({
    name: 'familyMemberNoOrg',
    initialState: initialNoOrgFamilyMemberState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchNoOrgFamilyMember.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(fetchNoOrgFamilyMember.fulfilled, (state, action) => {
                state.familyMember = action.payload;
                state.status = "success";
            })
            .addCase(fetchNoOrgFamilyMember.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(saveNoOrgFamilyMember.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(saveNoOrgFamilyMember.fulfilled, (state, action) => {
                state.familyMember = action.payload;
                state.status = "saved";
            })
            .addCase(saveNoOrgFamilyMember.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(clearSaveNoOrgFamilyMemberError, (state, action) => {
                state.status = "success";
                state.error = "";
            })
            .addCase(updateNoOrgFamilyMemberPhoneNumber, (state, action) => {
                state.familyMember!.userAccount = {
                    mobileNumber: action.payload,
                    emailAddress: state.familyMember?.userAccount?.emailAddress
                };
            })
            .addCase(initNoOrgFamilyMemberState, (state, action) => {
                state.status = "idle";
                state.familyMember = {};
                state.code = undefined;
                state.error = undefined;
            })
            .addCase(updateNoOrgFamilyMemberFields, (state, action) => {
                state.familyMember = {
                    ...state.familyMember,
                    ...action.payload as FamilyMemberResponseDto
                };
            });

    }
});

export default familyMemberNoOrgSlice.reducer;


function processError(
    action:
        PayloadAction<ApiError | undefined,
            string,
            {
                arg: string | FamilyMemberRequestDto | {  familyMemberId: string } | {

                    familyMember: FamilyMemberRequestDto
                };
                requestId: string;
                requestStatus: "rejected";
                aborted: boolean;
                condition: boolean;
            } &
            ({ rejectedWithValue: true; } | ({ rejectedWithValue: false; } & {})),
            SerializedError>,
    state: FamilyMemberNoOrgState) {
    if (action.payload) {

        //state.code = action.payload.statusCode;
        //const apiError = action.payload as ApiError;
        if (action.payload !== undefined) {
            state.error = action.payload.message;
            state.code = action.payload.statusCode;
            if (state.code == 401) {
                state.status = "auth-error";
            } else {
                state.status = "error";
            }
        }
    } else {
        state.status = "error";
        state.error = action.error.message;
    }
}

