import {Configuration, GlobalAudioLibraryApi, GlobalImageLibraryApi} from "../api/client-axios";
import {REACT_APP_BASE_API_PATH} from "../config";
import {baseUrl} from "../config/apiConfig";


export const imageUploadUrl = baseUrl + "/api/v1/administration/imageLibrary";
export const audioUploadUrl = baseUrl + "/api/v1/administration/audioLibrary";

export const apiConfiguration = () => new Configuration({

    accessToken: localStorage.getItem('token') ?? '',
    apiKey: `Bearer  ${localStorage.getItem('token')}`, basePath: baseUrl
});

export const deleteLibraryImage = async (id: string) => {
    const api = new GlobalImageLibraryApi(apiConfiguration());
    await api.apiV1AdministrationImageLibraryIdDelete(id);
}

export const deleteLibraryAudio = async (id: string) => {
    const api = new GlobalAudioLibraryApi(apiConfiguration());
    await api.apiV1AdministrationAudioLibraryIdDelete(id);
}

