import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { Configuration, GlobalImageLibraryApi, ImageFileResponseDto } from "../api/client-axios";
import { ApiError } from "../model/apiError";
import { Problem } from "../model/problem";
import { apiConfiguration } from "./server";


export type ImageResult ={
    image: ImageFileResponseDto, url: string
}

export interface ImagesLibraryState {
    images?: ImageResult[],
    selectedImage?: ImageFileResponseDto,
    status: "idle" | "loading" | "error" | "success" | "auth-error" | "deleting";
    error?: string | Problem;
    code?: number;
}


const initialImagesLibraryState: ImagesLibraryState = {
    status: "idle",
    error: ""
}

const readFileAsync = async (data: any) : Promise<ArrayBuffer | string | null> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            resolve(reader.result);
        }
        reader.onerror = (error) => {
            reject(error);
        }
        reader.readAsDataURL(data);
    });
}

export const fetchImages = createAsyncThunk<ImageResult[],void, { rejectValue: ApiError }>('imageLibrary/fetchImages', async (_, thunkApi) => {
    try {
        const api = new GlobalImageLibraryApi(apiConfiguration());
        const response = await api.apiV1AdministrationImageLibraryGet();
        const images = response.data as ImageFileResponseDto[];
        var result: ImageResult[] = [];
        for(var image of images){
            const r = await api.apiV1AdministrationImageLibraryIdContentGet(image.id!,{responseType: 'blob'});
            const url = await readFileAsync(r.data);
            result = [...result,{image, url: url as string} as ImageResult];
        }
        return result;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data.toString(), statusCode: error?.response.status });
    }
});


// export const uploadImage = createAsyncThunk<ImageFileResponseDto[], void, { rejectValue: ApiError }>('imageLibrary/uploadImage', async (args, thunkApi) => {
//     try {
//         const api = new GlobalImageLibraryApi(apiConfiguration());
//         await api.apiV1AdministrationImageLibraryPost(args);
//         const response = await api.apiV1AdministrationImageLibraryGet();
//         return response.data;
//     } catch (error: AxiosError | any) {
//         return thunkApi.rejectWithValue({ message: error?.response.data.toString(), statusCode: error?.response.status });
//     }
// });

export const deleteImage = createAsyncThunk<ImageFileResponseDto[],string, { rejectValue: ApiError }>('imageLibrary/deleteImage', async (imageId, thunkApi) => {
    try {
        const api = new GlobalImageLibraryApi(apiConfiguration());
        await api.apiV1AdministrationImageLibraryIdDelete(imageId);
        const response = await api.apiV1AdministrationImageLibraryGet();
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data.toString(), statusCode: error?.response.status });
    }
});

export const selectImage = createAction("imageLibrary/selectImage", (image: ImageFileResponseDto) => ({ payload: image }));
export const clearErrorImages = createAction("imageLibrary/clearErrorImages");
export const startDeleteImage = createAction("imageLibrary/startDeleteImage");
export const showImageError = createAction("imageLibrary/showImageError", (error: Problem) => ({ payload: error }));

const imagesLibrarysSlice = createSlice({
    name: 'audioLibrary',
    initialState: initialImagesLibraryState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchImages.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(fetchImages.fulfilled, (state, action) => {
                state.images = action.payload;
                state.status = "success";
            })
            .addCase(fetchImages.rejected, (state, action) => {
                if (action.payload) {
                    state.status = "auth-error";
                    state.error = action.payload.message;
                    state.code = action.payload.statusCode;
                } else {
                    state.status = "error";
                    state.error = action.error.message;
                }
            })
            .addCase(deleteImage.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(deleteImage.fulfilled, (state, action) => {
              
                state.status = "success";
            })
            .addCase(deleteImage.rejected, (state, action) => {
                if (action.payload) {
                    state.status = "auth-error";
                    state.error = action.payload.message;
                    state.code = action.payload.statusCode;
                } else {
                    state.status = "error";
                    state.error = action.error.message;
                }
            })
            .addCase(selectImage, (state, action) => {
                state.selectedImage = action.payload;
            })
            .addCase(clearErrorImages, (state) => {
                state.status = "success";
             })
             .addCase(startDeleteImage, (state) => {
                 state.status = "deleting";
             })
             .addCase(showImageError,(state, action) => {
                state.status = "error";
                state.error = action.payload;
             }) 
            ;


    }
});

export default imagesLibrarysSlice.reducer;
