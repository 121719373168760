import {createAction, createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {AxiosError} from "axios";
import {
    Configuration,
    GlobalCaretakerApi,
    CaretakerResponseDto,
    CaretakerResponseDtoPagedList
} from "../api/client-axios";
import {ApiError} from "../model/apiError";
import {Problem} from "../model/problem";
import {apiConfiguration} from "./server";


export interface CaretakersNoOrgState {
    caretakers?: CaretakerResponseDto[],
    selectedCaretaker?: CaretakerResponseDto,
    searchTerm?: string,
    status: "idle" | "loading" | "error" | "success" | "auth-error" | "deleting";
    error?: string | Problem;
    code?: number;
}


const initialCaretakerState: CaretakersNoOrgState = {
    status: "idle",
    error: ""
}


export const fetchNoOrgCaretakers = createAsyncThunk<CaretakerResponseDtoPagedList, {
    pageNumber?: number,
    pageSize?: number,
    q?: string
}, { rejectValue: ApiError }>('caretakersNoOrg/fetchCaretakers', async (args, thunkApi) => {
    try {

        const api = new GlobalCaretakerApi(apiConfiguration());
        const response = await api.apiV1AdministrationNoOrgCaretakersGet(args.pageNumber, args.pageSize, args.q);
        return response.data;

    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({message: error?.response.data.toString(), statusCode: error?.response.status});
    }
});


export const deleteNoOrgCaretaker = createAsyncThunk<CaretakerResponseDtoPagedList, {
    caretakerId: string
}, { rejectValue: ApiError }>('caretakers/deleteNoOrgCaretaker', async (args, thunkApi) => {
    try {

        const api = new GlobalCaretakerApi(apiConfiguration());
        await api.apiV1AdministrationNoOrgCaretakersIdDelete(args.caretakerId);
        const response = await api.apiV1AdministrationNoOrgCaretakersGet();
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({message: error?.response.data.toString(), statusCode: error?.response.status});
    }
});


export const selectNoOrgCaretaker = createAction("caretaker/selectNoOrgCaretaker", (caretaker: CaretakerResponseDto) => ({payload: caretaker}));
export const clearErrorNoOrgCaretakers = createAction("caretaker/clearErrorNoOrgCaretakers");
export const startDeleteNoOrgCaretaker = createAction("caretaker/startDeleteNoOrgCaretaker");
export const updateNoOrgCaretakerSearchTerm = createAction("caretaker/searchNoOrgTerm", (searchTerm: string) => ({payload: searchTerm}));


const caretakersNoOrgSlice = createSlice({
    name: 'caretakersNoOrg',
    initialState: initialCaretakerState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchNoOrgCaretakers.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(fetchNoOrgCaretakers.fulfilled, (state, action) => {
                state.caretakers = action.payload.items;
                state.status = "success";
            })
            .addCase(fetchNoOrgCaretakers.rejected, (state, action) => {
                if (action.payload) {
                    state.status = "auth-error";
                    state.error = action.payload.message;
                    state.code = action.payload.statusCode;
                } else {
                    state.status = "error";
                    state.error = action.error.message;
                }
            })
            .addCase(deleteNoOrgCaretaker.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(deleteNoOrgCaretaker.fulfilled, (state, action) => {
                state.status = "success";
            })
            .addCase(deleteNoOrgCaretaker.rejected, (state, action) => {
                if (action.payload) {
                    state.status = "auth-error";
                    state.error = action.payload.message;
                    state.code = action.payload.statusCode;
                } else {
                    state.status = "error";
                    state.error = action.error.message;
                }
            })
            .addCase(selectNoOrgCaretaker, (state, action) => {
                state.selectedCaretaker = action.payload;
            })
            .addCase(clearErrorNoOrgCaretakers, (state) => {
                state.status = "success";
            })
            .addCase(startDeleteNoOrgCaretaker, (state) => {
                state.status = "deleting";
            })
            .addCase(updateNoOrgCaretakerSearchTerm, (state, action) => {
                state.searchTerm = action.payload;
            })
        ;


    }
});

export default caretakersNoOrgSlice.reducer;
