export const readFileAsync = async (data: any) : Promise<ArrayBuffer | string | null> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            resolve(reader.result);
        }
        reader.onerror = (error) => {
            reject(error);
        }
        reader.readAsDataURL(data);
    });
}