import { ReloadOutlined } from "@ant-design/icons";
import { Button, Col, Row, Spin, Upload, UploadFile, Image, Modal } from "antd";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import { clearErrorImages, fetchImages, showImageError } from "../features/imagesLibrarySlice";
import { deleteLibraryImage, imageUploadUrl } from "../features/server";
import ImgCrop from 'antd-img-crop';
import { Problem } from "../model/problem";
import { REACT_APP_MAX_UPLOAD_SIZE } from "../config";
import { bytesToSize } from "../utils/sizeUtis";

export const ImagesLibraryPage = () => {
    const dispatch = useAppDispatch();
    const images = useAppSelector((state: RootState) => state.imageLibrary.images);
    const status = useAppSelector((state: RootState) => state.imageLibrary.status);
    const error = useAppSelector((state: RootState) => state.imageLibrary.error);

    const [previewVisible, setPreviewVisible] = useState(true);
    const [previewImageUrl, setPreviewImageUrl] = useState('');

    useEffect(() => {
        dispatch(fetchImages());
    }, []);




    const files = images?.map((image) => {
        return {
            uid: image.image.id,
            name: image.image.name,
            status: 'done',
            url: image.url,
            // originFileObj: { uid: "1" , ...file }

        } as UploadFile;
    });

    const onReload = () => {
        dispatch(fetchImages());
    }

    const onDeleteFile = async (imageId: string) => {
        await deleteLibraryImage(imageId);
    }

    const getErrorMessage = (): { title: string, details: string } => {
        const apiError = error as Problem;
        const title = typeof error === 'string' ? error : apiError.title ?? "Server Error";
        const detail = typeof error === 'string' ? "" : apiError.detail ?? "";
        return { title, details: detail };
    }

    const errorMessage = getErrorMessage();


    const onBeforeUpload = (file: File) => {
       if(file.size > REACT_APP_MAX_UPLOAD_SIZE) {
          dispatch(showImageError({ type: 'file-is-too-big', title: 'File is too big', detail: `File size ${file.name} exceed 3Mb`} as Problem));
          return false;
       }
       return file;
    }

    if (status === 'idle' || status === 'loading') {
        return <Spin className="flex-center mt-xxl" />
    }


    return (
        <div >
            <Row>
                <Col className="mt-s mr-s ml-s">
                    <Row justify="end">
                        <Button className="mb-xs" type='text' shape="circle" icon={<ReloadOutlined />} onClick={onReload} />
                    </Row>
                    <ImgCrop>
                        <Upload
                            accept=".jpg, .jpeg, .png"
                            defaultFileList={files}
                            name="file"
                            action={imageUploadUrl}
                            listType="picture-card"
                            headers={{
                                authorization: `Bearer ${localStorage.getItem('token')}`
                            }}
                            onChange={(info) => {
                                if (info.file.size != null && info.file.size > REACT_APP_MAX_UPLOAD_SIZE){
                                    info.fileList.pop();
                                    return;
                                }
                                if (info.file.status === 'done') {
                                    console.log(info.file.uid);
                                    info.file.uid = info.file.response.id;
                                }
                            }}

                            onPreview={(file) => {
                                setPreviewVisible(true);
                                setPreviewImageUrl(file.url!);
                            }}


                            beforeUpload={onBeforeUpload}

                            withCredentials={true}
                            showUploadList={{ showPreviewIcon: true }}

                            //showUploadList={false}
                            onRemove={(file) => {
                                onDeleteFile((file.uid));
                            }}
                        >
                            <Button type="primary">Upload</Button>
                        </Upload>
                    </ImgCrop>

                </Col>
            </Row>
            <Image src={previewImageUrl} preview={{
                visible: previewVisible, onVisibleChange: (value, preValue) => {
                    setPreviewVisible(value);
                }
            }} width={0} />
            <Modal title={errorMessage.title} visible={status === 'error' || status === 'auth-error'} onOk={() => dispatch(clearErrorImages())} cancelButtonProps={{ hidden: true }}>
                <p>{errorMessage.details}</p>
            </Modal>
        </div>


    );
}


