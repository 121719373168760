import { SearchOutlined, EditOutlined, PlusOutlined, ReloadOutlined, SaveOutlined, CloseOutlined } from "@ant-design/icons";
import { Col, Divider, Row, Space, Input, Button, Table, Spin, Modal, Form, InputRef } from "antd";
import { ColumnsType, ColumnType } from "antd/lib/table";
import { FilterConfirmProps } from "antd/lib/table/interface";
import { useEffect, useRef, useState } from "react";
import { ActivityResponseDto } from "../api/client-axios";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { addActivity, clearErrorActivity, deleteActivity, fetchActivities, selectActivity, startAddActivity, startDeleteActivity, updateActivity } from "../features/activitiesSlice";
import { Problem } from "../model/problem";

export const ActivitiesPage = () => {

    const dispatch = useAppDispatch()
    const activities = useAppSelector(state => state.activities.activities);
    const selectedActivity = useAppSelector(state => state.activities.selectedActivity);
    const status = useAppSelector(state => state.activities.status);
    const error = useAppSelector(state => state.activities.error);

    useEffect(() => {
        dispatch(fetchActivities());
    }, []);

    const onRow = (activity: ActivityResponseDto, index: number | undefined) => {
        return {
            onClick: () => {
                dispatch(selectActivity(activity))
            }, // click row
        }
    }

    const [searchText, setSearchText] = useState("");
    const searchInput = useRef<InputRef>(null);

    const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void) => {
        confirm();
        setSearchText(selectedKeys[0]);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (): ColumnType<ActivityResponseDto> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search activity`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record.name != null
                ? record.name.toString().toLowerCase().includes((value as string).toLowerCase())
                : false,
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
    });


    const columns: ColumnsType<ActivityResponseDto> = [
        {

            width: '100%',
            title: 'Name',
            dataIndex: 'name',
            key: 'name',

            ...getColumnSearchProps(),
            sorter: (a, b) => a.name! > b.name! ? -1 : 1
        }
    ];

    const getRowClassName = (activity: ActivityResponseDto, index: number) => {
        return selectedActivity != null && selectedActivity.id === activity.id ? 'bg-selected' : '';
    }


    const getErrorMessage = (): { title: string, details: string } => {
        const apiError = error as Problem;
        const title = typeof error === 'string' ? error : apiError?.title ?? "Server Error";
        const detail = typeof error === 'string' ? "" : apiError?.detail ?? "";
        return { title, details: detail };
    }


    const onAddActivity = () => {
        dispatch(startAddActivity({} as ActivityResponseDto));
    }

    const onReload = () => {
        dispatch(fetchActivities());
    }

    const handleOk = () => {
       dispatch(clearErrorActivity()); 
    }

    const errorMessage = getErrorMessage();

    const changeActivityName = (name: string) => {
        dispatch(selectActivity({ ...selectedActivity, name }));
    }

    const onSaveActivity = () => {
        if (selectedActivity?.name == null) return;

        if (selectedActivity.id == null) {
            dispatch(addActivity(selectedActivity!.name!));
            return;
        }

        dispatch(updateActivity(selectedActivity!));
    }

    const onEditActivity = () => {
        if (selectedActivity != null) {
            dispatch(startAddActivity(selectedActivity!));
        }
    }

    const onWarnDeleteActivity = () => {
        if (selectedActivity != null) {
            dispatch(startDeleteActivity());
        }
    }

    const onDeleteActivity = () => {
        if (selectedActivity != null) {
            dispatch(deleteActivity(selectedActivity.id!));
        }
    }


    const onCancelAddActivity = () => {
        dispatch(clearErrorActivity());
    }

    const addForm = status == "adding" ? <Form className='mr-s'
        name="basic"

        initialValues={{ remember: true }}
        autoComplete="off">
        <Form.Item label="Organzation Name:" rules={[{ required: true, message: '' }]} className="ml-m">
            <Input placeholder='John' defaultValue={selectedActivity?.name || ""} onChange={(e) => { changeActivityName(e.target.value) }} />
        </Form.Item>
        <Col offset={4} span={20} >
            <Row justify="end">

                <Space>
                    <Button type='text' icon={<CloseOutlined />} onClick={onCancelAddActivity} >Cancel</Button>
                    <Button type='primary' disabled={selectedActivity?.name == null || selectActivity.name.length == 0} icon={<SaveOutlined />} onClick={onSaveActivity}>Save</Button>
                </Space>

            </Row>

        </Col>
    </Form> : <div></div>;


    const buttons = status != "adding" ? <Row >
        <Col span={24}>
            <Row className="ml-m mr-m" justify="space-between">
                {/* <Space>
 <Input placeholder='John' defaultValue={searchTerm} onChange={(e) => { setSearchTerm(e.target.value); }} onPressEnter={onSearch}/>
 <Button type="primary" icon={<SearchOutlined />}  onClick={onSearch}>Search</Button>
</Space> */}
                <Button disabled={selectedActivity == null} type='primary' danger icon={<CloseOutlined />} onClick={onWarnDeleteActivity} >Delete</Button>

                <Space>
                    {selectedActivity?.id != null ? <Button type='primary' icon={<EditOutlined />} onClick={onEditActivity}>Edit</Button> : <div></div>}
                    <Button type='primary' icon={<PlusOutlined />} onClick={onAddActivity}>Add</Button>
                    <Button type='text' shape="circle" icon={<ReloadOutlined />} onClick={onReload} />
                </Space>

            </Row>
        </Col>

    </Row> : <div></div>;

    const content = status === 'loading' || status === 'idle'
        ? <Spin className="flex-center mt-xxl" />
        : <div>
            {buttons}
            {addForm}
            <Table className="mr-s ml-s mt-s"
                rowKey={(activity: ActivityResponseDto) => activity.id!}
                rowClassName={getRowClassName}
                onRow={onRow}
                dataSource={activities}
                columns={columns} />

            <Modal title={errorMessage.title} visible={status === 'error' || status === 'auth-error'} onOk={handleOk} cancelButtonProps={{ hidden: true }}>
                <p>{errorMessage.details}</p>
            </Modal>
            <Modal visible={status === "deleting"} okText="Yes" cancelText="No" onCancel={() => dispatch(clearErrorActivity())} onOk={onDeleteActivity} >Are you sure to complitely delete activity "{selectedActivity?.name}"</Modal>
        </div>





    return (<div className="" >
        <h4 className="fs-m fw-600 ml-s mt-s">
            Activities
        </h4>
        <Divider />
        {content}
    </div>);
}

