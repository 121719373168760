import { createAsyncThunk, createAction, createSlice, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { PatientResponseDto, OrganizationPatientsApi, Configuration, PatientRequestDto, GlobalPatientApi } from "../api/client-axios";
import { ApiError } from "../model/apiError";
import { Problem } from "../model/problem";
import { initOtpState } from "./otpSlice";
import { apiConfiguration } from "./server";

export interface PatientState {
    patient?: PatientResponseDto,
    status: "idle" | "loading" | "error" | "success" | "auth-error" | "saved";
    error?: string | Problem;
    code?: number;
    isNew: boolean;
}


const initialPatientState: PatientState = {
    status: "idle",
    error: "",
    isNew: false
}

export const fetchPatient = createAsyncThunk<PatientResponseDto, {organizationId: string, patientId : string}, { rejectValue: ApiError }>('patient/fetchPatient', async (args, thunkApi) => {
    try {
        const api = new GlobalPatientApi(apiConfiguration());
        if (args.patientId === "new") {
            thunkApi.dispatch(initOtpState());
            return {} as PatientResponseDto;
        }
        const response = await api.apiV1AdministrationOrganizationOrganizationIdPatientsIdGet(args.patientId, args.organizationId)
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data, statusCode: error?.response.status });
    }
});


export const savePatient = createAsyncThunk<PatientResponseDto, {organizationId: string, patient: PatientRequestDto}, { rejectValue: ApiError }>('patient/savePatient', async (args, thunkApi) => {
    try {
        const api = new GlobalPatientApi(apiConfiguration());
        const response = await api.apiV1AdministrationOrganizationOrganizationIdPatientsPost( args.organizationId, args.patient);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data, statusCode: error?.response.status });
    }
});


export const attachPatient = createAsyncThunk<PatientResponseDto, {organizationId: string, patientId : string}, { rejectValue: ApiError }>('patient/attachPatient', async (args, thunkApi) => {
    try {
        const api = new GlobalPatientApi(apiConfiguration());
        const response = await api.apiV1AdministrationOrganizationOrganizationIdPatientsIdAttachPost(args.patientId, args.organizationId);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data, statusCode: error?.response.status });
    }
});

export const detachPatient = createAsyncThunk<PatientResponseDto, {organizationId: string, patientId : string}, { rejectValue: ApiError }>('patient/detachPatient', async (args, thunkApi) => {
    try {
        const api = new GlobalPatientApi(apiConfiguration());
        const response = await api.apiV1AdministrationOrganizationOrganizationIdPatientsIdDetachPost(args.patientId, args.organizationId);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data, statusCode: error?.response.status });
    }
});


export const updatePatientFields = createAction("patient/updatePatientFields",(patient: PatientRequestDto) => {return { payload: patient }});
export const clearSavePatientError = createAction('patient/clearError');
export const updatePatientPhoneNumber = createAction('patient/updatePhoneNumber', (phoneNumber: string) => { return { payload: phoneNumber }; });

export const updatePatientByCode = createAction('patient/updatePatientByCode', (patient: PatientResponseDto) => {return {payload: patient}});


export const initPatientState = createAction('patient/init');

const patientSlice = createSlice({
    name: 'patient',
    initialState: initialPatientState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchPatient.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(fetchPatient.fulfilled, (state, action) => {
                state.patient = action.payload;
                state.status = "success";
            })
            .addCase(fetchPatient.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(savePatient.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(savePatient.fulfilled, (state, action) => {
                state.patient = action.payload;
                state.status = "saved";
            })
            .addCase(savePatient.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(clearSavePatientError, (state, action) => {
                state.status = "success";
                state.error = "";
            })
            .addCase(attachPatient.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(attachPatient.fulfilled, (state, action) => {
                state.patient = action.payload;
                state.status = "saved";
            })
            .addCase(attachPatient.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(detachPatient.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(detachPatient.fulfilled, (state, action) => {
                state.patient = action.payload;
                state.status = "saved";
            })
            .addCase(detachPatient.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(updatePatientPhoneNumber, (state, action) => {
                state.patient!.userAccount = { mobileNumber: action.payload, emailAddress: state.patient?.userAccount?.emailAddress };
            })
            .addCase(initPatientState,(state,action)=>{
                state.status = "idle";
                state.patient = {};
                state.code = undefined;
                state.error = undefined;
                state.isNew = false;
            })
            .addCase(updatePatientFields,(state,action)=>{
                state.patient ={
                    ...state.patient,
                    ...action.payload as PatientResponseDto
                };
            })
            .addCase(updatePatientByCode,(state, action)=>{
                state.patient = action.payload;
                state.isNew = true;
            });

    }
});

export default patientSlice.reducer;


function processError(
    action:
        PayloadAction<ApiError | undefined,
            string,
            { arg: string | PatientRequestDto | {organizationId: string, patientId : string} | {organizationId: string, patient: PatientRequestDto} ; requestId: string; requestStatus: "rejected"; aborted: boolean; condition: boolean; } &
            ({ rejectedWithValue: true; } | ({ rejectedWithValue: false; } & {})),
            SerializedError>,
    state: PatientState) {
    if (action.payload) {

        //state.code = action.payload.statusCode;
        //const apiError = action.payload as ApiError;
        if (action.payload !== undefined) {
            state.error = action.payload.message;
            state.code = action.payload.statusCode;
            if (state.code == 401) {
                state.status = "auth-error";
            } else {
                state.status = "error";
            }
        }
    } else {
        state.status = "error";
        state.error = action.error.message;
    }
}

