import { configureStore } from "@reduxjs/toolkit";
import organizationReducer from "../features/organizationsSlice";
import activitiesReducer from "../features/activitiesSlice";
import organizationAdminsReducer from "../features/organizationAdminsSlice";
import organizationAdminReducer from "../features/organizationAdminSlice";
import measurementTypesReducer from "../features/measurementTypesSlice";
import measurementValueTypesReducer from "../features/measurementValueTypesSlice";
import patientsReducer from "../features/patientsSlice";
import patientReducer from "../features/patientSlice";
import patientsNoOrgReducer from "../features/patientsNoOrgSlice";
import patientNoOrgReducer from "../features/patientNoOrgSlice";
import otpReducer from "../features/otpSlice";
import addPatientByCodeReducer from "../features/addPatientByCode";
import addPatientReducer from "../features/addPatientSlice";
import countryReducer from "../features/countrySlice";
import caretakerReducer from "../features/caretakerSlice";
import caretakersReducer from "../features/caretakersSlice";
import caretakerNoOrgReducer from "../features/caretakerNoOrgSlice";
import caretakersNoOrgReducer from "../features/caretakersNoOrgSlice";
import addCaretakerReducer from "../features/addCaretakerSlice";
import doctorReducer from "../features/doctorSlice";
import doctorsReducer from "../features/doctorsSlice";
import doctorsNoOrgReducer from "../features/doctorsNoOrgSlice";
import doctorNoOrgReducer from "../features/doctorNoOrgSlice";
import addDoctorReducer from "../features/addDoctorSlice";
import imageLibraryReducer from "../features/imagesLibrarySlice";
import audioLibraryReducer from "../features/audioLibrarySlice";
import treatmentTemplateReducer from "../features/treatmentTemplateSlice";
import familyMemberNoOrgReducer from "../features/familyMemberNoOrgSlice";
import familyMembersNoOrgReducer from  "../features/familyMembersNoOrgSlice";

export const store = configureStore({  
    reducer: {
        organizations: organizationReducer,
        organizationAdmins: organizationAdminsReducer,
        organizationAdmin: organizationAdminReducer,
        activities: activitiesReducer,
        measurementTypes: measurementTypesReducer,
        measurementValueTypes: measurementValueTypesReducer,
        patients: patientsReducer,
        patient: patientReducer,
        addPatientByCode:addPatientByCodeReducer,
        addPatient: addPatientReducer,
        patientsNoOrg: patientsNoOrgReducer,
        patientNoOrg: patientNoOrgReducer,
        otp: otpReducer,
        country: countryReducer,
        caretakers: caretakersReducer,
        caretaker: caretakerReducer,
        caretakersNoOrg: caretakersNoOrgReducer,
        caretakerNoOrg: caretakerNoOrgReducer,
        addCaretaker: addCaretakerReducer,
        doctors: doctorsReducer,
        doctor: doctorReducer,
        doctorsNoOrg: doctorsNoOrgReducer,
        doctorNoOrg: doctorNoOrgReducer,
        addDoctor: addDoctorReducer,
        imageLibrary: imageLibraryReducer,
        audioLibrary: audioLibraryReducer,
        treatmentTemplate: treatmentTemplateReducer,
        familyMemberNoOrg: familyMemberNoOrgReducer,
        familyMembersNoOrg: familyMembersNoOrgReducer
    }
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;