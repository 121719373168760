import { createAction, createAsyncThunk, createSlice, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { Configuration, GlobalCaretakerApi, CaretakerAvailability } from "../api/client-axios";
import { ApiError } from "../model/apiError";
import { PhoneValidation } from "../model/phoneValidationType";
import { Problem } from "../model/problem";
import { apiConfiguration } from "./server";

export interface AddCaretakerState {
    phoneNumber: string,
    phoneValidation: PhoneValidation,
    caretakerAvailability?: CaretakerAvailability,
    status: "idle" | "loading" | "error" | "success" | "auth-error";
    error?: string | Problem;
    code?: number;
}


const initialAddCaretakerState: AddCaretakerState = {
    phoneNumber: "",
    phoneValidation: "",
    status: "idle",
    error: ""
}

export const setCaretakerPhoneNumber = createAction('addCaretaker/setPhoneNumber', (phoneNumber: string) => { return { payload: phoneNumber }; });
export const setCaretakerPhoneValidation = createAction('addCaretaker/setPhoneValidation', (phoneValidation: PhoneValidation) => { return { payload: phoneValidation }; });
export const clearAddCaretakerError = createAction('addCaretaker/clearError');
export const initAddCaretakerState = createAction('addCaretaker/initState');

export const checkCaretakerAvailability = createAsyncThunk<CaretakerAvailability, {organizationId: string, phoneNumber: string}, { rejectValue: ApiError }>('addCaretaker/checkCaretakerAvailability', async (args, thunkApi) => {
    try {
        const api = new GlobalCaretakerApi(apiConfiguration());
        const response = await api.apiV1AdministrationOrganizationOrganizationIdCaretakersAvailableRawPhoneNumberGet(args.organizationId, args.phoneNumber);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data, statusCode: error?.response.status });
    }
}); 


const addCaretakerSlice = createSlice({
    name: 'addCaretaker',
    initialState: initialAddCaretakerState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(checkCaretakerAvailability.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(checkCaretakerAvailability.fulfilled, (state, action) => {
                state.caretakerAvailability = action.payload;
                state.status = "success";
            })
            .addCase(checkCaretakerAvailability.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(setCaretakerPhoneNumber, (state, action) => {
                state.status = "idle";
                state.phoneNumber = action.payload;
            })
            .addCase(setCaretakerPhoneValidation, (state, action) => {
                state.status = "idle";
                state.phoneValidation = action.payload;
            })
            .addCase(clearAddCaretakerError, (state, _) => {
                state.status = "idle";
            })
            .addCase(initAddCaretakerState, (state, _) => {
                state.phoneNumber ="";
                state.phoneValidation = "";
                state.status = "idle";
                state.error = "";
            });

           

    }
});

export default addCaretakerSlice.reducer;


function processError(
    action:
        PayloadAction<ApiError | undefined,
            string,
            { arg: string | string | { organizationId : string, phoneNumber: string}; requestId: string; requestStatus: "rejected"; aborted: boolean; condition: boolean; } &
            ({ rejectedWithValue: true; } | ({ rejectedWithValue: false; } & {})),
            SerializedError>,
    state: AddCaretakerState) {
    if (action.payload) {

        //state.code = action.payload.statusCode;
        //const apiError = action.payload as ApiError;
        if (action.payload !== undefined) {
            state.error = action.payload.message;
            state.code = action.payload.statusCode;
            if (state.code == 401) {
                state.status = "auth-error";
            } else {
                state.status = "error";
            }
        }
    } else {
        state.status = "error";
        state.error = action.error.message;
    }
}