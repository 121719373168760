import { createAction, createAsyncThunk, createSlice, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import {  Configuration , MeasurementTypeApi, MeasurementTypeDto, MeasurementTypeRequestDto } from "../api/client-axios";
import { ApiError } from "../model/apiError";
import { Problem } from "../model/problem";
import { apiConfiguration } from "./server";

export interface MeasurementTypesState {
    measurementTypes?: MeasurementTypeDto[],
    selectedMeasurementType?: MeasurementTypeDto,
    status: "idle" | "loading" | "error" | "adding" | "success" | "auth-error" | "deleting" ;
    error?: string | Problem;
    code?: number;
}

const initialMeasurementTypesState: MeasurementTypesState = {
    status: "idle",
    error: ""
}

export const fetchMeasurementTypes = createAsyncThunk<MeasurementTypeDto[], void, { rejectValue: ApiError }>('measurementTypes/fetchMeasurementTypes', async (_, thunkApi) => {
    try {
        const api = new MeasurementTypeApi(apiConfiguration());
        const response = await api.apiV1AdministrationMeasurementGet();
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data, statusCode: error?.response.status });
    }
});

export const addMeasurementType = createAsyncThunk<MeasurementTypeDto[], MeasurementTypeRequestDto, { rejectValue: ApiError }>('measurementTypes/addMeasurementType', async (measurement, thunkApi) => {
    try {
        const api = new MeasurementTypeApi(apiConfiguration());
        await api.apiV1AdministrationMeasurementPut(measurement);
        const response = await api.apiV1AdministrationMeasurementGet();
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data, statusCode: error?.response.status });
    }
});

export const updateMeasurementType = createAsyncThunk<MeasurementTypeDto[], {id: string, measurementType: MeasurementTypeRequestDto}, { rejectValue: ApiError }>('measurementTypes/updateMeasurementType', async (args, thunkApi) => {
    try {
        const api = new MeasurementTypeApi(apiConfiguration());
        await api.apiV1AdministrationMeasurementIdPost(args.id!, args.measurementType);
        const response = await api.apiV1AdministrationMeasurementGet();
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data, statusCode: error?.response.status });
    }
});


export const getMeasurementType = createAsyncThunk<MeasurementTypeDto[], string, { rejectValue: ApiError }>('measurementTypes/getMeasurementType', async (id, thunkApi) => {
    try {
        const api = new MeasurementTypeApi(apiConfiguration());
        await api.apiV1AdministrationMeasurementIdGet(id);
        const response = await api.apiV1AdministrationMeasurementGet();
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data, statusCode: error?.response.status });
    }
});

export const deleteMeasurementType = createAsyncThunk<MeasurementTypeDto[], string, { rejectValue: ApiError }>('measurementTypes/deleteMeasurementType', async (id, thunkApi) => {
    try {
        const api = new MeasurementTypeApi(apiConfiguration());
        await api.apiV1AdministrationMeasurementIdDelete(id);
        const response = await api.apiV1AdministrationMeasurementGet();
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data, statusCode: error?.response.status });
    }
});


export const selectMeasurementType = createAction("measurementTypes/selectMeasurementType", (organization: MeasurementTypeDto) => ({ payload: organization})); 
export const clearErrorMeasurementType = createAction("measurementTypes/clearErrorMeasurementType"); 
export const startAddMeasurementType = createAction("measurementTypes/startAddMeasurementType", (organization: MeasurementTypeDto) => ({ payload: organization})); 
export const startDeleteMeasurementType = createAction("measurementTypes/startDeleteMeasurementType"); 


const measurementTypesSlice = createSlice({
    name: "measurementTypes",
    initialState: initialMeasurementTypesState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchMeasurementTypes.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchMeasurementTypes.fulfilled, (state, action) => {
                state.status = "success";
                state.measurementTypes = action.payload;
            })
            .addCase(fetchMeasurementTypes.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(addMeasurementType.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addMeasurementType.fulfilled, (state, action) => {
                state.status = "success";
                state.measurementTypes = action.payload;
            })
            .addCase(addMeasurementType.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(updateMeasurementType.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateMeasurementType.fulfilled, (state, action) => {
                state.status = "success";
                state.measurementTypes = action.payload;
            })
            .addCase(updateMeasurementType.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(getMeasurementType.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getMeasurementType.fulfilled, (state, action) => {
                state.status = "success";
                state.measurementTypes = action.payload;
            })
            .addCase(getMeasurementType.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(deleteMeasurementType.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteMeasurementType.fulfilled, (state, action) => {
                state.status = "success";
                state.measurementTypes = action.payload;
            })
            .addCase(deleteMeasurementType.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(selectMeasurementType, (state, action) => {
                state.selectedMeasurementType = action.payload;
            })
            .addCase(clearErrorMeasurementType, (state) => {
                state.status = 'success';
            })
            .addCase(startAddMeasurementType, (state, action) => {
                state.status = 'adding';
                state.selectedMeasurementType =action.payload;
            })
            .addCase(startDeleteMeasurementType, (state) => {
                state.status = 'deleting';
                
            })
            ;
    }
});

export default measurementTypesSlice.reducer;


function processError(
    action:
        PayloadAction<ApiError | undefined,
            string,
            { arg: string | MeasurementTypeDto | void; requestId: string; requestStatus: "rejected"; aborted: boolean; condition: boolean; } &
            ({ rejectedWithValue: true; } | ({ rejectedWithValue: false; } & {})),
            SerializedError>,
    state: MeasurementTypesState) {
    if (action.payload) {

        if (action.payload !== undefined) {
            state.error = action.payload.message;
            state.code = action.payload.statusCode;
            if (state.code == 401) {
                state.status = "auth-error";
            } else {
                state.status = "error";
            }
        }
    } else {
        state.status = "error";
        state.error = action.error.message;
    }
}