import { createAction, createAsyncThunk, createSlice, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { GlobalTreatmentTemplateApi, ImageFileResponseDto, TreatmentTemplateRequestDto, TreatmentTemplateResponseDto } from "../api/client-axios";
import { ApiError } from "../model/apiError";
import { Problem } from "../model/problem";
import { readFileAsync } from "../utils/fileUtils";
import { apiConfiguration } from "./server";


export interface IImageCache {
    [index: string] : string
}


export interface TreatmentTemplatesState {
    treatmentTemplates?: TreatmentTemplateResponseDto[],
    selectedTreatmentTemplate?: TreatmentTemplateResponseDto,
    imagesCache: IImageCache 
    status: "idle" | "loading" | "error" | "adding" | "success" | "auth-error" | "deleting" ;
    imageStatus: "idle" | "loading" | "success" | "failed"
    error?: string | Problem;
    code?: number;
}

const initialTreatmentTemplatesState: TreatmentTemplatesState = {
    status: "idle",
    imageStatus: "idle",
    error: "",
    imagesCache: {} as IImageCache
}

export const fetchTreatmentTemplates = createAsyncThunk<TreatmentTemplateResponseDto[], void, { rejectValue: ApiError }>('treatmentTemplates/fetchTreatmentTemplates', async (_, thunkApi) => {
    try {
        const api = new GlobalTreatmentTemplateApi(apiConfiguration());
        const response = await api.apiV1AdministrationTreatmentTemplateGet();
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data, statusCode: error?.response.status });
    }
});

export const addTreatmentTemplate = createAsyncThunk<TreatmentTemplateResponseDto[], TreatmentTemplateRequestDto, { rejectValue: ApiError }>('treatmentTemplates/addTreatmentTemplate', async (measurement, thunkApi) => {
    try {
        const api = new GlobalTreatmentTemplateApi(apiConfiguration());
        await api.apiV1AdministrationTreatmentTemplatePut(measurement);
        const response = await api.apiV1AdministrationTreatmentTemplateGet();
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data, statusCode: error?.response.status });
    }
});

export const updateTreatmentTemplate = createAsyncThunk<TreatmentTemplateResponseDto[], {id: string, treatmentTemplate: TreatmentTemplateRequestDto}, { rejectValue: ApiError }>('treatmentTemplates/updateTreatmentTemplate', async (args, thunkApi) => {
    try {
        const api = new GlobalTreatmentTemplateApi(apiConfiguration());
        await api.apiV1AdministrationTreatmentTemplateIdPost(args.id!, args.treatmentTemplate);
        const response = await api.apiV1AdministrationTreatmentTemplateGet();
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data, statusCode: error?.response.status });
    }
});


export const getTreatmentTemplate = createAsyncThunk<TreatmentTemplateResponseDto, string, { rejectValue: ApiError }>('treatmentTemplates/getTreatmentTemplate', async (id, thunkApi) => {
    try {
        const api = new GlobalTreatmentTemplateApi(apiConfiguration());
        const response = await api.apiV1AdministrationTreatmentTemplateIdGet(id);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data, statusCode: error?.response.status });
    }
});

export const deleteTreatmentTemplate = createAsyncThunk<TreatmentTemplateResponseDto[], string, { rejectValue: ApiError }>('treatmentTemplates/deleteTreatmentTemplate', async (id, thunkApi) => {
    try {
        const api = new GlobalTreatmentTemplateApi(apiConfiguration());
        await api.apiV1AdministrationTreatmentTemplateIdDelete(id);
        const response = await api.apiV1AdministrationTreatmentTemplateGet();
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data, statusCode: error?.response.status });
    }
});

export const getTreatmentTemplateImageContent = createAsyncThunk<string, {id: string, imageId: string}, { rejectValue: ApiError }>('treatmentTemplates/getTreatmentTemplateImageContent', async (args, thunkApi) => {
    try {
        const api = new GlobalTreatmentTemplateApi(apiConfiguration());
        const r = await api.apiV1AdministrationTreatmentTemplateIdImageGet(args.id, { responseType: 'blob' });
        const url = await readFileAsync(r.data);
        return url as string;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data, statusCode: error?.response.status });
    }
});

export const uploadTreatmentTemplateImageContent = createAsyncThunk<{ imageId: string | undefined, url: string}, File, { rejectValue: ApiError }>('treatmentTemplates/uploadTreatmentTemplateImageContent', async (file, thunkApi) => {
    try {
        const api = new GlobalTreatmentTemplateApi(apiConfiguration());
        const url = await readFileAsync(file);
        const response = await api.apiV1AdministrationTreatmentTemplateImagePost(file)
        return {imageId: response.data.id, url: url as string};
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data, statusCode: error?.response.status });
    }
});


export const selectTreatmentTemplate = createAction("treatmentTemplates/selectTreatmentTemplate", (organization: TreatmentTemplateResponseDto) => ({ payload: organization})); 
export const clearErrorTreatmentTemplate = createAction("treatmentTemplates/clearErrorTreatmentTemplate"); 
export const startAddTreatmentTemplate = createAction("treatmentTemplates/startAddTreatmentTemplate", (organization: TreatmentTemplateResponseDto) => ({ payload: organization})); 
export const startDeleteTreatmentTemplate = createAction("treatmentTemplates/startDeleteTreatmentTemplate"); 
export const putImageInCache = createAction("treatmentTemplates/putImageInCache", ( arg: {id: string, url :string}) => ( {payload : arg}));
export const clearImage = createAction("treatmentTemplates/clearImageInCache", ( imageId: string) => ( {payload : imageId}))


const treatmentTemplatesSlice = createSlice({
    name: "treatmentTemplates",
    initialState: initialTreatmentTemplatesState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchTreatmentTemplates.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchTreatmentTemplates.fulfilled, (state, action) => {
                state.status = "success";
                state.treatmentTemplates = action.payload;
            })
            .addCase(fetchTreatmentTemplates.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(addTreatmentTemplate.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addTreatmentTemplate.fulfilled, (state, action) => {
                state.status = "success";
                state.treatmentTemplates = action.payload;
            })
            .addCase(addTreatmentTemplate.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(updateTreatmentTemplate.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateTreatmentTemplate.fulfilled, (state, action) => {
                state.status = "success";
                state.treatmentTemplates = action.payload;
            })
            .addCase(updateTreatmentTemplate.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(getTreatmentTemplate.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getTreatmentTemplate.fulfilled, (state, action) => {
                state.status = "success";
                state.selectedTreatmentTemplate = action.payload;
            })
            .addCase(getTreatmentTemplate.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(deleteTreatmentTemplate.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteTreatmentTemplate.fulfilled, (state, action) => {
                state.status = "success";
                state.treatmentTemplates = action.payload;
            })
            .addCase(deleteTreatmentTemplate.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(selectTreatmentTemplate, (state, action) => {
                state.selectedTreatmentTemplate = action.payload;
            })
            .addCase(clearErrorTreatmentTemplate, (state) => {
                state.status = 'success';
            })
            .addCase(startAddTreatmentTemplate, (state, action) => {
                state.status = 'adding';
                state.selectedTreatmentTemplate =action.payload;
            })
            .addCase(startDeleteTreatmentTemplate, (state) => {
                state.status = 'deleting';                
            })
            .addCase(getTreatmentTemplateImageContent.pending, (state) => {
                state.imageStatus = "loading"
            })
            .addCase(getTreatmentTemplateImageContent.rejected, (state) =>{
                state.imageStatus = "failed"
            })
            .addCase(getTreatmentTemplateImageContent.fulfilled, (state,action) =>{
                state.imageStatus = "success",
                state.imagesCache[action.meta.arg.imageId]= action.payload
            })
            .addCase(uploadTreatmentTemplateImageContent.fulfilled, (state, action)=>{
                if(state.selectedTreatmentTemplate != null){
                    state.selectedTreatmentTemplate.imageMediaFileId = action.payload.imageId;
                    state.imagesCache[action.payload.imageId!]= action.payload.url
                }                
            })
            .addCase(putImageInCache, (state,action)=>{
                state.imagesCache[action.payload.url] = action.payload.url
            })
            .addCase(clearImage,(state,action)=>{
                if(state.selectedTreatmentTemplate?.imageMediaFileId != null){
                    state.imagesCache[action.payload] = "";
                    state.selectedTreatmentTemplate.imageMediaFileId = null;
                }
            })
            ;
    }
});

export default treatmentTemplatesSlice.reducer;


function processError(
    action:
        PayloadAction<ApiError | undefined,
            string,
            { arg: string | TreatmentTemplateRequestDto | void | {id: string, treatmentTemplate : TreatmentTemplateRequestDto}; requestId: string; requestStatus: "rejected"; aborted: boolean; condition: boolean; } &
            ({ rejectedWithValue: true; } | ({ rejectedWithValue: false; } & {})),
            SerializedError>,
    state: TreatmentTemplatesState) {
    if (action.payload) {

        if (action.payload !== undefined) {
            state.error = action.payload.message;
            state.code = action.payload.statusCode;
            if (state.code == 401) {
                state.status = "auth-error";
            } else {
                state.status = "error";
            }
        }
    } else {
        state.status = "error";
        state.error = action.error.message;
    }
}