import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './app/store';
import { AccountPage } from './pages/AccounPage';
import { RouteNames } from './routes';
import { PageNotFound } from './pages/NotFoundPage';
import { OrganizationsPage } from './pages/OrganizationsPage';
import { ActivitiesPage } from './pages/ActivitiesPage';
import { EditOrganizationAdminPage } from './pages/EditOrganizationAdminPage';
import { MeasurementTypesPage } from './pages/MeasurementTypesPage';
import { MeasurementValueTypesPage } from './pages/MeasurementValueTypesPage';
import { OrganizationPage } from './pages/OrganizationPage';
import { EditPatientPage } from './pages/EditPatientPage';
import { EditCaretakerPage } from './pages/EditCaretakerPage';
import { EditDoctorPage } from './pages/EditDoctorPage';
import { ImagesLibraryPage } from './pages/ImagesLibraryPage';
import { AudioLibraryPage } from './pages/AudioLibraryPage';
import { TreatmentTemplatesPage } from './pages/TreatmentTemplatesPage';
import keycloak from "./authConfig/keyCloakConfig";
import {AuthProvider} from "react-oidc-context";
import { IndividualsPage } from './pages/IndividualsPage';
import {EditNoOrgPatientPage} from "./pages/EditNoOrgPatientPage";
import {EditNoOrgCaretakerPage} from "./pages/EditNoOrgCaretakerPage";
import {EditNoOrgDoctorPage} from "./pages/EditNoOrgDoctorPage";
import {EditNoOrgFamilyMemberPage} from "./pages/EditNoOrgFamilyMemberPage";



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    <AuthProvider {...keycloak}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<App />}>
            <Route path={`${RouteNames.organizations}`} element={<OrganizationsPage />} >
              <Route path=":organizationId" element={<OrganizationPage />}>
                <Route path="admins/:adminId" element={<EditOrganizationAdminPage />} />
                <Route path="patients/:patientId" element={<EditPatientPage />} />
                <Route path="caretakers/:caretakerId" element={<EditCaretakerPage />} />
                <Route path="doctors/:doctorId" element={<EditDoctorPage />} />
                
              </Route>
            </Route>
            <Route path={`${RouteNames.individuals}`} element={<IndividualsPage />} >
              <Route path="patients/:patientId" element={<EditNoOrgPatientPage />} />
              <Route path="familyMembers/:familyMemberId" element={<EditNoOrgFamilyMemberPage />} />
              <Route path="caretakers/:caretakerId" element={<EditNoOrgCaretakerPage />} />
              <Route path="doctors/:doctorId" element={<EditNoOrgDoctorPage />} />
            </Route>
            {/* <Route path={`${RouteNames.organizationAdmins}`} element={<OrganizationAdminsPage />} /> */}
            <Route path={`${RouteNames.measurementTypes}`} element={<MeasurementTypesPage />} >
              <Route path=":measurementTypeId" element={<MeasurementValueTypesPage />} />
            </Route>
            <Route path={`${RouteNames.activities}`} element={<ActivitiesPage />} />
            <Route path={`${RouteNames.imagesLibrary}`} element={<ImagesLibraryPage />} />
            <Route path={`${RouteNames.audioLibrary}`} element={<AudioLibraryPage />} />
            <Route path={`${RouteNames.treatments}`} element={<TreatmentTemplatesPage />} />
            <Route path={`${RouteNames.account}`} element={<AccountPage />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>

  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
