import {Layout, Spin} from 'antd';
import {useEffect} from 'react';
import {useNavigate, Outlet} from 'react-router-dom';
import './App.css';
import {SideMenu} from './components/SideMenu';
import {RouteNames} from './routes';
import {
    AppstoreOutlined,
    AppstoreTwoTone,
    AudioTwoTone,
    ContactsTwoTone,
    CustomerServiceOutlined,
    DashboardTwoTone,
    FileDoneOutlined,
    FileImageOutlined,
    FileImageTwoTone,
    HddOutlined,
    HddTwoTone,
    IdcardTwoTone,
    MehTwoTone,
    UserAddOutlined,
    UserOutlined
} from '@ant-design/icons';
import {ItemType} from 'antd/lib/menu/hooks/useItems';
import {useAppDispatch} from './app/hooks';
import {fetchCountries} from './features/countrySlice';
import {useAuth, hasAuthParams} from "react-oidc-context";

function App() {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    //const {isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently, user} = useAuth0();

    const auth = useAuth();


    useEffect(() => {
        if (!hasAuthParams() &&
            !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
            auth.signinRedirect();}
    },[auth.isAuthenticated, auth.activeNavigator, auth.isLoading, auth.signinRedirect]);

    useEffect(() => {
        dispatch(fetchCountries());
    }, [])

    useEffect(() => {
        if (auth.isAuthenticated) {
            console.log(auth.user ?? '');
            const token = auth.user?.access_token;
            console.log(token);
            localStorage.setItem('token', token ?? '');
        }
    }, [auth]);

    useEffect(() =>  {
        return auth.events.addAccessTokenExpiring(async () => {
            console.log('renew token');
            await auth.signinSilent();
            const token = auth.user?.access_token;
            console.log(token ?? '');
            localStorage.setItem('token', token ?? '');

        })
    }, [auth.events,auth.signinSilent]);

    const menuItems: ItemType[] = [
        {
            key: RouteNames.organizations,
            itemIcon: <AppstoreTwoTone/>,
            label: 'Organizations'
        },
        {
            key: RouteNames.individuals,
            itemIcon: <IdcardTwoTone />,
            label: 'Individuals'
        },
        {
            key: RouteNames.measurementTypes,
            itemIcon: <HddTwoTone/>,
            label: 'Measurement Types'
        },
        {
            key: RouteNames.activities,
            itemIcon: <DashboardTwoTone/>,
            label: 'Activities'
        },
        {
            key: RouteNames.imagesLibrary,
            itemIcon: <FileImageTwoTone/>,
            label: 'Images Library'
        },
        {
            key: RouteNames.audioLibrary,
            itemIcon: <AudioTwoTone/>,
            label: 'Audios Library'
        },
        {
            key: RouteNames.treatments,
            itemIcon: <MehTwoTone/>,
            label: 'Treatments'
        },
        {
            key: 'account',
            itemIcon: <ContactsTwoTone/>,
            label: 'Account'
        }
    ];


    const onMenuClick = (menuInfo: {
        key: string;
        keyPath: string[];
        domEvent: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
    }) => {

        const route = `/${menuInfo.key}`;
        console.log(`Clicked menu: ${route}`);
        navigate(route)
    }


    return (
        <div className="App">
            {auth.isAuthenticated ? <Layout>
                <div style={{backgroundColor: 'white'}}>
                    <div className='fogfree-logo'/>
                    <SideMenu menuItems={menuItems} onMenuClick={onMenuClick}/>
                </div>

                <Layout.Content className='content'>
                    <Outlet/>
                </Layout.Content>
            </Layout> : <Spin className='wide-spin'/>}
        </div>
    );
}

export default App;