import { createAction, createAsyncThunk, createSlice, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ActivityApi, ActivityResponseDto, Configuration, } from "../api/client-axios";
import { ApiError } from "../model/apiError";
import { Problem } from "../model/problem";
import { apiConfiguration } from "./server";

export interface ActivityState {
    activities?: ActivityResponseDto[],
    selectedActivity?: ActivityResponseDto,
    status: "idle" | "loading" | "error" | "adding" | "success" | "auth-error" | "deleting" ;
    error?: string | Problem;
    code?: number;
}

const initialOrganizationState: ActivityState = {
    status: "idle",
    error: ""
}

export const fetchActivities = createAsyncThunk<ActivityResponseDto[], void, { rejectValue: ApiError }>('activities/fetchActivities', async (_, thunkApi) => {
    try {
        const api = new ActivityApi(apiConfiguration());
        const response = await api.apiV1ActivityGet();
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data, statusCode: error?.response.status });
    }
});

export const addActivity = createAsyncThunk<ActivityResponseDto[], string, { rejectValue: ApiError }>('activities/addActivity', async (name, thunkApi) => {
    try {
        const api = new ActivityApi(apiConfiguration());
        await api.apiV1ActivityPut({ name: name });
        const response = await api.apiV1ActivityGet();
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data, statusCode: error?.response.status });
    }
});

export const updateActivity = createAsyncThunk<ActivityResponseDto[], ActivityResponseDto, { rejectValue: ApiError }>('activities/updateActivity', async (activityDto, thunkApi) => {
    try {
        const api = new ActivityApi(new Configuration({ apiKey: `Bearer  ${localStorage.getItem('token')}`  }));
        await api.apiV1ActivityIdPost(activityDto.id!, { name: activityDto.name });
        const response = await api.apiV1ActivityGet();
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data, statusCode: error?.response.status });
    }
});


export const deleteActivity = createAsyncThunk<ActivityResponseDto[], string, { rejectValue: ApiError }>('activities/deleteActivity', async (id, thunkApi) => {
    try {
        const api = new ActivityApi(apiConfiguration());
        await api.apiV1ActivityIdDelete(id);
        const response = await api.apiV1ActivityGet();
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data.toString(), statusCode: error?.response.status });
    }
});


export const selectActivity = createAction("activities/selectActivity", (activity: ActivityResponseDto) => ({ payload: activity})); 
export const clearErrorActivity = createAction("activities/clearErrorActivity"); 
export const startAddActivity = createAction("activities/startAddActivity", (activity: ActivityResponseDto) => ({ payload: activity})); 
export const startDeleteActivity = createAction("activities/startDeleteActivity"); 


const activitiesSlice = createSlice({
    name: "activities",
    initialState: initialOrganizationState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchActivities.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchActivities.fulfilled, (state, action) => {
                state.status = "success";
                state.activities = action.payload;
            })
            .addCase(fetchActivities.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(addActivity.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addActivity.fulfilled, (state, action) => {
                state.status = "success";
                state.activities = action.payload;
            })
            .addCase(addActivity.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(updateActivity.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateActivity.fulfilled, (state, action) => {
                state.status = "success";
                state.activities = action.payload;
            })
            .addCase(updateActivity.rejected, (state, action) => {
                processError(action, state);
            })
            // .addCase(getOrganization.pending, (state) => {
            //     state.status = "loading";
            // })
            // .addCase(getOrganization.fulfilled, (state, action) => {
            //     state.status = "success";
            //     state.activities = action.payload;
            // })
            // .addCase(getOrganization.rejected, (state, action) => {
            //     processError(action, state);
            // })
            .addCase(deleteActivity.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteActivity.fulfilled, (state, action) => {
                state.status = "success";
                state.activities = action.payload;
            })
            .addCase(deleteActivity.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(selectActivity, (state, action) => {
                state.selectedActivity = action.payload;
            })
            .addCase(clearErrorActivity, (state) => {
                state.status = 'success';
            })
            .addCase(startAddActivity, (state, action) => {
                state.status = 'adding';
                state.selectedActivity =action.payload;
            })
            .addCase(startDeleteActivity, (state) => {
                state.status = 'deleting';
                
            })
            ;
    }
});

export default activitiesSlice.reducer;


function processError(
    action:
        PayloadAction<ApiError | undefined,
            string,
            { arg: string | ActivityResponseDto | void; requestId: string; requestStatus: "rejected"; aborted: boolean; condition: boolean; } &
            ({ rejectedWithValue: true; } | ({ rejectedWithValue: false; } & {})),
            SerializedError>,
    state: ActivityState) {
    if (action.payload) {

        //state.code = action.payload.statusCode;
        //const apiError = action.payload as ApiError;
        if (action.payload !== undefined) {
            state.error = action.payload.message;
            state.code = action.payload.statusCode;
            if (state.code == 401) {
                state.status = "auth-error";
            } else {
                state.status = "error";
            }
        }
    } else {
        state.status = "error";
        state.error = action.error.message;
    }
}