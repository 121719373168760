import { createAsyncThunk, createAction, createSlice, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { PatientResponseDto, OrganizationPatientsApi, Configuration, PatientRequestDto, GlobalPatientApi } from "../api/client-axios";
import { ApiError } from "../model/apiError";
import { Problem } from "../model/problem";
import { initOtpState } from "./otpSlice";
import { apiConfiguration } from "./server";

export interface PatientNoOrgState {
    patient?: PatientResponseDto,
    status: "idle" | "loading" | "error" | "success" | "auth-error" | "saved";
    error?: string | Problem;
    code?: number;
}


const initialPatientState: PatientNoOrgState = {
    status: "idle",
    error: ""
}

export const fetchNoOrgPatient = createAsyncThunk<PatientResponseDto, {patientId : string}, { rejectValue: ApiError }>('patientNoOrg/fetchPatient', async (args, thunkApi) => {
    try {
        const api = new GlobalPatientApi(apiConfiguration());
        if (args.patientId === "new") {
            thunkApi.dispatch(initOtpState());
            return {} as PatientResponseDto;
        }
        const response = await api.apiV1AdministrationNoOrgPatientsIdGet(args.patientId)
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data, statusCode: error?.response.status });
    }
});


export const saveNoOrgPatient = createAsyncThunk<PatientResponseDto, {patient: PatientRequestDto}, { rejectValue: ApiError }>('patient/saveNoOrgPatient', async (args, thunkApi) => {
    try {
        const api = new GlobalPatientApi(apiConfiguration());
        const response = await api.apiV1AdministrationNoOrgPatientsIdPost( args.patient.id!, args.patient);
        return response.data;
    } catch (error: AxiosError | any) {
        return thunkApi.rejectWithValue({ message: error?.response.data, statusCode: error?.response.status });
    }
});



export const updateNoOrgPatientFields = createAction("patient/updateNoOrgPatientFields",(patient: PatientRequestDto) => {return { payload: patient }});
export const clearSaveNoOrgPatientError = createAction('patient/clearErrorNoOrg');
export const updateNoOrgPatientPhoneNumber = createAction('patient/updatePhoneNumberNoOrg', (phoneNumber: string) => { return { payload: phoneNumber }; });
export const initNoOrgPatientState = createAction('patient/initNoOrg');

const patientNoOrgSlice = createSlice({
    name: 'patientNoOrg',
    initialState: initialPatientState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchNoOrgPatient.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(fetchNoOrgPatient.fulfilled, (state, action) => {
                state.patient = action.payload;
                state.status = "success";
            })
            .addCase(fetchNoOrgPatient.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(saveNoOrgPatient.pending, (state, _) => {
                state.status = "loading";
            })
            .addCase(saveNoOrgPatient.fulfilled, (state, action) => {
                state.patient = action.payload;
                state.status = "saved";
            })
            .addCase(saveNoOrgPatient.rejected, (state, action) => {
                processError(action, state);
            })
            .addCase(clearSaveNoOrgPatientError, (state, action) => {
                state.status = "success";
                state.error = "";
            })

            .addCase(updateNoOrgPatientPhoneNumber, (state, action) => {
                state.patient!.userAccount = { mobileNumber: action.payload, emailAddress: state.patient?.userAccount?.emailAddress };
            })
            .addCase(initNoOrgPatientState,(state,action)=>{
                state.status = "idle";
                state.patient = {};
                state.code = undefined;
                state.error = undefined;
            })
            .addCase(updateNoOrgPatientFields,(state,action)=>{
                state.patient ={
                    ...state.patient,
                    ...action.payload as PatientResponseDto
                };
            });

    }
});

export default patientNoOrgSlice.reducer;


function processError(
    action:
        PayloadAction<ApiError | undefined,
            string,
            { arg: string | PatientRequestDto | { patientId : string} | {patient: PatientRequestDto} ; requestId: string; requestStatus: "rejected"; aborted: boolean; condition: boolean; } &
            ({ rejectedWithValue: true; } | ({ rejectedWithValue: false; } & {})),
            SerializedError>,
    state: PatientNoOrgState) {
    if (action.payload) {

        //state.code = action.payload.statusCode;
        //const apiError = action.payload as ApiError;
        if (action.payload !== undefined) {
            state.error = action.payload.message;
            state.code = action.payload.statusCode;
            if (state.code == 401) {
                state.status = "auth-error";
            } else {
                state.status = "error";
            }
        }
    } else {
        state.status = "error";
        state.error = action.error.message;
    }
}

