import { CloseOutlined, EditOutlined, PlusOutlined, ReloadOutlined, RightOutlined, SaveOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Input, InputRef, Modal, Row, Space, Spin, Table } from "antd";
import { ColumnsType, ColumnType, FilterConfirmProps } from "antd/lib/table/interface";
import { useEffect, useRef, useState } from "react";
import { Link, Outlet, useParams } from "react-router-dom";
import { MeasurementRequestDto, MeasurementTypeDto } from "../api/client-axios";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { addMeasurementType, clearErrorMeasurementType, deleteMeasurementType, fetchMeasurementTypes, selectMeasurementType, startAddMeasurementType, startDeleteMeasurementType, updateMeasurementType } from "../features/measurementTypesSlice";
import { Problem } from "../model/problem";

export const MeasurementTypesPage = () => {

    const dispatch = useAppDispatch()
    const measurementTypes = useAppSelector(state => state.measurementTypes.measurementTypes);
    const selectedMeasurementType = useAppSelector(state => state.measurementTypes.selectedMeasurementType);
    const status = useAppSelector(state => state.measurementTypes.status);
    const error = useAppSelector(state => state.measurementTypes.error);
    const params = useParams();

    useEffect(() => {
        dispatch(fetchMeasurementTypes());
    }, []);
    const [searchText, setSearchText] = useState("");
    const searchInput = useRef<InputRef>(null);

    if(params.measurementTypeId !== undefined) {
        return <Outlet />
    }

    const onRow = (measurementType: MeasurementTypeDto, index: number | undefined) => {
        return {
            onClick: () => {
                dispatch(selectMeasurementType(measurementType))
            }, // click row
        }
    }



    const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void) => {
        confirm();
        setSearchText(selectedKeys[0]);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (): ColumnType<MeasurementTypeDto> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search measurementType`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record.description != null
                ? record.description.toString().toLowerCase().includes((value as string).toLowerCase())
                : false,
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
    });


    const columns: ColumnsType<MeasurementTypeDto> = [
        {
            width: '30%',
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            ...getColumnSearchProps(),
            sorter: (a, b) => a.description! > b.description! ? -1 : 1,
            ellipsis: true,
            render: (text, record) => {
                return <Row justify="space-between">{text}<Link to={record.id!}><RightOutlined /></Link></Row>;
            }
        }
        // {
        //     width: '30%',
        //     title: 'Procedure',
        //     dataIndex: 'measurementProcedure',
        //     key: 'measurementProcedure',
        //     ...getColumnSearchProps(),
        //     sorter: (a, b) => a.description! > b.description! ? -1 : 1,
        //     ellipsis: true,
        //     render: (text, record) => {
        //         return <Row justify="space-between">{text}<Link to={record.id!}><RightOutlined /></Link></Row>;
        //     }
        // },
       
    ];

    const getRowClassName = (measurementType: MeasurementTypeDto, index: number) => {
        return selectedMeasurementType != null && selectedMeasurementType.id === measurementType.id ? 'bg-selected' : '';
    }


    const getErrorMessage = (): { title: string, details: string } => {
        const apiError = error as Problem;
        const title = typeof error === 'string' ? error : apiError.title ?? "Server Error";
        const detail = typeof error === 'string' ? "" : apiError.detail ?? "";
        return { title, details: detail };
    }


    const onAddMeasurementType = () => {
        dispatch(startAddMeasurementType({} as MeasurementTypeDto));
    }

    const onReload = () => {
        dispatch(fetchMeasurementTypes());
    }

    const handleOk = () => {

    }

    const errorMessage = getErrorMessage();

    const changeMeasurementTypeDescription = (description: string) => {
        dispatch(selectMeasurementType({ ...selectedMeasurementType, description }));
    }

    const changeMeasurementTypeProcedure = (measurementProcedure: string) => {
        dispatch(selectMeasurementType({ ...selectedMeasurementType, measurementProcedure }));
    }


    const onSaveMeasurementType = () => {
        if (selectedMeasurementType?.description == null) return;

        if (selectedMeasurementType.id == null) {
            dispatch(addMeasurementType(selectedMeasurementType));
            return;
        }

        dispatch(updateMeasurementType({id: selectedMeasurementType.id, measurementType: {...selectedMeasurementType} as MeasurementTypeDto}));
    }

    const onEditMeasurementType = () => {
        if (selectedMeasurementType != null) {
            dispatch(startAddMeasurementType(selectedMeasurementType!));
        }
    }

    const onWarnDeleteMeasurementType = () => {
        if (selectedMeasurementType != null) {
            dispatch(startDeleteMeasurementType());
        }
    }

    const onDeleteMeasurementType = () => {
        if (selectedMeasurementType != null) {
            dispatch(deleteMeasurementType(selectedMeasurementType.id!));
        }
    }


    const onCancelAddMeasurementType = () => {
        dispatch(clearErrorMeasurementType());
    }

    const addForm = status == "adding" ? <Form className='mr-s'
        name="basic"
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 22 }}
        initialValues={{ remember: true }}
        autoComplete="off">
        <Form.Item label="Description:" rules={[{ required: true, message: '' }]} className="ml-m">
            <Input placeholder='Pressure' defaultValue={selectedMeasurementType?.description || ""} onChange={(e) => { changeMeasurementTypeDescription(e.target.value) }} />
        </Form.Item>
        <Form.Item label="Procedure:" rules={[{ required: true, message: '' }]} className="ml-m">
            <Input placeholder='' defaultValue={selectedMeasurementType?.measurementProcedure || ""} onChange={(e) => { changeMeasurementTypeProcedure(e.target.value) }} />
        </Form.Item>
        <Col offset={4} span={20} >
            <Row justify="end">

                <Space>
                    <Button type='text' icon={<CloseOutlined />} onClick={onCancelAddMeasurementType} >Cancel</Button>
                    <Button type='primary' disabled={selectedMeasurementType == null } icon={<SaveOutlined />} onClick={onSaveMeasurementType}>Save</Button>
                </Space>

            </Row>

        </Col>
    </Form> : <div></div>;


    const buttons = status != "adding" ? <Row >
        <Col span={24}>
            <Row className="ml-m mr-m" justify="space-between">
                <Button disabled={selectedMeasurementType == null} type='primary' danger icon={<CloseOutlined />} onClick={onWarnDeleteMeasurementType} >Delete</Button>
                <Space>
                    {selectedMeasurementType?.id != null ? <Button type='primary' icon={<EditOutlined />} onClick={onEditMeasurementType}>Edit</Button> : <div></div>}
                    <Button type='primary' icon={<PlusOutlined />} onClick={onAddMeasurementType}>Add</Button>
                    <Button type='text' shape="circle" icon={<ReloadOutlined />} onClick={onReload} />
                </Space>

            </Row>
        </Col>

    </Row> : <div></div>;

    const content = status === 'loading' || status === 'idle'
        ? <Spin className="flex-center mt-xxl" />
        : <div>
            {buttons}
            {addForm}
            <Table className="mr-s ml-s mt-s"
                rowKey={(measurementType: MeasurementTypeDto) => measurementType.id!}
                rowClassName={getRowClassName}
                onRow={onRow}
                dataSource={measurementTypes}
                columns={columns} />

            <Modal title={errorMessage.title} visible={status === 'error' || status === 'auth-error'} onOk={handleOk} cancelButtonProps={{ hidden: true }}>
                <p>{errorMessage.details}</p>
            </Modal>
            <Modal visible={status === "deleting"} okText="Yes" cancelText="No" onCancel={() => dispatch(clearErrorMeasurementType())} onOk={onDeleteMeasurementType} >Are you sure to complitely delete measurementType "{selectedMeasurementType?.description}"</Modal>
        </div>





    return (<div className="" >
        <h4 className="fs-m fw-600 ml-s mt-s">
            Measurement Types
        </h4>
        <Divider />
        {content}
    </div>);
}

