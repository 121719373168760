import {ScissorOutlined, DeleteOutlined, CloseOutlined, CheckOutlined} from "@ant-design/icons";
import {Select, Spin, Modal, Col, Form, Row, Space, Button, Input, DatePicker, InputNumber, Switch} from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import {useState, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {CaretakerCreateRequestDto, DoctorRequestDto} from "../api/client-axios";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {RootState} from "../app/store";
import {
    fetchDoctor,
    initDoctorState,
    updateDoctorFields,
    detachDoctor,
    updateDoctorPhoneNumber,
    clearSaveDoctorError,
    saveDoctor
} from "../features/doctorSlice";
import {fetchDoctors, deleteDoctor} from "../features/doctorsSlice";
import {Problem} from "../model/problem";
import {RouteNames} from "../routes";
import {AddDoctor} from "./AddDoctor";

const {Option} = Select;

export const EditDoctorPage = () => {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const doctorsStatus = useAppSelector((state: RootState) => state.doctors.status);
    const doctor = useAppSelector((state: RootState) => state.doctor.doctor);
    const status = useAppSelector((state: RootState) => state.doctor.status);
    const error = useAppSelector((state: RootState) => state.doctor.error);
    const countries = useAppSelector((state: RootState) => state.country.countries);


    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isRemoveDoctorWarningVisible, setIsRemoveDoctorWarningVisible] = useState(false);
    const [isDeleteDoctorWarningVisible, setIsDeleteDoctorWarningVisible] = useState(false);

    const organizationId = params.organizationId;
    const doctorId = params.doctorId;

    useEffect(() => {
        if (doctorsStatus === "idle") {
            dispatch(fetchDoctors({organizationId: organizationId!}));
        }
        dispatch(fetchDoctor({organizationId: organizationId!, doctorId: doctorId!}));
    }, []);

    useEffect(() => {
        if (status === "saved") {
            dispatch(fetchDoctors({organizationId: organizationId!}));
            dispatch(initDoctorState());
            navigate(-1);
        }
        if (status === "auth-error" || status === "error") {
            setIsModalVisible(true);
        }
    }, [status])

    useEffect(() => {
        if (doctorsStatus === "saved") {
            dispatch(fetchDoctors({organizationId: organizationId!}));
            dispatch(initDoctorState());
            navigate(-1);
        }
    }, [doctorsStatus])


    const onCancel = () => {
        navigate(-1);
    }

    const changeDoctor = (doctor: DoctorRequestDto) => {
        dispatch(updateDoctorFields(doctor));
    }

    const onSave = () => {
        dispatch(saveDoctor({organizationId: organizationId!, doctor: doctor as DoctorRequestDto}));
    }


    const onDelete = () => {
        setIsDeleteDoctorWarningVisible(true);
    }

    const onRemove = () => {
        setIsRemoveDoctorWarningVisible(true);
    }

    const onDeleteDoctor = () => {
        if (doctor?.id != null) {
            dispatch(deleteDoctor({organizationId: organizationId!, doctorId: doctor.id}));
        }
    }

    const onRemoveDoctor = () => {
        if (doctor?.id != null) {
            dispatch(detachDoctor({organizationId: organizationId!, doctorId: doctor.id}));
        }
    }

    const onConfirm = (phoneNumber: string) => {
        dispatch(updateDoctorPhoneNumber(phoneNumber));
    }

    const handleAuthOk = () => {
        setIsModalVisible(false)
        navigate("/")
    }

    const handleErrorOk = () => {
        setIsModalVisible(false);
        dispatch(clearSaveDoctorError());
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }


    if (status === "idle" || status === "loading" || doctorsStatus === "idle") {
        return <Spin/>;
    }

    if (status === "auth-error") {
        return <Modal title="Auth error" visible={isModalVisible} onOk={handleAuthOk} onCancel={handleCancel}>
            <p>"Authentication failed, please login"</p>
        </Modal>
    }

    if (status === "error") {
        const apiError = error as Problem;
        const title = apiError.title ?? "Server Error";
        const detail = apiError.detail ?? error?.toString;
        return <Modal title={title} visible={isModalVisible} onOk={handleErrorOk} onCancel={handleCancel}>
            <p>{detail}</p>
        </Modal>
    }

    if (status === "saved") {
        return (<div></div>);
    }

    // if (doctor?.userAccount?.mobileNumber === undefined) {
    //     return <AddDoctor organizationId={organizationId!}  onConfirm={onConfirm} />
    // }

    return (<div className='edit-person-main-frame'>
        <Col span={24}>
            <Form className='person-form'
                  name="basic"
                  labelCol={{span: 6}}
                  wrapperCol={{span: 24}}
                  initialValues={{remember: true}}
                //onFinish={onFinish}
                //onFinishFailed={onFinishFailed}
                  autoComplete="off"
            >

                <Row>
                    {/* <Col className='person-title' offset={6} span={11}>Doctor</Col> */}
                    <Col offset={6} span={18}>
                        <Form.Item>
                            <Row justify="end">
                                <Space className="mr-xxs">
                                    <Button icon={<ScissorOutlined/>} onClick={onRemove} type='primary'
                                            style={{background: 'orange', borderColor: 'orange'}}
                                            disabled={doctor?.id === undefined}>Remove</Button>
                                    <Button icon={<DeleteOutlined/>} onClick={onDelete} type='primary' danger
                                            disabled={doctor?.id === undefined}>Delete</Button>
                                </Space>

                                <Space>
                                    <Button icon={<CloseOutlined/>} onClick={onCancel}>Cancel</Button>
                                    <Button icon={<CheckOutlined/>} type='primary' onClick={onSave}>Save</Button>
                                </Space>
                            </Row>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Email:">
                            <Input placeholder='john.doe@email.com'
                                   defaultValue={doctor?.userAccount?.emailAddress || ""}
                                   onChange={(e) => {
                                       changeDoctor({
                                           ...doctor,
                                           userAccount: {...doctor?.userAccount, emailAddress: e.target.value}
                                       } as DoctorRequestDto)
                                   }}
                            />
                        </Form.Item>
                    </Col>
                    {doctorId === undefined || doctorId == "new"
                        ?
                        <Col span={24}>
                            <Form.Item label="Password:">
                                <Input.Password placeholder='password' onChange={(e) => {
                                    changeDoctor({
                                        ...doctor,
                                        password: e.target.value
                                    } as DoctorRequestDto)
                                }}/>
                            </Form.Item>
                        </Col>
                        : <span/>}
                    <Col span={24}>
                        <Form.Item label="Phone number:" rules={[{required: true}]}
                                   initialValue={doctor?.userAccount?.mobileNumber}>
                            <Input placeholder='1 (344) 333-3333'
                                   onChange={(e) => {
                                       changeDoctor({
                                           ...doctor,
                                           userAccount: {...doctor?.userAccount, mobileNumber: e.target.value}
                                       } as DoctorRequestDto)
                                   }}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item label="First name:" rules={[{required: true, message: 'please enter First Name'}]}>
                            <Input placeholder='John' defaultValue={doctor?.firstName || ""} onChange={(e) => {
                                changeDoctor({...doctor, firstName: e.target.value} as DoctorRequestDto)
                            }}/>
                        </Form.Item>

                    </Col>
                    <Col span={24}>
                        <Form.Item label="Last name:">
                            <Input placeholder='Doe' defaultValue={doctor?.lastName || ""} onChange={(e) => {
                                changeDoctor({...doctor, lastName: e.target.value} as DoctorRequestDto)
                            }}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Country:" initialValue={doctor?.address?.country}>
                            <Select
                                onChange={(e) => changeDoctor({
                                    ...doctor,
                                    address: {...doctor?.address, alpha3Code: e}
                                } as DoctorRequestDto)}
                                defaultValue={doctor?.address?.alpha3Code}
                                showSearch
                                style={{width: 200}}
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) => {
                                    return (option!.children![2] as unknown as string).toLowerCase().includes(input.toLowerCase())
                                }}
                                filterSort={(optionA, optionB) =>
                                    (optionA!.children![2] as unknown as string)
                                        .toLowerCase()
                                        .localeCompare((optionB!.children![2] as unknown as string).toLowerCase())
                                }
                            >
                                {countries.map(country => <Option key={country.alpha3Code}
                                                                  value={country.alpha3Code}><img src={country.flag!}
                                                                                                  width="20"></img> {country.name}
                                </Option>)}

                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Address:">
                            <Input defaultValue={doctor?.address?.streetAddress1 as string | undefined}
                                   placeholder='Road st 27' onChange={(e) => {
                                changeDoctor({
                                    ...doctor,
                                    address: {...doctor?.address, streetAddress1: e.target.value}
                                } as DoctorRequestDto)
                            }}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Address:">
                            <Input defaultValue={doctor?.address?.streetAddress2 as string | undefined}
                                   placeholder='Left side' onChange={(e) => {
                                changeDoctor({
                                    ...doctor,
                                    address: {...doctor?.address, streetAddress2: e.target.value}
                                } as DoctorRequestDto)
                            }}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="City:">
                            <Input defaultValue={doctor?.address?.city as string | undefined} placeholder='New York'
                                   onChange={(e) => {
                                       changeDoctor({
                                           ...doctor,
                                           address: {...doctor?.address, city: e.target.value}
                                       } as DoctorRequestDto)
                                   }}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="State:">
                            <Input defaultValue={doctor?.address?.state as string | undefined} placeholder='NY'
                                   onChange={(e) => {
                                       changeDoctor({
                                           ...doctor,
                                           address: {...doctor?.address, state: e.target.value}
                                       } as DoctorRequestDto)
                                   }}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Postcode:">
                            <Input defaultValue={doctor?.address?.postCode as string | undefined} placeholder='NY'
                                   onChange={(e) => {
                                       changeDoctor({
                                           ...doctor,
                                           address: {...doctor?.address, postCode: e.target.value}
                                       } as DoctorRequestDto)
                                   }}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Col>
        <Modal visible={isRemoveDoctorWarningVisible} okText="Yes" cancelText="No"
               onCancel={() => setIsRemoveDoctorWarningVisible(false)} onOk={onRemoveDoctor}>Are you sure to remove
            doctor from clinic "{doctor?.firstName + " " + doctor?.lastName}"</Modal>
        <Modal visible={isDeleteDoctorWarningVisible} okText="Yes" cancelText="No"
               onCancel={() => setIsDeleteDoctorWarningVisible(false)} onOk={onDeleteDoctor}>Are you sure to complitely
            delete doctor "{doctor?.firstName + " " + doctor?.lastName}"</Modal>
    </div>);
}