import { CloseOutlined, DeleteOutlined, EditOutlined, PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Input, Modal, Row, Space, Spin } from "antd";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    OrganizationAdminCreateRequest,
    OrganizationAdminUpdateRequest,
    UserAccountOrganizationCreateRequestDto
} from "../api/client-axios";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { addOrganizationAdmin, clearErrorOrganizationAdmin, getOrganizationAdmin, initOrganizationAdmin, setErrorOrganizationAdmin, updateOrganizationAdmin, updateOrganizationAdminFields, updateOrganizationAdminPassword } from "../features/organizationAdminSlice";
import { Problem } from "../model/problem";
import { RouteNames } from "../routes";

export const EditOrganizationAdminPage = () => {
    const params = useParams();
    const dispatch = useAppDispatch();
    const status = useAppSelector(state => state.organizationAdmin.status);
    const organizationAdmin = useAppSelector(state => state.organizationAdmin.admin);
    const password = useAppSelector(state => state.organizationAdmin.password);
    const error = useAppSelector(state => state.organizationAdmin.error);
    const navigate = useNavigate();


    console.log("render", organizationAdmin);

    useEffect(() => {
        if (params.adminId === "new") {
            dispatch(initOrganizationAdmin());
            return;
        }

        if (params.adminId != null && params.organizationId != null) {
            dispatch(getOrganizationAdmin({ organizationId: params.organizationId, id: params.adminId }));
        }
    }, []);

    useEffect(() => {
        if(status === "saved") {
            navigate(`/${RouteNames.organizations}/${params.organizationId}`);
        }
    },[status])

    const organizationId = params.organizationId;
    const adminId = params.adminId;
    const isAdd = adminId === "new";

    const onCancel = () => {
        navigate(-1);
    };

    const isValidPassword = () => {
        
       const result = password != null && password.password.length > 7 && password.password === password.confirmPassword;
       if(!result) {
           dispatch(setErrorOrganizationAdmin({
            title: "Password validation error",
            detail: "Password must be at least 8 characters long and must match the confirm password "+ 
                    "and contains at least one: "+
                    "lower case (a-z), "+
                    "upper case (A-Z), "+
                    "numbers (0-9)\nSpecial characters (!@#$%^&*)"
           } as Problem));
       }
       return result;
    }

    const onSave = () => {
        if (organizationId == null || organizationAdmin == null) return;

        if (isAdd && isValidPassword()) {
            dispatch(addOrganizationAdmin({
                organizationId: organizationId,
                admin: {
                    firstName: organizationAdmin.firstName,
                    lastName: organizationAdmin.lastName,
                    userAccountId: organizationAdmin.userAccountId,
                    password: password.password,
                    userAccount: {
                        emailAddress: organizationAdmin.userAccount?.emailAddress,
                        mobileNumber: organizationAdmin.userAccount?.mobileNumber
                    } as UserAccountOrganizationCreateRequestDto

                } as OrganizationAdminCreateRequest
            }));
            return;
        }


        if (organizationAdmin.id == null) return;

        dispatch(updateOrganizationAdmin({
            organizationId: organizationId,
            id: organizationAdmin.id!,
            admin: {
                firstName: organizationAdmin.firstName,
                lastName: organizationAdmin.lastName
            } as OrganizationAdminUpdateRequest
        }));
    }

    const buttons = <Row className="" >
        <Col span={24}>
            <Row justify="end">
                <Space>
                    <Button type='text' icon={<CloseOutlined />} onClick={onCancel} >Cancel</Button>
                    <Button type='primary' icon={<PlusOutlined />} onClick={onSave}>Save</Button>
                </Space>

            </Row>
        </Col>

    </Row>;

    const getErrorMessage = (): { title: string, details: string } => {
        const apiError = error as Problem;
        const title = typeof error === 'string' ? error : apiError.title ?? "Server Error";
        const detail = typeof error === 'string' ? "" : apiError.detail ?? "";
        return { title, details: detail };
    }

    const errorMessage = getErrorMessage();

    const passwordFields = isAdd ? <div>
        <Col span={24}>
            <Form.Item label="Password:" rules={[{ required: true }]} initialValue={password?.password}>
                <Input type="password" placeholder='*******' onChange={(e) => dispatch(updateOrganizationAdminPassword({ ...password, password: e.target.value }))} />
            </Form.Item>
        </Col>
        <Col span={24}>
            <Form.Item label="Repeat:" rules={[{ required: true }]} initialValue={password?.confirmPassword}>
                <Input type="password" placeholder='*******' onChange={(e) => dispatch(updateOrganizationAdminPassword({ ...password, confirmPassword: e.target.value }))} />
            </Form.Item>
        </Col>
    </div> : <div />;

    const content = status === "loading" || status === "idle" ? <Spin className="flex-center mt-xxl" /> : <Form className='mt-s'
        name="basic"
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 22 }}
        initialValues={{ remember: true }}
        autoComplete="off"
    >
        <Col span={24}>
            <Form.Item label="First name:" rules={[{ required: true }]} >
                <Input  defaultValue={organizationAdmin?.firstName ?? ""} placeholder='John'  onChange={(e) => dispatch(updateOrganizationAdminFields({ ...organizationAdmin, firstName: e.target.value }))} />
            </Form.Item>
        </Col>
        <Col span={24}>
            <Form.Item label="Last name:" rules={[{ required: true }]}>
                <Input  defaultValue={organizationAdmin?.lastName ?? ""} placeholder='Doe' onChange={(e) => dispatch(updateOrganizationAdminFields({ ...organizationAdmin, lastName: e.target.value }))} />
            </Form.Item>
        </Col>
        <Col span={24}>
            <Form.Item label="Email:" rules={[{ required: true }]} >
                <Input defaultValue={organizationAdmin?.userAccount?.emailAddress ?? ""} disabled={!isAdd} placeholder='email@mail.com' onChange={(e) => dispatch(updateOrganizationAdminFields({ ...organizationAdmin, userAccount: { ...organizationAdmin?.userAccount, emailAddress: e.target.value } }))} />
            </Form.Item>
        </Col>
        {passwordFields}
    </Form>;



    return (
        <div className="">
            {/* <h5>Administrator</h5>
            <Divider /> */}
            {/* <h1>{params.organizationId}</h1>
            <h1>{params.adminId}</h1> */}
            {buttons}
            {content}
            <Modal title={errorMessage.title} visible={status === 'error' || status === 'auth-error'} onOk={() => dispatch(clearErrorOrganizationAdmin())} cancelButtonProps={{ hidden: true }}>
                {errorMessage.details}
            </Modal>
        </div>
    );
}